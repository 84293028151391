import { getLength } from 'ol/sphere';
import LineString from 'ol/geom/LineString';
import { fromLonLat } from 'ol/proj';
import Feature from 'ol/Feature';
import { Coordinate } from 'ol/coordinate';

const distance = (aP: Coordinate, bP: Coordinate) => getLength(new LineString([aP, bP]));

interface IHydratedFeature {
  closestPoint: Coordinate;
  feature: Feature;
}

function closestPoint(features: Feature[], inputCoordinate: Coordinate) {
  let cP = [0, 0];
  const targetCoordinate = fromLonLat(inputCoordinate);

  const sortedFeatures = features
    .filter((feature: Feature): feature is Feature => feature !== undefined && feature !== null)
    .map((feature: Feature) => ({
      // optional chaining to supress lint error - we know from filtering it's never undefined
      closestPoint: feature?.getGeometry()?.getClosestPoint(targetCoordinate) || [0, 0], // the fallback can never be used - this is only to fool TS
      feature,
    }))
    .sort((a: IHydratedFeature, b: IHydratedFeature) => {
      return distance(a.closestPoint, targetCoordinate) > distance(b?.closestPoint, targetCoordinate) ? 1 : -1;
    });

  if (
    sortedFeatures[0].feature.get('type') === 'open_area_boundary' ||
    sortedFeatures[0].feature.get('type') === 'open_area_entry' ||
    sortedFeatures[0].feature.get('type') === 'open_area_exit'
  ) {
    cP = targetCoordinate;
  } else {
    cP = sortedFeatures[0].closestPoint;
  }

  if (distance(cP, targetCoordinate) < 20) {
    return cP;
  }

  return targetCoordinate;
}

export default closestPoint;
