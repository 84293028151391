import { SvgIcon } from '@material-ui/core';
import { ICommonIconProps } from './CommonIconProps';

export const Circle = ({ className, style, testid }: ICommonIconProps) => {
  return (
    <SvgIcon
      width="2"
      height="2"
      viewBox="0 0 2 2"
      fill="none"
      className={className}
      style={style}
      data-testid={testid}
    >
      <circle cx="1" cy="1" r="1" />
    </SvgIcon>
  );
};
export default Circle;
