import { SvgIcon } from '@material-ui/core';
import { ICommonIconProps } from './CommonIconProps';

export const Triangle = ({ className, testid }: ICommonIconProps) => {
  return (
    <SvgIcon width="16" height="14" viewBox="0 0 16 14" fill="none" className={className} data-testid={testid}>
      <polygon points="7 0, 16 14, 0 14" />
    </SvgIcon>
  );
};
export default Triangle;
