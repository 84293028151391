import { OperationalState, EquipmentMode } from '@ats/graphql';
import IVehicleStateColor, { LastCommunicationState } from './IVehicleStateColor';
import { beige900, blue, green, grey500, red, yellow } from '../../../theme/color';

export const active: IVehicleStateColor = {
  foreground: green,
  shadow: 'rgba(29, 171, 139, 0.38)',
  lastCommunicationStateColor: LastCommunicationState.LAST_COMMUNICATION_ACTIVE,
};
export const deviation: IVehicleStateColor = {
  foreground: yellow,
  shadow: 'rgba(241, 194, 27, 0.38)',
  lastCommunicationStateColor: LastCommunicationState.LAST_COMMUNICATION_DEVIATION,
};
export const idle: IVehicleStateColor = {
  foreground: blue,
  shadow: 'rgba(74, 137, 243, 0.38)',
  lastCommunicationStateColor: LastCommunicationState.LAST_COMMUNICATION_IDLE,
};
export const manual: IVehicleStateColor = {
  foreground: beige900,
  shadow: 'rgba(206, 184, 136, 0.38)',
  lastCommunicationStateColor: LastCommunicationState.LAST_COMMUNICATION_MANUAL,
};
export const offline: IVehicleStateColor = {
  foreground: grey500,
  shadow: 'rgba(105, 106, 112, 0.38)',
  lastCommunicationStateColor: LastCommunicationState.LAST_COMMUNICATION_OFFLINE,
};
export const standDown: IVehicleStateColor = {
  foreground: red,
  shadow: 'rgba(255, 35, 64, 0.38)',
  lastCommunicationStateColor: LastCommunicationState.LAST_COMMUNICATION_STAND_DOWN,
};

const getVehicleStateColor = (
  state: OperationalState | null,
  mode: EquipmentMode | null,
  online: boolean,
): IVehicleStateColor => {
  if (!online) return offline;
  if (mode === EquipmentMode.MODE_MANUAL) return manual;

  switch (state) {
    case OperationalState.OPERATIONAL_STATE_STAND_DOWN:
    case OperationalState.OPERATIONAL_STATE_ENTERING_STAND_DOWN:
    case OperationalState.OPERATIONAL_STATE_LEAVING_STAND_DOWN:
      return standDown;
    case OperationalState.OPERATIONAL_STATE_EXECUTING:
      return active;
    case OperationalState.OPERATIONAL_STATE_EXECUTING_RESTRICTED:
    case OperationalState.OPERATIONAL_STATE_IDLE_RESTRICTED:
      return deviation;
    case OperationalState.OPERATIONAL_STATE_NORMAL:
    case OperationalState.OPERATIONAL_STATE_IDLE:
      return idle;
    default:
      return offline;
  }
};

export default getVehicleStateColor;
