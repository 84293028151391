import { useEffect, useRef, useState, memo } from 'react';
import { makeStyles } from '@material-ui/core';
import { equals } from 'ramda';
import { transform, fromLonLat } from 'ol/proj';
import { inAndOut } from 'ol/easing';

import withEquipment from '../withEquipment';
import {
  createMap,
  createOLVehicleFeatures,
  updateOLVehicleFeatures,
  removeOLVehicleFeatures,
} from './OpenLayerIntegration';
import { isEqualOmitEquipmentTimestamps } from '../../model/isEqual';
import Events from '../../model/events';

import useSiteMapLayer from './useSiteMapLayer';
import useTrackerLabelsLayer from './useTrackerLabelsLayer';
import usePoiLayer from './usePoiLayer';
import useVehicleRouteLayer from './useVehicleRouteLayer';
import useDestinationMarkerLayer from './useDestinationMarkerLayer';

import { generateCatchUp, rawData } from '../../model/vehicle/position/vehiclePositionAlgorithms';
import useClickAndDriveLayer from './useClickAndDriveLayer';
import {
  mousePointerCoordinates as mousePointerCoordinatesObservable,
  offsetPosition as offsetPositionObservable,
  selectedVehiclePosition as selectedVehiclePositionObservable,
} from '../../model/observables';
import useQueueAndPaddockLayer from './useQueueAndPaddockLayer';

/* This is to place the map above all App components, that houses the background click swallower for Position and Rotation Modules,
but to still place below the default MUI popover layers, which are placed at z-index 1300 */
const useStyles = makeStyles(
  {
    root: {
      zIndex: 1100,
    },
  },
  { index: 1 },
);

const MapContainer = (props) => {
  const {
    equipmentStatuses,
    snapState: [snap, setSnap],
    selectedEquipmentState: [selectedEquipmentId, setSelectedEquipmentId],
    hoverEquipmentState: [hoverEquipmentId, setHoverEquipmentId],
    selectedPoi: [, setSelectedPoiId],
    selectedZone: setSelectedZone, // Note: The "zone" might be a stand-alone queue or paddock as well
    vehicleSmoothing,
    areaId,
    satelliteLayerVisible,
    missionLabel,
  } = props;

  const [mapPointer, setMapPointer] = useState(null);
  const [mouseWheelPointer, setMouseWheelPointer] = useState(null);
  const [vehicleLayerPointer, setVehicleLayerPointer] = useState(null);
  const setOLSatelliteLayerVisibilityPointer = useRef();
  const mapContainerRef = useRef();
  const snapRef = useRef({ longitude: null, latitude: null, enabled: false });
  const siteMapLayer = useSiteMapLayer();
  const queueAndPaddockLayer = useQueueAndPaddockLayer(areaId);
  const vehicleRouteLayer = useVehicleRouteLayer();
  const destinationMarkerLayer = useDestinationMarkerLayer(missionLabel);
  const trackerLayer = useTrackerLabelsLayer();
  const poiLayer = usePoiLayer(areaId, mapPointer, trackerLayer);
  const { root } = useStyles();

  const { layer: clickAndDriveLayer } = useClickAndDriveLayer();

  const mouseover = useRef({ longitude: null, latitude: null });

  const cameraPositionRef = useRef({ longitude: null, latitude: null });

  const smoothingAlgorithmRef = useRef(null);

  const persistSelectedVehiclePosition = useRef(null);
  const persistSelectedEquipmentId = useRef(null);

  const resetAlgorithm = () => {
    smoothingAlgorithmRef.current = {
      OFF: () => rawData,
      'CATCH-UP': generateCatchUp,
    }[vehicleSmoothing](); // This generates a new Catch Up (meaning the old stored values are intentionally lost)
  };

  useEffect(() => {
    if (persistSelectedEquipmentId.current === selectedEquipmentId) return;
    persistSelectedEquipmentId.current = selectedEquipmentId;
    resetAlgorithm();
    // this should only fire to compare a differently selected vehicle, the others are refs and a function
    // eslint-disable-next-line
  }, [selectedEquipmentId]);

  useEffect(resetAlgorithm, [vehicleSmoothing]);

  useEffect(() => {
    persistSelectedVehiclePosition.current = () => {
      if (!selectedEquipmentId || !equipmentStatuses) return;

      const equipment = equipmentStatuses.find(
        ({ externalEquipmentReference }) => externalEquipmentReference === selectedEquipmentId,
      );

      if (!equipment) return;

      const { position, timestamp } = equipment;

      if (!position || !timestamp) return;

      const { longitude, latitude, heading } =
        smoothingAlgorithmRef.current(
          position.longitude,
          position.latitude,
          position.heading,
          timestamp,
          new Date().toISOString(),
        ) || {};

      // If algorithms need more than one data-point to give return values, hide the vehicle until there is enough data, null = hidden vehicle
      if (!longitude || !latitude || !heading) return;

      selectedVehiclePositionObservable.next({ longitude, latitude, heading });
    };
  }, [selectedEquipmentId, equipmentStatuses]);

  useEffect(() => {
    const subscription = selectedVehiclePositionObservable.subscribe((value) => {
      cameraPositionRef.current = value;
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  // Initialize map
  useEffect(() => {
    const config = {
      containerRef: mapContainerRef.current,
      setHoverEquipmentId,
      setSelectedEquipmentId,
      siteMapLayer,
      setSelectedPoiId,
      setSelectedZone,
      poiLayer,
      queueAndPaddockLayer,
      vehicleRouteLayer,
      destinationMarkerLayer,
      satelliteLayerVisible,
      clickAndDriveLayer,
      trackerLayer,
    };
    const disableSnap = () => {
      setSnap(false);
      snapRef.current = { longitude: null, latitude: null, enabled: false };
    };
    createMap(config).then(({ map, vehicleLayer, mouseWheelZoom, setSatelliteLayerVisibility }) => {
      setMouseWheelPointer(mouseWheelZoom);
      setMapPointer(map);
      setVehicleLayerPointer(vehicleLayer);
      offsetPositionObservable.next(mapContainerRef.current.getBoundingClientRect());

      window.addEventListener(Events.REMOVE_ALL_VEHICLES, () => removeOLVehicleFeatures(vehicleLayer));

      setOLSatelliteLayerVisibilityPointer.current = setSatelliteLayerVisibility;
      map.on('pointermove', ({ coordinate, dragging }) => {
        const transformedCoordinate = transform(coordinate, 'EPSG:3857', 'EPSG:4326');
        if (!equals(mouseover.current, transformedCoordinate)) {
          mousePointerCoordinatesObservable.next(transformedCoordinate);
          mouseover.current = transformedCoordinate;
        }
        if (dragging) {
          disableSnap();
        }
      });
    });
    // Map should only be created once and never again, hence we ignore the dependency array and have it run once on startup
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!mapPointer) return;
    mapPointer.updateSize(); // This is to solve the bug where large map is off center when returning to Map Overview
  }, [mapPointer]);

  useEffect(() => {
    if (setOLSatelliteLayerVisibilityPointer.current) {
      setOLSatelliteLayerVisibilityPointer.current(satelliteLayerVisible);
    }
  }, [satelliteLayerVisible]);

  // When a vehicle is snapped, turn off anchor to let zoom anchor be in the absolute middle (where the vehicle is)
  useEffect(() => {
    if (!mouseWheelPointer) return;
    mouseWheelPointer.setMouseAnchor(!snap);
  }, [snap, mouseWheelPointer]);

  // Update vehicles when there is new data
  useEffect(() => {
    // disregard all equipmentStatuses until async map and vehicle layer is loaded
    if (!mapPointer || !vehicleLayerPointer || !equipmentStatuses) return;

    const onVehicleClickCallback = () => setSnap(false);

    createOLVehicleFeatures(
      vehicleLayerPointer,
      trackerLayer,
      mapPointer,
      equipmentStatuses,
      vehicleSmoothing,
      onVehicleClickCallback,
    );

    // this is temporary and can be handled from within the vehicleobject itself, it has access to the observable and the event listener for postrender in openlayers
    // perhaps i was wrong, it needs the selectedEquipmentId
    updateOLVehicleFeatures(vehicleLayerPointer, equipmentStatuses, selectedEquipmentId, hoverEquipmentId);
  }, [
    mapPointer,
    vehicleLayerPointer,
    trackerLayer,
    equipmentStatuses,
    selectedEquipmentId,
    setSnap,
    hoverEquipmentId,
    vehicleSmoothing,
  ]);

  // Snap logic
  useEffect(() => {
    const eq = equipmentStatuses?.find(
      ({ externalEquipmentReference }) => externalEquipmentReference === selectedEquipmentId,
    );
    if (snap && selectedEquipmentId && equipmentStatuses && eq) {
      const { position } = eq;
      const { longitude, latitude } = position;
      snapRef.current = { longitude, latitude, enabled: true }; // Using ref instead of state since the open layers post-render listener will trigger decoupled from react
      return;
    }
    snapRef.current = { longitude: null, latitude: null, enabled: false };
  }, [equipmentStatuses, selectedEquipmentId, snap, mapPointer]);

  useEffect(() => {
    if (snap && !selectedEquipmentId) {
      setSnap(false);
    }
  }, [snap, selectedEquipmentId, setSnap]);

  // Create snap postrender listener
  useEffect(() => {
    if (!mapPointer) return; // not ready to create listener yet
    const callback = () => {
      persistSelectedVehiclePosition.current();
      if (cameraPositionRef.current === null) return;
      const { longitude, latitude } = cameraPositionRef.current;
      if (!longitude || !latitude || !snapRef.current.enabled) return;
      // Use animate instead of setCenter to center the view. The difference is that the animation is
      // cancelled when scrolling using the mouse wheel, whereas the setCenter is cancelling the mouse wheel
      // zoom animation
      mapPointer.getView().animate({ center: fromLonLat([longitude, latitude]), duration: 10, easing: inAndOut });
    };

    mapPointer.on('postrender', callback);
    return () => mapPointer.un('postrender', callback);
  }, [mapPointer, snapRef, persistSelectedVehiclePosition, poiLayer]);

  return <div id="map" className={root} ref={mapContainerRef} />;
};

export default withEquipment(memo(MapContainer, isEqualOmitEquipmentTimestamps));
