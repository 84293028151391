import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { fromLonLat } from 'ol/proj';
import { Circle as CircleStyle, Style, Stroke, Fill } from 'ol/style';
import { degreesToRadians } from './Map.helpers';
import { feelInstantaneous } from '../../model/duration';
import getVehicleStateColor from '../../model/vehicle/state/getVehicleStateColor';
import scaleModifier from './scaleModifier';
import { blue, white } from '../../theme/color';

function createOLVehicleFeature(externalEquipmentReference, getVehiclePosition, clickCallback) {
  // Vehicle state
  let visibilityFactor = 0;
  let animation = null;
  let IVehiclePersisted = null;
  let selected = null;
  let clicked = false;
  let hover = false;

  const fill = new Fill({
    color: 'rgba(255,255,255,0.4)',
  });
  const stroke = new Stroke({
    color: blue,
    width: 1.25,
  });

  const feature = new Feature({
    externalEquipmentReference,
    name: 'vehicle',
  });

  const style = new Style({
    image: new CircleStyle({
      fill, // Not used when using custom renderer
      stroke, // Not used when using custom renderer
      radius: 23, // This is only used for hover and click events and needs to be dynamic
    }),
    fill, // Not used when using custom renderer
    stroke, // Not used when using custom renderer
    renderer: (coordinates, state) => {
      if (!IVehiclePersisted) return null;

      const { context, pixelRatio } = state;
      const { position, operationalState, mode, online, timestamp } = IVehiclePersisted;
      const { longitude, latitude, heading } =
        getVehiclePosition(
          position.longitude,
          position.latitude,
          position.heading,
          timestamp,
          new Date().toISOString(),
        ) || {};
      const vehicleColor = getVehicleStateColor(operationalState, mode, online);

      // If algorithms need more than one data-point to give return values, hide the vehicle until there is enough data, null = hidden vehicle
      if (!longitude || !latitude || !heading) return;

      // This might be used to update the geometry during each render
      const point = new Point(fromLonLat([longitude, latitude]));
      feature.setGeometry(point);

      // latitude and longitude is already calculated since we have to run setGeometry to get the renderer to trigger, hence we can use coordinates array

      // Using visibilityFactor to scale in aswell
      const scale = (0.2 + visibilityFactor) * pixelRatio * scaleModifier(state.resolution);

      if (animation) animation(context);
      context.globalAlpha = visibilityFactor;

      let circleScale = scale;
      if (hover) {
        circleScale *= 1.1;
      }

      if (clicked) {
        circleScale *= 1.2;
      }

      // Shadow when vehicle is selected
      context.save();
      context.translate(coordinates[0], coordinates[1]);
      context.scale(circleScale * 1.5, circleScale * 1.5);
      context.beginPath();
      context.arc(0, 0, 20, 0, 2 * Math.PI);
      context.fillStyle = vehicleColor.shadow;
      if (selected) context.fill();
      context.restore();

      // Circle showing vehicle state
      context.save();
      context.translate(coordinates[0], coordinates[1]);
      context.scale(circleScale, circleScale);
      context.beginPath();
      context.arc(0, 0, 20, 0, 2 * Math.PI);
      context.fillStyle = vehicleColor.foreground;
      context.fill();
      context.restore();

      // Arrow within the vehicle circle
      context.save();
      context.translate(coordinates[0], coordinates[1]);
      context.rotate(degreesToRadians(heading));
      context.scale(scale * 1.2, scale * 1.2);
      context.translate(-7.75, -9.5);
      context.beginPath();
      context.moveTo(8, 0);
      context.lineTo(0.5, 15);
      context.lineTo(8, 11.25);
      context.lineTo(15.5, 15);
      context.lineTo(8, 0);
      context.fillStyle = white;
      context.fill();
      context.restore();

      context.restore();
    },
  });

  feature.setStyle((f_, resolution) => {
    const scale = scaleModifier(resolution);
    style.getImage().setScale(scale);
    return style;
  });

  feature.setProperties({
    customUpdateSelected: (IVehicle, _selected) => {
      const firstTime = IVehiclePersisted === null;
      IVehiclePersisted = IVehicle;
      selected = _selected;

      if (firstTime) {
        const { position } = IVehicle;
        const { longitude, latitude } = position;
        const point = new Point(fromLonLat([longitude, latitude]));
        feature.setGeometry(point); // This redundancy is used to 'bootstrap' the feature - no geometry means no custom renderfunction is called
      }
    },
    customUpdateHover: (IVehicle, _hover) => {
      const firstTime = IVehiclePersisted === null;
      IVehiclePersisted = IVehicle;
      hover = _hover;

      if (firstTime) {
        const { position } = IVehicle;
        const { longitude, latitude } = position;
        const point = new Point(fromLonLat([longitude, latitude]));
        feature.setGeometry(point); // This redundancy is used to 'bootstrap' the feature - no geometry means no custom renderfunction is called
      }
    },
    fadeIn: () => {
      animation = () => {
        if (visibilityFactor < 1) visibilityFactor += 0.1;
        if (visibilityFactor > 1) {
          animation = null;
          visibilityFactor = 1;
        }
      };
    },
    remove: (callback) => {
      animation = () => {
        if (visibilityFactor > 0) visibilityFactor -= 0.1;
        if (visibilityFactor <= 0) {
          animation = null;
          visibilityFactor = 0;
          callback();
        }
      };
    },
    click: () => {
      if (clicked) return;
      clicked = true;
      clickCallback();
      setTimeout(() => {
        clicked = false;
      }, feelInstantaneous);
    },
    getDisplayName: () => {
      return IVehiclePersisted?.displayName || 'Loading';
    },
  });

  return feature;
}

export default createOLVehicleFeature;
