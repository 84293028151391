import { memo } from 'react';
import { makeStyles, Typography, Divider } from '@material-ui/core';
import { EquipmentStatus_position as EquipmentStatusPosition, EquipmentMode, Equipment } from '@ats/graphql';
import clsx from 'clsx';
import IVehicle from '../../model/vehicle/IVehicle';
import { grey100, grey300, grey400, grey600, semiDimWhite } from '../../theme/color';
import { Wifi, Direction } from '../icons';
import getVehicleStateColor from '../../model/vehicle/state/getVehicleStateColor';
import getVehicleStateLabel from '../../model/vehicle/state/getVehicleStateLabel';
import getModeLabel from '../../model/vehicle/state/getModeLabel';
import VehicleDisplayName from './VehicleDisplayName';
import LastCommunicationPulseBar from './LastCommunicationPulseBar';

const useStyles = makeStyles(
  {
    root: {
      width: '368px',
      height: '330px',
      flexShrink: 0,
      flexGrow: 0,
      flexBasis: '330px',
    },
    mode: {
      color: grey300,
      position: 'absolute',
      left: '25px',
      top: '71px',
      textTransform: 'capitalize',
    },
    operationalState: {
      color: semiDimWhite,
      position: 'absolute',
      left: '25px',
      top: '92px',
    },
    speedContainer: {
      position: 'absolute',
      left: '24px',
      top: '200px',
      display: 'flex',
      alignItems: 'baseline',
    },
    speed: {
      color: semiDimWhite,
      fontFamily: '"Scania Sans", Arial, Helvetica, sans-serif',
      textAlign: 'left',
      fontSize: '56px',
      lineHeight: '56px',
      fontWeight: 700,
      paddingRight: '12px',
    },
    speedUnit: {
      color: grey100,
    },
    absoluteDivider: {
      position: 'absolute',
      width: '320px',
      left: '24px',
      top: '268px',
      margin: 0,
    },
    wifiIcon: { position: 'absolute', left: '293px', top: '16px' },
    vehicleDataAge: {
      position: 'absolute',
      width: '30px',
      height: '16px',
      left: '324px',
      top: '20px',
      color: grey100,
    },
    personalStanddownIcon: {
      position: 'absolute',
      left: '3px',
      top: '3px',
    },
    invehicleStanddownIcon: { position: 'absolute', left: '3px', top: '3px' },
    directionIcon: { position: 'absolute', left: '309px', top: '218px' },
    lockIcon: {
      position: 'absolute',
      left: '4px',
      top: '3px',
    },
    controlContainer: {
      '&::-webkit-scrollbar': {
        color: grey600,
        width: '4px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: grey400,
      },
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      flexBasis: 'auto',
      flexShrink: 1,
    },
    offlineSpeed: {
      color: grey600,
    },
  },
  { index: 1 },
);

// We want as little as possible to be sent to this component to avoid re-rendering due to data we don't use (for example position (other than heading))
interface IProps {
  vehicleData: Pick<IVehicle, 'mode' | 'operationalState' | 'speed' | 'owner' | 'online' | 'releaseConditions'> & {
    position: Pick<EquipmentStatusPosition, 'heading'> | undefined | null;
  };
  vehicle: Equipment;
  vehicleDataAgeComponent: React.ReactNode;
  lastCommunicationHoverLabel: React.ReactNode;
}

const BasicVehicleInfo = ({ vehicleData, vehicleDataAgeComponent, lastCommunicationHoverLabel, vehicle }: IProps) => {
  const classes = useStyles();
  const { foreground, lastCommunicationStateColor } = getVehicleStateColor(
    vehicleData.operationalState,
    vehicleData.mode,
    vehicleData.online,
  );

  const showModeLabel = vehicleData.mode !== EquipmentMode.MODE_MANUAL && vehicleData.online;
  const offlineSpeedColor = vehicleData.online ? '' : classes.offlineSpeed;

  return (
    <>
      <div className={classes.root} id="basicVehicleInfo">
        <VehicleDisplayName vehicle={vehicle} />
        <LastCommunicationPulseBar lastCommunicationStateColor={lastCommunicationStateColor} />
        <Wifi
          className={classes.wifiIcon}
          online={vehicleData.online}
          lastCommunicationHoverLabel={lastCommunicationHoverLabel}
          testid="vehicle-wifi-icon"
        />
        <div className={clsx(classes.vehicleDataAge, 'sdds-headline-07')}>{vehicleDataAgeComponent}</div>
        <Typography className={clsx(classes.mode, 'sdds-detail-02')} data-testid="basicVehicleInfo-mode">
          {showModeLabel ? getModeLabel(vehicleData.mode) : ' '}
        </Typography>
        <Typography
          className={clsx(classes.operationalState, 'sdds-headline-03')}
          data-testid="basicVehicleInfo-operationalState"
        >
          {getVehicleStateLabel(vehicleData.operationalState, vehicleData.mode, vehicleData.online, true) ?? '-'}
        </Typography>
        <div className={classes.speedContainer}>
          <Typography className={clsx(classes.speed, offlineSpeedColor)} data-testid="basicVehicleInfo-speed">
            {parseFloat(`${vehicleData.speed ?? 0}`).toFixed(0)}
          </Typography>

          <Typography className={clsx(classes.speedUnit, 'sdds-body-01', offlineSpeedColor)}>km/h</Typography>
        </div>
        <Divider className={classes.absoluteDivider} data-testid="basicVehicleInfo-divider" />

        <Direction heading={vehicleData.position?.heading} className={classes.directionIcon} color={foreground} />
      </div>
    </>
  );
};
export default memo(BasicVehicleInfo);
