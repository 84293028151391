import { makeStyles, Typography } from '@material-ui/core';
import { Poi } from '@ats/graphql';
import clsx from 'clsx';
import { grey100 } from '../../theme/color';

const useStyles = makeStyles(
  {
    displayName: {
      color: grey100,
      position: 'absolute',
      top: '80px',
      left: '28px',
      width: '117px',
      textAlign: 'center',
      display: 'inline-block',
      whiteSpace: 'nowrap',
    },
  },
  { index: 1 },
);

interface IProps {
  pointOfInterest: Poi;
}

const PoiDisplayName = ({ pointOfInterest }: IProps) => {
  const { displayName } = useStyles();

  return <Typography className={clsx(displayName, 'sdds-headline-03')}>{pointOfInterest.displayName}</Typography>;
};
export default PoiDisplayName;
