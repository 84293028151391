import { Card, CardContent, Link, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { t } from 'ttag';

import { useDegradations, useDegradationPlan } from '@ats/graphql';

import Degradation, { useStyles as useDegradationStyles } from './Degradation';
import DegradationHeader from './DegradationHeader';
import getDegradationPlans from '../../model/degradation/getDegradationPlans';
import getDegradationsSummary from '../../model/degradation/getDegradationsSummary';
import { blue500 } from '../../theme/color';
import theme from '../../theme/theme';

import LoadingSkeleton from '../LoadingSkeleton';

import getDegradationStateColor from '../../model/degradation/getDegradationStateColor';

interface IProps {
  online: boolean;
  selectedAreaId: string | null;
  selectedEquipmentId: string;
}

const useStyles = makeStyles(
  {
    root: {
      marginLeft: 'auto',
      marginRight: 'auto',
      flexShrink: 1,
      flexGrow: 0,
      flexBasis: '154px',
      width: '320px',
    },
    spaced: {
      margin: theme.spacing(5),
    },
    link: {
      color: blue500,
    },
    typographySeeMore: {
      marginTop: theme.spacing(4),
      padding: theme.spacing(0, 4, 0),
    },
    typographySummaryExpanded: {
      marginBottom: theme.spacing(8),
    },
  },
  { index: 1 },
);

const Degradations = (props: IProps) => {
  const { online, selectedAreaId, selectedEquipmentId } = props;
  const { link, root, typographySeeMore, typographySummaryExpanded, spaced } = useStyles();
  const { card, content, typography } = useDegradationStyles({ nested: false });
  const [expanded, setExpanded] = useState<boolean>(false);
  const [allDegradations] = useDegradations({
    areaId: selectedAreaId,
    externalEquipmentReference: selectedEquipmentId,
  });

  const [degradationPlan] = useDegradationPlan({ externalEquipmentReference: selectedEquipmentId });

  useEffect(() => {
    setExpanded(false);
  }, [selectedEquipmentId]);

  if (!online || allDegradations === null || allDegradations.length === 0) return null;

  if (degradationPlan === null || degradationPlan.length !== 1)
    return (
      <div className={spaced}>
        <LoadingSkeleton />
      </div>
    );

  const degradations = getDegradationPlans(degradationPlan[0], allDegradations[0].degradations);

  if (degradations.length === 0) return null;

  const { foreground } = getDegradationStateColor(degradations[0]?.action);

  return (
    <div className={root}>
      {degradations.length === 1 && <Degradation degradation={degradations[0]} allDegradations={allDegradations} />}
      {degradations.length > 1 && (
        <Card style={{ borderTopColor: foreground }} className={card}>
          <DegradationHeader action={degradations[0].action} data-testid="degradation-header" />
          <CardContent className={content}>
            <Typography
              className={clsx(typography, expanded && typographySummaryExpanded, 'sdds-detail-03')}
              data-testid="degradation-summary"
            >
              {getDegradationsSummary(degradations)}
            </Typography>
            {expanded &&
              degradations.map((degradation) => (
                <Degradation
                  degradation={degradation}
                  key={degradation.description}
                  nested
                  allDegradations={allDegradations}
                />
              ))}
            <Typography className={clsx(typographySeeMore, 'sdds-detail-03')}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link
                className={link}
                component="button"
                onClick={() => setExpanded(!expanded)}
                data-testid="degradation-summary-expand-toggle"
              >
                {expanded ? t`See less` : t`See more`}
              </Link>
            </Typography>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default Degradations;
