const CameraViewIcon = () => (
  <svg
    data-testid="equipment-panel-camera-view-button"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.625 6.625V16C18.625 16.1381 18.5131 16.25 18.375 16.25H2.5C2.36193 16.25 2.25 16.1381 2.25 16V6.625C2.25 6.48693 2.36193 6.375 2.5 6.375H5.41924C6.27571 6.375 7.00633 5.75512 7.14586 4.91009L7.30285 3.95927C7.32278 3.83855 7.42716 3.75 7.54951 3.75H13.6543C13.7766 3.75 13.881 3.83855 13.901 3.95927L14.0579 4.91009C14.1975 5.75511 14.9281 6.375 15.7846 6.375H18.375C18.5131 6.375 18.625 6.48693 18.625 6.625Z"
      stroke="white"
      strokeWidth="1.5"
    />
    <circle cx="10.5" cy="11" r="2.5" stroke="white" />
  </svg>
);

export default CameraViewIcon;
