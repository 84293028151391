import { interpolate } from 'd3-interpolate';
import { clone, equals } from 'ramda';

const isWithinFirstRevolution = (r) => r >= 0 && r <= 360;

const constrain = (snapshot) => {
  if (isWithinFirstRevolution(snapshot.heading + 360)) {
    return { ...snapshot, heading: snapshot.heading + 360 };
  }
  if (isWithinFirstRevolution(snapshot.heading - 360)) {
    return { ...snapshot, heading: snapshot.heading - 360 };
  }
  return snapshot;
};

export const solveNorthCase = (a, b) => {
  const alpha = Math.abs(a - b);
  const beta = Math.abs(a + 360 - b);

  if (a === b || alpha < 180) return [a, b];

  if (beta > alpha && a > b) {
    return [a, b + 360];
  }
  if (beta < alpha && a < b) {
    return [a + 360, b];
  }
  return [a, b];
};

/*
This catch up function needs two data points and a timestamp that increments for each render pass. The
function then interpolates between the data points using the "now"-timestamp to determine where in the
interpolation it is.
The signature is that you call a generate function (HOC) that returns a new function, which you call to
get the position every render, and you provide the current data point every time aswell, even though no
new data has been received from the back-end. This means that this algorithm also detects when there is
new "fresh" data that is meaningful to store.
*/
export function generateCatchUp() {
  // The "from"-point in space and time of real vehicle data
  let snapshotA = null;

  // The "to"-point in space and time of real vehicle data
  let snapshotB = null;

  // The previously rendered interpolated position
  let snapshotC = null;

  let syncronizer = null;

  return (longitude, latitude, heading, timestamp, now) => {
    // The input snapshot every render
    const snapshotD = { longitude, latitude, heading, timestamp };

    // Fresh data - store as both values for now but return null
    if (!snapshotA) {
      snapshotA = clone(snapshotD);
      snapshotB = clone(snapshotD);

      syncronizer = new Date(now).getTime() - new Date(timestamp).getTime();

      return null;
    }

    // Without any input, assume that no position can be derived
    if (!longitude || !latitude || !heading || !timestamp) {
      return null;
    }

    // Detect fresh data, so do a indiana jones swap for the snapshots
    if (!equals(snapshotD, snapshotB)) {
      snapshotA = clone(snapshotC || snapshotB);

      snapshotB = snapshotD;

      snapshotA = constrain(snapshotA);
      snapshotB = constrain(snapshotB);

      syncronizer = new Date(now).getTime() - new Date(timestamp).getTime();
    }

    const nowTime = new Date(now).getTime();
    const previousTime = new Date(snapshotA.timestamp).getTime();
    const currentTime = new Date(snapshotB.timestamp).getTime();
    const durationTime = currentTime - previousTime;
    const playbackTime = nowTime - previousTime - durationTime - syncronizer;

    // Between 0 and 1 - where we are rendering right now between the two datapoints we have
    const floatingSpan = durationTime === 0 ? 0 : playbackTime / durationTime;

    const cappedFloatingSpan = Math.max(0, Math.min(1, floatingSpan));

    const [northAdjustedA, northAdjustedB] = solveNorthCase(snapshotA.heading, snapshotB.heading);

    snapshotC = {
      longitude: interpolate(snapshotA.longitude, snapshotB.longitude)(cappedFloatingSpan),
      latitude: interpolate(snapshotA.latitude, snapshotB.latitude)(cappedFloatingSpan),
      heading: interpolate(northAdjustedA, northAdjustedB)(cappedFloatingSpan),
      timestamp,
    };

    return snapshotC;
  };
}

export function rawData(longitude, latitude, heading) {
  return {
    longitude,
    latitude,
    heading,
  };
}
