const RefreshIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.41155 12.1402C3.41155 12.1402 4.50646 16.8848 9.61606 16.8848C14.7257 16.8848 16.1855 12.1402 16.1855 12.1402"
      stroke="#F9FAFB"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.6351 11.7565L16.433 11.0068C16.7133 10.9316 17.0015 11.098 17.0766 11.3783L17.8263 14.1762"
      stroke="#F9FAFB"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M16.6164 7.85983C16.6164 7.85983 15.5215 3.1152 10.4119 3.1152C5.30229 3.1152 3.84241 7.85983 3.84241 7.85983"
      stroke="#F9FAFB"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.39285 8.24366L3.59495 8.99336C3.31462 9.06847 3.02647 8.90211 2.95135 8.62178L2.20166 5.82388"
      stroke="#F9FAFB"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export default RefreshIcon;
