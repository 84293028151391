import {
  Degradations as IDegradations,
  DegradationPlan as IDegradationPlan,
  DegradationPlanElementData as IDegradationPlanElementData,
  DegradationPlanReleaseCondition,
  DegradationPlanAction,
} from '@ats/graphql';
import prettyText from '../prettyText';

const ignoredDegradations = [
  'OffboardStandDownRequestAutomaticRelease',
  'OffboardStandDownRequestOffboardRelease',
  'OffboardStandDownRequestOnboardRelease',
  'OnboardStandDownRequest',
];

export interface IDegradationPlanElementDataWithName extends IDegradationPlanElementData {
  name: string;
}

const getDegradationPlans = (degradationPlan: IDegradationPlan, degradations: IDegradations['degradations']) =>
  degradations
    .filter((degradation) => !ignoredDegradations.includes(degradation))
    .map((degradation) => {
      const info = degradationPlan.degradationInformation.find((i) => i.degradation === degradation);
      return info
        ? {
            ...info.information,
            name: degradation,
          }
        : ({
            name: degradation,
            action: DegradationPlanAction.Unknown,
            description: prettyText(`${degradation}.`),
            releaseCondition: DegradationPlanReleaseCondition.Unknown,
            operatorActionSequenceWithActiveFault: [],
            operatorActionSequenceWithPassiveFault: [],
          } as IDegradationPlanElementDataWithName);
    })
    .sort(
      (a: IDegradationPlanElementDataWithName, b: IDegradationPlanElementDataWithName) =>
        1024 *
          (Object.values(DegradationPlanAction).indexOf(a.action) -
            Object.values(DegradationPlanAction).indexOf(b.action)) +
        Object.values(DegradationPlanReleaseCondition).indexOf(a.releaseCondition) -
        Object.values(DegradationPlanReleaseCondition).indexOf(b.releaseCondition),
    );
export default getDegradationPlans;
