import { makeStyles, Typography } from '@material-ui/core';
// import { SiteRelation_queueRelations as SiteRelationQueueRelation } from '@ats/graphql';

import clsx from 'clsx';
import { grey100 } from '../../theme/color';

const useStyles = makeStyles(
  {
    displayNameStyle: {
      color: grey100,
      position: 'absolute',
      top: '80px',
      textAlign: 'center',
      display: 'inline-block',
      paddingLeft: '18px',
      paddingRight: '18px',
      overflow: 'hidden',
      width: '100%',
    },
  },
  { index: 1 },
);

interface IProps {
  // zone: SiteRelationQueueRelation;
  displayName: string;
}

const ZoneDisplayName = ({ displayName }: IProps) => {
  const { displayNameStyle } = useStyles();

  return <Typography className={clsx(displayNameStyle, 'sdds-headline-03')}>{displayName}</Typography>;
};
export default ZoneDisplayName;
