import { makeStyles, List, ListItem } from '@material-ui/core';
import { useQueueList } from '@ats/graphql';
import { useObservable } from '@libreact/use-observable';
import { grey100, grey700, grey900 } from '../../theme/color';
import {
  selectedAreaId as selectedAreaIdObservable,
  selectedQueueId as selectedQueueIdObservable,
} from '../../model/observables';
import { Close } from '../icons';
import { Selected } from '../../model/type/Selected';
import ZoneDisplayName from './ZoneDisplayName';
import QueueIcon from '../icons/QueueIcon';
import QueueStatus from './QueueStatus';

// TODO: Styles were copied from ZonePanel, so not all are relevant... remove unused

const useStyles = makeStyles(
  {
    root: {
      backgroundColor: grey900,
      borderTop: `8px solid ${grey700}`,
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100vh - 64px)',
      position: 'relative',
    },
    queueSummary: {
      width: '368px',
      height: '260px',
      flexShrink: 0,
      color: 'white',
    },
    icons: {
      position: 'absolute',
      top: '164px',
      display: 'flex',
      left: '93px',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    queueIcon: {
      width: '56px',
      height: '56px',
    },
    close: {
      position: 'absolute',
      color: grey100,
      top: '2px',
      left: '10px',
      width: '40px',
      height: '40px',
      cursor: 'pointer',
    },
  },
  { index: 1 },
);

interface IProps {
  selectedEquipmentState: [string | null, React.Dispatch<React.SetStateAction<null>>];
  setSelected: (selected: Selected) => void;
}

const QueuePanel = (props: IProps) => {
  const { selectedEquipmentState, setSelected } = props;
  const { root, queueSummary, icons, queueIcon, close } = useStyles();

  const [areaId] = useObservable(selectedAreaIdObservable, null);
  const [selectedQueueId] = useObservable(selectedQueueIdObservable, null);
  const [queueList] = useQueueList({ areaId });

  // Get info about the queue if a queue is selected (otherwise we will list the queues)
  const queueInfo = selectedQueueId && queueList ? queueList.find((item) => item.id === selectedQueueId) : null;

  const selectQueue = (id: string) => {
    selectedQueueIdObservable.next(id);
  };

  return (
    <div className={root} id="QueuePanel">
      {queueInfo ? (
        <>
          <div className={queueSummary} id="queueSummary">
            <Close click={() => setSelected(null)} className={close} />
            <ZoneDisplayName displayName={JSON.parse(queueInfo.metaData).displayName} />
            <div className={icons}>
              <QueueIcon className={queueIcon} />
            </div>
          </div>
          <QueueStatus queueId={selectedQueueId} areaId={areaId} selectedEquipmentState={selectedEquipmentState} />
        </>
      ) : (
        <div id="queueList">
          <Close click={() => setSelected(null)} className={close} />
          <div className={queueSummary} style={{ marginTop: 30 }}>
            {queueList && queueList.length ? (
              <List>
                {queueList.map((ql) => (
                  <ListItem key={ql.id} onClick={() => selectQueue(ql.id)}>
                    <QueueIcon className={queueIcon} />
                    <span style={{ padding: 10 }}>{JSON.parse(ql.metaData).displayName}</span>
                  </ListItem>
                ))}
              </List>
            ) : (
              queueList && !queueList.length && <span style={{ padding: 30 }}>No queues</span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default QueuePanel;
