import { Button, makeStyles } from '@material-ui/core';
import { useRef, useEffect } from 'react';
import { useRiskmap, RISKMAP_CELL_ROWS, RISKMAP_CELL_COLUMNS, RiskmapCellRow, RiskClass } from '@ats/graphql';

import { SubView } from '../model/ViewTypes';
import { blue700, blue800, blue900 } from '../theme/color';

interface IColors {
  key: RiskClass;
  value: number[];
}

// Lookup table for colors for each RiskClass
const COLORS: IColors[] = [
  {
    key: RiskClass.INVALID,
    value: [111, 111, 111],
  },
  {
    key: RiskClass.OCCUPIED_INSIDE_GEOFENCE,
    value: [231, 233, 238],
  },
  { key: RiskClass.OCCUPIED_OUTSIDE_GEOFENCE, value: [29, 34, 41] },
  { key: RiskClass.UNOCCUPIED_INSIDE_GEOFENCE, value: [195, 75, 83] },
  { key: RiskClass.UNOCCUPIED_OUTSIDE_GEOFENCE, value: [13, 15, 19] },
  {
    key: RiskClass.UNSEEN_INSIDE_GEOFENCE,
    value: [255, 255, 0], // Fix proper colors
  },
  { key: RiskClass.UNSEEN_OUTSIDE_GEOFENCE, value: [13, 15, 16] },
];

function updateImageData(imageData: ImageData, cellRows: RiskmapCellRow[]): ImageData {
  const { data } = imageData;
  for (let i = 0; i < data.length; i += 4) {
    const targetRow = Math.floor(i / 4 / RISKMAP_CELL_ROWS);
    const targetColumn = (i / 4) % RISKMAP_CELL_COLUMNS;

    const target = cellRows[targetRow].cellRisks[targetColumn];
    if (target) {
      const color = COLORS.find((c) => c.key === target)?.value;
      if (color === undefined) {
        throw new Error(`Missing color specification for ${target}`);
      }
      const [r, g, b] = color;
      data[i + 0] = r;
      data[i + 1] = g;
      data[i + 2] = b;
      data[i + 3] = 255; // alpha
    }
  }
  return imageData;
}
const useStyles = makeStyles(
  {
    button: {
      border: 0,
      fontSize: '14px',
      borderRight: `1px solid ${blue800}`,
      borderRadius: 0,
      height: '26px',
      margin: '10px',
      padding: '20px',
      backgroundColor: blue900,
      transition: 'none',
      '&:hover, &:focus': {
        border: 'unset',
        marginRight: '1px',
        backgroundColor: blue800,
      },
      '&:active': {
        backgroundColor: blue700,
      },
      '&:visited': {
        outline: 'unset',
      },
    },
  },
  { index: 1 },
);

interface IProps {
  areaId: string;
  externalEquipmentReference: string;
  setSubView?: (arg0: SubView) => void;
  preview: boolean;
}

function RiskMap(props: IProps) {
  const { areaId, externalEquipmentReference, setSubView, preview } = props;
  const [riskmap] = useRiskmap({ areaId, externalEquipmentReference });
  const imageDataRef = useRef<ImageData | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const { button } = useStyles();

  useEffect(() => {
    if (!riskmap || !riskmap[0] || !riskmap[0].timestamp) return;

    const canvas = canvasRef.current;
    if (!canvas) return;
    canvas.width = RISKMAP_CELL_COLUMNS;
    canvas.height = RISKMAP_CELL_ROWS;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;
    imageDataRef.current = ctx.createImageData(RISKMAP_CELL_COLUMNS, RISKMAP_CELL_ROWS);
  }, [riskmap]);

  useEffect(() => {
    if (!riskmap || !riskmap[0] || !riskmap[0].cellRows || !canvasRef.current || !imageDataRef.current) {
      return;
    }

    if (sessionStorage.getItem('measurePerformance')) window.performance.mark('RISKMAP_UPDATE:START');

    imageDataRef.current = updateImageData(imageDataRef.current, [...riskmap[0].cellRows]);
    const ctx = canvasRef.current.getContext('2d');
    if (!ctx) return;
    ctx.putImageData(imageDataRef.current, 0, 0);

    if (sessionStorage.getItem('measurePerformance')) window.performance.mark('RISKMAP_UPDATE:END');
    if (sessionStorage.getItem('measurePerformance'))
      window.performance.measure('RISKMAP_UPDATE', 'RISKMAP_UPDATE:START', 'RISKMAP_UPDATE:END');
  }, [riskmap, canvasRef, imageDataRef]);

  return (
    <div id="riskmap">
      {!preview ? (
        <div style={{ position: 'absolute' }} onClick={() => setSubView && setSubView(SubView.Dashboard)}>
          <Button className={button}>Back</Button>
        </div>
      ) : null}
      <canvas
        ref={canvasRef}
        style={{ width: '100%', height: preview ? '100%' : 'calc(100vh - 70px)', imageRendering: 'pixelated' }}
      />
    </div>
  );
}

export default RiskMap;
