import bearing from '@turf/bearing';
import type { Position } from 'geojson';
import type { DeepReadonly } from 'ts-essentials';

const getHeading = (coordinates: DeepReadonly<Position[]>, index: number): number =>
  bearing(
    coordinates[index && index - 1] as number[],
    coordinates[index < coordinates.length - 1 ? index + 1 : index] as number[],
  );

export default getHeading;
