import { Mission, MissionState } from '@ats/graphql';

const getStartTime = (
  mission: Mission,
  actionIndex: number,
  states: ReadonlyArray<MissionState> | null,
  commandIndex?: number,
): Date | null => {
  const missionState = states?.find(
    (state) =>
      state.missionId === mission.id &&
      state.executing !== null &&
      state.executing.actionIndex === actionIndex &&
      (commandIndex ? state.executing.commandIndex === commandIndex : true),
  );
  return missionState ? new Date(missionState.timestamp) : null;
};
export default getStartTime;
