/*
This is all events used in the app. String enums for better readability when debugging.
*/

enum Event {
  /* Used to show the generic modal with a provided message. New errors will replace an existing one.
     Custom data: { detail: { msg: 'Any message string here' }} */
  GENERIC_ERROR = 'GENERIC_ERROR',

  /* Used to show the hard coded modal that the map failed to download */
  MAP_DOWNLOAD_FAILED = 'MAP_DOWNLOAD_FAILED',

  /* Dispatched whenever an operation that modifies the layout of the map element happens. An example would
     be that a panel that occupies space next to the map opens */
  MAP_AREA_CHANGED = 'MAP_AREA_CHANGED',

  /* Dispatched whenever data that has rendered vehicles is obsolete. Map is then in charge of removing all
     vehicles and let the algorithms to detect new vehicles trigger the recreation of the vehicles */
  REMOVE_ALL_VEHICLES = 'REMOVE_ALL_VEHICLES',

  /* Used to remove label slave feature
    Custom = Custom data: { detail: { id: 'UUID_OF_VEHICLE' }} */
  VEHICLE_REMOVED = 'VEHICLE_REMOVED',

  /* Used to remove label slave feature
    Custom = Custom data: { detail: { id: 'UUID_OF_POI' }} */
  POI_REMOVED = 'POI_REMOVED',

  /* Dispatched whenever the user clicks the map
     Custom data: { detail: position: [15, 16] }} */
  MAP_CLICK = 'MAP_CLICK',

  /* Dispatched whenever the map zooms in */
  ZOOM_IN = 'ZOOM_IN',

  /* Dispatched whenever the map zooms out */
  ZOOM_OUT = 'ZOOM_OUT',

  /* Dispatched to show a toast
     Custom = Custom data: { detail: { title: 'Any title string here', message: 'Any message string here' }} */
  TOAST = 'TOAST',

  /* Dispatched to show the state of previewMission, whether it is loading or loading fails */
  PREVIEW_LOAD = 'PREVIEW_LOAD',

  /* Dispatched to show a modal
    Custom = Custom data: { detail: {       title: 'Any title string here',
     description: 'description string here',
     subDescription: 'subdescription string here',
     cancelLabel: 'cancel action label',
     executeLabel: 'execute/save action label ',
     onLoadingLabel: 'label shown when promise ',
     executeCallback: execute/save action callback,}} */
  MODAL = 'MODAL',
}

export default Event;
