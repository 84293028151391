import { makeStyles } from '@material-ui/core';
import { blue, blue400, blue500, blue600, grey100, grey300, grey900 } from '../theme/color';

const useModalStyles = makeStyles(
  {
    paper: {
      backgroundColor: grey900,
      width: '544px',
    },
    dialogTitle: {
      padding: '16px',
      color: grey100,
    },
    closeButton: {
      position: 'absolute',
      right: '4px',
      top: '4px',
      padding: '4px',
    },
    dialogContent: {
      paddingLeft: '16px',
    },
    formControl: {
      display: 'flex',
    },
    formLabel: {
      color: grey300,
      '&.Mui-focused': {
        color: grey300,
      },
    },
    formVehiclebehaviour: {
      paddingTop: '20px',
      color: grey300,
    },
    formSound: {
      paddingTop: '24px',
      color: grey300,
    },
    formLayer: {
      paddingTop: '24px',
      color: grey300,
    },
    formControlLabel: {
      '&:first-child, .MuiFormLabel-root + &': {
        paddingTop: '8px',
      },
    },
    label: {
      color: grey100,
    },
    blueButton: {
      marginLeft: '12px',
      minWidth: '80px',
      height: '56px',
      padding: '20px',
      backgroundColor: blue600,
      borderRadius: '4px',
      '&:hover': {
        backgroundColor: blue500,
      },
      '&:focus': {
        background: blue400,
      },
    },
    cancelButton: {
      width: '84px',
      height: '56px',
      padding: '20px',
      borderRadius: '4px',
      borderColor: 'rgba(255, 255, 255, 0.38)',
      border: `1px solid`,
      '&:hover': {
        border: `1px solid`,
        borderColor: 'rgba(255, 255, 255, 0.6)',
        backgroundColor: grey900,
      },
      '&:focus': {
        border: `1px solid ${blue}`,
      },
    },
    actionButtons: {
      paddingTop: '40px',
      paddingBottom: '16px',
    },
  },
  { index: 1 },
);

export default useModalStyles;
