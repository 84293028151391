import { SvgIcon, Tooltip } from '@material-ui/core';
import { ICommonIconProps, useIconStyles } from './CommonIconProps';
import { grey100 } from '../../theme/color';

interface IProps extends ICommonIconProps {
  online: boolean;
  lastCommunicationHoverLabel: React.ReactNode;
}

export const Wifi = ({ className, online, lastCommunicationHoverLabel, testid }: IProps) => {
  const iconStyles = useIconStyles();

  return online ? (
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={
        <span className={iconStyles.tooltipTypography} style={{ whiteSpace: 'nowrap', width: '135px' }}>
          Last communication:{'\u00A0'}
          {lastCommunicationHoverLabel}
        </span>
      }
    >
      <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" className={className} data-testid={testid}>
        <path
          d="M8.4 21.8C7.03384 20.7754 5.92501 19.4468 5.1613 17.9193C4.3976 16.3919 4 14.7077 4 13C4 11.2923 4.3976 9.60806 5.1613 8.08065C5.92501 6.55324 7.03384 5.22461 8.4 4.2L9.6 5.8C8.48224 6.63832 7.575 7.72538 6.95016 8.97507C6.32531 10.2248 6 11.6028 6 13C6 14.3972 6.32531 15.7752 6.95016 17.0249C7.575 18.2746 8.48224 19.3617 9.6 20.2L8.4 21.8Z"
          fill={grey100}
        />
        <path
          d="M11.33 18.22C10.5951 17.5634 10.0071 16.759 9.60451 15.8595C9.20193 14.9599 8.99384 13.9855 8.99384 13C8.99384 12.0145 9.20193 11.0401 9.60451 10.1405C10.0071 9.241 10.5951 8.43661 11.33 7.78L12.67 9.27C12.1446 9.73902 11.7242 10.3138 11.4363 10.9566C11.1485 11.5993 10.9997 12.2957 10.9997 13C10.9997 13.7043 11.1485 14.4007 11.4363 15.0434C11.7242 15.6862 12.1446 16.261 12.67 16.73L11.33 18.22Z"
          fill={grey100}
        />
        <path d="M17 12H15V30H17V12Z" fill={grey100} />
        <path
          d="M19.33 16.73L20.67 18.22C21.4049 17.5634 21.9929 16.759 22.3955 15.8595C22.7981 14.9599 23.0062 13.9855 23.0062 13C23.0062 12.0145 22.7981 11.0401 22.3955 10.1405C21.9929 9.241 21.4049 8.43661 20.67 7.78L19.33 9.27C19.8554 9.73902 20.2758 10.3138 20.5637 10.9566C20.8515 11.5993 21.0003 12.2957 21.0003 13C21.0003 13.7043 20.8515 14.4007 20.5637 15.0434C20.2758 15.6862 19.8554 16.261 19.33 16.73Z"
          fill={grey100}
        />
        <path
          d="M22.4 20.2L23.6 21.8C24.9662 20.7754 26.075 19.4468 26.8387 17.9193C27.6024 16.3919 28 14.7077 28 13C28 11.2923 27.6024 9.60806 26.8387 8.08065C26.075 6.55324 24.9662 5.22461 23.6 4.2L22.4 5.8C23.5178 6.63832 24.425 7.72538 25.0499 8.97507C25.6747 10.2248 26 11.6028 26 13C26 14.3972 25.6747 15.7752 25.0499 17.0249C24.425 18.2746 23.5178 19.3617 22.4 20.2Z"
          fill={grey100}
        />
      </SvgIcon>
    </Tooltip>
  ) : (
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={<span className={iconStyles.tooltipTypography}>No communication</span>}
    >
      <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" className={className} data-testid={testid}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.4143 2L30 28.5859L28.5857 30L17 18.4141V30H15V16.4141L11.0393 12.4536C11.016 12.6349 11.0029 12.8173 11 13C11.0004 13.7034 11.1491 14.3988 11.4362 15.041C11.7233 15.6831 12.1425 16.2575 12.6665 16.7268L11.3325 18.2168C10.3114 17.3071 9.58058 16.1171 9.23119 14.7949C8.8818 13.4728 8.92928 12.077 9.3677 10.7817L7.1714 8.5854C6.09172 10.4851 5.74276 12.7136 6.19005 14.8524C6.63735 16.9911 7.85013 18.893 9.6006 20.2008L8.3994 21.8008C6.18515 20.1428 4.67813 17.7102 4.17955 14.9893C3.68096 12.2684 4.22752 9.45953 5.71 7.1241L2 3.4141L3.4143 2ZM26 13C26.0052 15.328 25.0989 17.5656 23.4751 19.2339L24.8884 20.647C25.9774 19.5281 26.8152 18.1899 27.3459 16.7216C27.8767 15.2533 28.0882 13.6886 27.9663 12.1321C27.8444 10.5755 27.392 9.06289 26.6392 7.69507C25.8863 6.32725 24.8504 5.13573 23.6006 4.2L22.3994 5.8C23.5212 6.63456 24.4315 7.72082 25.0568 8.97137C25.6822 10.2219 26.0053 11.6018 26 13ZM21 13C20.9981 14.0219 20.6832 15.0188 20.0977 15.8564L21.5254 17.2842C22.6141 15.8869 23.1311 14.1281 22.9717 12.364C22.8123 10.5999 21.9885 8.96223 20.667 7.7827L19.333 9.2727C19.8571 9.74203 20.2764 10.3165 20.5636 10.9587C20.8509 11.6009 20.9996 12.2965 21 13Z"
          fill={grey100}
        />
      </SvgIcon>
    </Tooltip>
  );
};
export default Wifi;
