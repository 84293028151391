import { useKeycloak } from '@react-keycloak/web';
import { useEffect, useState } from 'react';
import AppBar from './AppBar';
import lightningjs from '../vendor/lightningjs';
import { IUserAttributes } from '../model/user';
import { INotification } from '../model/notification/notificationType';
import { View } from '../model/ViewTypes';

interface IProps {
  openSettingsPanel: () => void;
  openLogDumpPanel: () => void;
  notificationPanelState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  selectedEquipmentState: [string | null, React.Dispatch<React.SetStateAction<string>>];
  markAsNotVisible: () => void;
  notifications: INotification[];
  setSnap: (arg0: boolean) => void;
  selectedAreaId: string;
  viewState: [View, React.Dispatch<React.SetStateAction<View>>];
}

const AppBarContainer = (props: IProps) => {
  const {
    openSettingsPanel,
    openLogDumpPanel,
    notificationPanelState,
    markAsNotVisible,
    notifications,
    selectedEquipmentState,
    setSnap,
    selectedAreaId,
    viewState,
  } = props;
  const [user, setUser] = useState<IUserAttributes | null>(null);
  const { keycloak } = useKeycloak();
  const isLoggedIn = keycloak.authenticated;

  useEffect(() => {
    if (isLoggedIn) {
      const token = keycloak.tokenParsed && keycloak.tokenParsed;
      const userAttributes = token as IUserAttributes;
      setUser(userAttributes);
    }
  }, [isLoggedIn, keycloak.tokenParsed]);

  const giveFeedback = () => {
    const usabilla = lightningjs.require('usabilla_live', 'https://w.usabilla.com/91e08fc9edb3.js');

    usabilla('hide');
    usabilla('click');
  };

  const signOut = () => {
    keycloak.logout();
  };

  return (
    <AppBar
      giveFeedback={giveFeedback}
      openSettingsPanel={openSettingsPanel}
      openLogDumpPanel={openLogDumpPanel}
      signOut={signOut}
      user={user}
      notificationPanelState={notificationPanelState}
      markAsNotVisible={markAsNotVisible}
      notifications={notifications}
      selectedEquipmentState={selectedEquipmentState}
      setSnap={setSnap}
      selectedAreaId={selectedAreaId}
      viewState={viewState}
    />
  );
};

export default AppBarContainer;
