import { ActionList, MissionInput } from '@ats/graphql';

import { Button, Card, CardContent, CardActions, CardHeader, makeStyles, Typography, Divider } from '@material-ui/core';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { t } from 'ttag';

import ClickAndDriveButton from './ClickAndDriveButton';
import { grey100, grey300 } from '../../theme/color';
import { mapInteractionModule as mapInteractionModuleObservable } from '../../model/observables';
import { useCardStyles } from './Card.styles';
import { useCommonStyles } from '../../theme/theme';
import NoPredefinedMissionView from './NoPredefinedMissionView';
import { isValidJSON } from '../../model/mission/extractTagsData';

interface IProps {
  setActions: (actions: MissionInput['actions'] | null) => void;
  setTags: (tags: MissionInput['tags'] | null) => void;
  setActionList: (actionList: ActionList | null) => void;
  actionLists: ReadonlyArray<ActionList> | null;
  clear: () => void;
  setPreviewMissionId: (id: string | null) => void;
  selectMission: (id: string, hoverMissionId: string | null) => void;
  canSelectMission: boolean;
  inStandDown: boolean;
  renderReleaseComponent: () => JSX.Element | null;
  vehicleMode: string | null;
  setRenderButton: (render: boolean) => void;
  darkMode: boolean;
  dispatcherRight: boolean;
}

const useStyles = makeStyles(
  {
    button: {
      borderColor: 'rgba(255, 255, 255, 0.38)',
      border: `1px solid`,
      color: grey100,
      marginTop: '8px',
      padding: '16px',
      textTransform: 'none',
    },
    typography: {
      color: grey300,
      marginBottom: '12px',
    },
  },
  { index: 1 },
);

export const SelectMission = ({
  actionLists,
  clear,
  setPreviewMissionId,
  selectMission,
  canSelectMission,
  inStandDown,
  renderReleaseComponent,
  vehicleMode,
  setRenderButton,
  setActions,
  setTags,
  setActionList,
  darkMode,
  dispatcherRight,
}: IProps) => {
  const { button, typography } = useStyles();
  const { ghostButton } = useCommonStyles();
  const {
    card,
    cardHeader,
    cardHeaderTypography,
    cardContent,
    cardActions,
    action,
    childOfContent,
    noBackground,
  } = useCardStyles();
  const [hoverMissionId, setHoverMissionId] = useState<string | null>(null);

  const actionList: ActionList | undefined = actionLists?.find(({ id }) => id === hoverMissionId);

  const isDispatchable = vehicleMode === 'Autonomous' || vehicleMode === 'Shadow';
  useEffect(() => {
    if (!inStandDown) {
      setRenderButton(false);
    } else {
      setRenderButton(true);
    }
  }, [setRenderButton, inStandDown]);

  useEffect(() => {
    if (actionList) {
      setActionList(actionList);
    }
  }, [setActionList, actionList]);

  const preDefinedMissionLabel = (tags: string, displayName: string) =>
    tags && isValidJSON(tags) && JSON.parse(tags).displayText ? JSON.parse(tags).displayText : displayName;

  const showRoutePreview = (id: string, actions: MissionInput['actions'], tags: MissionInput['tags']) => {
    setHoverMissionId(id);
    setPreviewMissionId(id);
    setActions(actions);
    setTags(tags);
  };

  const hideRoutePreview = () => {
    setHoverMissionId(null);
    setPreviewMissionId(null);
    setActionList(null);
    setActions(null);
    setTags(null);
  };
  return (
    <Card className={clsx(card, noBackground)}>
      <CardHeader
        action={
          <Button
            disableRipple
            className={clsx(ghostButton, 'sdds-headline-07')}
            onClick={clear}
            data-testid="clear-mission-button"
          >
            Clear
          </Button>
        }
        classes={{ action }}
        className={cardHeader}
        title={dispatcherRight ? t`Assign mission` : t`View missions`}
        titleTypographyProps={{ className: clsx(cardHeaderTypography, 'sdds-headline-04') }}
      />
      <Divider />
      {canSelectMission ? (
        <CardContent className={cardContent}>
          <Typography className={clsx(typography, 'sdds-headline-07')}>{t`Pre-defined`}</Typography>
          {actionLists?.map(({ id, displayName, actions, tags }) => (
            <Button
              className={clsx(button, 'sdds-detail-02')}
              fullWidth
              disableRipple
              key={id}
              onClick={() => {
                selectMission(id, hoverMissionId);
              }}
              onMouseEnter={() => showRoutePreview(id, actions, tags)}
              onMouseLeave={() => hideRoutePreview()}
              disabled={!isDispatchable}
              data-testid={`select-pre-defined-mission-${displayName}`}
            >
              {preDefinedMissionLabel(tags, displayName)}
            </Button>
          ))}
          <Divider className={childOfContent} />
          <ClickAndDriveButton
            testId="click-and-drive-button"
            disabled={darkMode || !dispatcherRight}
            onClick={() => mapInteractionModuleObservable.next('POSITION')}
          />
        </CardContent>
      ) : (
        <NoPredefinedMissionView darkMode={darkMode} title="Pre-defined Missions" />
      )}
      {inStandDown ? <CardActions className={cardActions}>{renderReleaseComponent()}</CardActions> : null}
    </Card>
  );
};
export default SelectMission;
