/* eslint-disable camelcase */
import { Button, Box, CircularProgress, makeStyles, Divider } from '@material-ui/core';
import { useState } from 'react';
import { sendTriggerLog } from '@ats/graphql';
import { v4 as uuid } from 'uuid';
import { grey900, grey800, white, blue400, blue500 } from '../../../theme/color';
import Copy from '../../icons/Copy';
import Events from '../../../model/events';

export const useStyles = makeStyles({
  card: {
    boxShadow: 'none',
    width: '368px',
    height: '210px',
    backgroundColor: grey900,
    cursor: 'pointer',
    color: white,
    position: 'relative',
    borderRadius: '4px',
  },
  cardFooter: {
    marginBottom: '16px',
  },
  cardHeader: {
    padding: '16px 0px 0px 16px',
    height: '15px',
  },
  cardDescription: {
    color: '#CDD1DB',
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '-0.01em',
    padding: '0px 16px 0px 16px',
    marginTop: '16px',
    lineHeight: '20px',
    height: '55px',
  },
  startEventButton: {
    maxWidth: '150px',
    minWidth: '120px',
    height: '40px',
    backgroundColor: blue400,
    borderRadius: '4px',
    position: 'static',
    fontSize: '14px',
    color: white,
    textTransform: 'none',
    display: 'flex',
    '&:hover': {
      backgroundColor: blue500,
      border: 'none',
      color: white,
    },
    '&:focus': {
      background: blue400,
      border: 'none',
      color: white,
    },
    '&:active': {
      color: white,
    },
    '&:disabled': {
      color: white,
    },
  },
  spacedItem: {
    marginLeft: '11px',
  },
  divider: {
    backgroundColor: grey800,
    margin: '0px 16px 0px 16px',
  },
  footerControl: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    paddingLeft: '16px',
    width: '340px',
  },
  logId: {
    width: '180px',
    height: '32px',
    letterSpacing: '-0.01rem',
    lineHeight: '16px',
    marginRight: '10px',
  },
  copyIcon: {
    bottom: '15px',
    right: '7px',
    position: 'absolute',
  },
});

interface IProps {
  externalEquipmentReference: string;
}
const uuidInt32Format = (uuid: string) => {
  const uuidHex = uuid.replaceAll('-', '');
  return {
    id_1: parseInt(uuidHex.substring(0, 8), 16),
    id_2: parseInt(uuidHex.substring(8, 16), 16),
    id_3: parseInt(uuidHex.substring(16, 24), 16),
    id_4: parseInt(uuidHex.substring(24), 16),
  };
};

const TriggerLog = ({ externalEquipmentReference }: IProps) => {
  const {
    card,
    footerControl,
    startEventButton,
    spacedItem,
    cardFooter,
    cardHeader,
    copyIcon,
    divider,
    logId,
    cardDescription,
  } = useStyles();

  const [loading, setLoading] = useState(false);
  const [triggerSuccess, setTriggerSuccess] = useState(false);
  const [logUuid, setLogUuid] = useState('');
  const TriggerLog = () => {
    setLoading(true);
    const guid = uuid();
    const uuidInt32 = uuidInt32Format(guid);
    setLogUuid(guid);
    sendTriggerLog(externalEquipmentReference, uuidInt32)
      .then(() => {
        const event = new CustomEvent(Events.TOAST, {
          detail: {
            title: 'Onboard logs triggered',
            message: 'The command was accepted and a reference UUID has been created',
            type: 'success',
            testId: 'trigger-event-success',
          },
        });
        window.dispatchEvent(event);
        setLoading(false);
        setTriggerSuccess(true);
      })
      .catch(() => {
        const event = new CustomEvent(Events.TOAST, {
          detail: {
            title: 'Failed to trigger logs',
            message: 'Failed to sent command to trigger logs, please try again',
            testId: 'trigger-event-failure',
          },
        });
        window.dispatchEvent(event);
        setLoading(false);
        setTriggerSuccess(false);
      });
  };

  return (
    <div className={card}>
      <div>
        <h6 className={cardHeader}>Trigger onboard logs</h6>
        <Divider className={divider} />
        <h6 className={cardDescription} data-testid="trigger-event-description">
          Trigger onboard logs with a 60 seconds offset before and after. A UUID will be presented for you to copy.
        </h6>
      </div>

      <div className={cardFooter}>
        <Box className={footerControl}>
          <Button
            className={startEventButton}
            disableRipple
            id="triggerOnboardLog"
            onClick={TriggerLog}
            disabled={loading}
            data-testid="start-trigger-event"
          >
            {loading ? (
              <>
                <CircularProgress size={15} />
                <span className={spacedItem}>Starting event</span>
              </>
            ) : (
              <>Start event</>
            )}
          </Button>
          {logUuid && !loading && triggerSuccess && (
            <div className={logId}>
              <span data-testid="trigger-event-uuid">UUID: {logUuid}</span>
            </div>
          )}
        </Box>
      </div>
      {logUuid && !loading && triggerSuccess && (
        <div className={copyIcon}>
          <Copy
            className={copyIcon}
            click={() => {
              navigator.clipboard.writeText(logUuid);
            }}
            data-testid="trigger-copy-icon"
          />
        </div>
      )}
    </div>
  );
};

export default TriggerLog;
