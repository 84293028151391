import clsx from 'clsx';
import { forwardRef, useState, useEffect } from 'react';
import { omit } from 'ramda';
import { t } from 'ttag';
import {
  makeStyles,
  Typography,
  FormControlLabel,
  Menu,
  FormControl,
  RadioGroup,
  Checkbox,
  Divider,
} from '@material-ui/core';
import StatusCircle from '../StatusCircle';
import { grey100, grey300, grey700, grey800, grey900, grey868, grey846 } from '../../../theme/color';
import StyledRadio from '../../icons/StyledRadio';

const useStyles = makeStyles(
  {
    root: {
      backgroundColor: grey900,
      border: 'none',
      color: grey100,
      textCecoration: 'none',
      display: 'flex',
      alignItems: 'center',
      width: '368px',
      height: '68px',
      flexShrink: 0,
      borderTop: `1px solid ${grey800}`,
      padding: '0',
      margin: '0',
      outline: 'none',
      '&:hover, &:active': {
        backgroundColor: grey868,
        cursor: 'pointer',
      },
      '&:focus': {
        backgroundColor: grey846,
      },
      '& > p': {
        marginLeft: '24px',
      },
    },
    wrapStyle: {
      borderBottom: `1px solid ${grey800}`,
    },
    squareStyle: {
      width: '68px',
    },
    areaStyle: {
      border: 0,
      color: grey100,
      height: '48px',
      '&:hover, &:active': {
        backgroundColor: grey868,
      },
      '&:focus': {
        backgroundColor: grey846,
      },
    },
    externalHover: {
      backgroundColor: grey846,
    },
    activeStyle: {
      backgroundColor: grey868,
      borderLeft: `4px solid ${grey100}`,
      '&:hover, &:active': {
        backgroundColor: grey868,
      },
      '& > div:first-child': {
        marginLeft: '-4px',
      },
    },
    divider: {
      '& p': {
        color: grey100,
        textAlign: 'left',
        margin: '0px',
      },
      '& p:first-child': {
        margin: 'auto 0 0 0',
      },
      '& p:last-child': {
        margin: '0 0 auto 0',
        color: grey300,
      },
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'space-around',
    },
    collapseContainer: {
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      '& svg': {
        marginLeft: '24px',
      },
      '& span': {
        marginLeft: '13px',
      },
    },
    expandButton: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    accordionContainer: {
      color: grey100,
      display: 'flex',
      width: '100%',
      height: '100%',
      justifyContent: 'space-between',
      borderBottom: `1px solid ${grey800}`,
      alignItems: 'center',
      '& > p': {
        marginLeft: '24px',
      },
      '& > svg': {
        marginRight: '33px',
      },
    },
    leftPlacement: {
      marginLeft: 'auto',
      paddingRight: '30px',
    },
    vehicleButton: {
      '&:hover': {
        backgroundColor: '#242A33',
      },
      '&:active': {
        backgroundColor: '#2B323D',
      },
    },
    mapControlButton: {
      width: '40px',
      height: '40px',
      borderRadius: '8px',
      borderTop: '0px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:active': {
        backgroundColor: grey100,
      },
    },
    mapControlButtonActive: {
      background: 'grey',
      '&:hover': {
        backgroundColor: grey700,
      },
    },
    layerFormControlLabel: {
      width: '135px',
      height: '47px',
      margin: '0px 0px 0px 7px',
    },
    formControlLabel: {
      width: '135px',
      height: '47px',
      margin: '0px 0px 0px 4px',
    },
    label: {
      color: grey100,
    },
    menuDivider: {
      width: '192px',
      margin: '0px 0px 0px 0px',
    },
    radioGroup: {
      marginTop: '0px',
    },
  },
  { index: 1 },
);

// forwardRef is used for Material UI ToolTip integration
export const StandardButton = forwardRef((props, ref) => {
  const { children, classes, active, hover, area, onMouseEnter, onMouseLeave, square, wrap } = props;

  const { root, externalHover, activeStyle, areaStyle, squareStyle, wrapStyle } = useStyles();

  const hoverClass = hover ? externalHover : '';
  const activeClass = active ? activeStyle : '';
  const areaClass = area ? areaStyle : '';
  const squareClass = square ? squareStyle : '';
  const wrapClass = wrap ? wrapStyle : '';

  // all of this is also used for Material UI Tooltip
  const wrappedProps = omit(['active', 'wrap', 'area', 'hover', 'square'], {
    ...props,
    onMouseEnter: (e) => {
      if (onMouseEnter) onMouseEnter(e);
    },
    onMouseLeave: (e) => {
      if (onMouseLeave) onMouseLeave(e);
    },
  });

  // This is not pure at all
  if (wrap) wrappedProps.wrap = wrap.toString();
  if (area) wrappedProps.area = area.toString();
  if (hover) wrappedProps.hover = hover.toString();
  if (active) wrappedProps.active = active.toString();
  if (square) wrappedProps.square = square.toString();

  return (
    <button
      {...wrappedProps}
      ref={ref}
      type="button"
      className={[root, hoverClass, activeClass, areaClass, squareClass, wrapClass].concat(classes).join(' ')}
    >
      {children}
    </button>
  );
});

// forwardRef is used for Material UI ToolTip integration
export const TinyVehicleButton = forwardRef((props, ref) => {
  const { operationalState, mode, online, ...rest } = props;
  const { vehicleButton } = useStyles();

  return (
    <StandardButton square {...rest} classes={[vehicleButton]} ref={ref}>
      <StatusCircle operationalState={operationalState} online={online} mode={mode} />
    </StandardButton>
  );
});

export const VehicleStatusButton = (props) => {
  const {
    active,
    hover,
    mode,
    onClick,
    onMouseEnter,
    onMouseLeave,
    operationalState,
    online,
    name,
    statusLabel,
    alertPulse,
    icon,
    className,
  } = props;

  const { divider, leftPlacement, vehicleButton } = useStyles();

  return (
    <StandardButton
      active={active}
      hover={hover}
      classes={[vehicleButton, className]}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-testid={`select-vehicle-${name}`}
    >
      <StatusCircle animating={alertPulse} operationalState={operationalState} mode={mode} online={online} />
      <div className={divider}>
        <Typography className="sdds-detail-02">{name}</Typography>
        <Typography className="sdds-detail-02" data-testid={`vehicle-${name}-status-label`}>
          {statusLabel}
        </Typography>
      </div>
      {icon && <span className={leftPlacement}>{icon}</span>}
    </StandardButton>
  );
};

export const AccordionButton = (props) => {
  const { open, onClick, label, style, testid } = props;

  const { accordionContainer } = useStyles();
  const rotation = `rotate(${open ? 180 : 0})`;

  return (
    <StandardButton onClick={onClick} style={style} data-testid={testid}>
      <div className={clsx(accordionContainer, 'sdds-headline-04')}>
        <p data-testid={`${testid}-label`}>{label}</p>
        <svg
          transform={rotation}
          width="14"
          height="8"
          viewBox="0 0 14 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.75 1.49805L1.66055 0.587502L7 5.92695L12.3394 0.587502L13.25 1.49805L7 7.74805L0.75 1.49805Z"
            fill={grey100}
          />
        </svg>
      </div>
    </StandardButton>
  );
};

export const CollapseButton = (props) => {
  const { onClick } = props;
  const { collapseContainer } = useStyles();

  return (
    <StandardButton onClick={onClick} data-testid="collapse-left-panel">
      <div className={collapseContainer}>
        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.5 0.5H0.25V15.5H1.5V0.5Z" fill={grey100} />
          <path
            d="M11.1313 13.3687L10.25 14.25L4 8L10.25 1.75L11.1313 2.63125L6.39375 7.375L17.75 7.375V8.625H6.39375L11.1313 13.3687Z"
            fill={grey100}
          />
        </svg>
        <span className="sdds-headline-07">Collapse</span>
      </div>
    </StandardButton>
  );
};

export const ExpandButton = (props) => {
  const { onClick } = props;
  const { expandButton } = useStyles();

  return (
    <StandardButton square onClick={onClick} data-testid="expand-left-panel">
      <svg
        className={expandButton}
        transform="rotate(180)"
        width="18"
        height="16"
        viewBox="0 0 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1.5 0.5H0.25V15.5H1.5V0.5Z" fill={grey100} />
        <path
          d="M11.1313 13.3687L10.25 14.25L4 8L10.25 1.75L11.1313 2.63125L6.39375 7.375L17.75 7.375V8.625H6.39375L11.1313 13.3687Z"
          fill={grey100}
        />
      </svg>
    </StandardButton>
  );
};

export const MapControlButton = (props) => {
  const { active, onClick, children, testid } = props;

  const { root, mapControlButton, mapControlButtonActive } = useStyles();

  return (
    <button
      onClick={onClick}
      type="button"
      className={[root, mapControlButton].concat(active && mapControlButtonActive).join(' ')}
      data-testid={testid}
    >
      {children}
    </button>
  );
};

export const LayerButton = (props) => {
  const {
    satelliteVisibilityState: [satelliteLayerVisible, setSatelliteLayerVisible],
    showPoiNameState: [showPoiName, setShowPoiName],
    showVehicleNameState: [showVehicleName, setShowVehicleName],
    rightPanelOpen,
    testid,
  } = props;

  const { label, layerFormControlLabel, formControlLabel, menuDivider, radioGroup } = useStyles();
  const [tempSatelliteVisibilityState, setTempSatelliteVisibilityState] = useState(satelliteLayerVisible);
  const [checkPoi, setCheckPoi] = useState(showPoiName);
  const [checkVehicle, setCheckVehicle] = useState(showVehicleName);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (tempSatelliteVisibilityState) {
      setSatelliteLayerVisible(true);
    } else {
      setSatelliteLayerVisible(false);
    }
  }, [tempSatelliteVisibilityState, setSatelliteLayerVisible]);

  useEffect(() => {
    if (checkPoi === 'ON') {
      setShowPoiName('ON');
    } else {
      setShowPoiName('OFF');
    }
  }, [checkPoi, setShowPoiName]);

  useEffect(() => {
    if (checkVehicle === 'ON') {
      setShowVehicleName('ON');
    } else {
      setShowVehicleName('OFF');
    }
  }, [checkVehicle, setShowVehicleName]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <>
      <MapControlButton onClick={handleClick}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          data-testid={testid}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.1093 13L16 4.86426L0.890747 13L6.46218 16L0.890747 19L16 27.1358L31.1093 19L25.5378 16L31.1093 13ZM23.4286 17.1358L16 21.1358L8.57144 17.1358L5.10927 19L16 24.8643L26.8907 19L23.4286 17.1358ZM16 18.8643L5.10927 13L16 7.13577L26.8907 13L16 18.8643Z"
            fill="white"
          />
        </svg>
      </MapControlButton>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={{
          disablePadding: true,
          style: {
            padding: '0px 0px 0px 0px',
          },
        }}
        PaperProps={{
          style: {
            height: 192,
            width: 192,
            paddingTop: '0px',
            borderRadius: '4px',
            transform: rightPanelOpen ? 'translateX(-105%) translateY(15%)' : 'translateX(-30%) translateY(15%)',
          },
        }}
      >
        <FormControl className={radioGroup}>
          <RadioGroup
            aria-labelledby="satellite-label"
            value={tempSatelliteVisibilityState ? 'ON' : 'OFF'}
            onChange={(event) => {
              setTempSatelliteVisibilityState(event.target.value === 'ON');
              setSatelliteLayerVisible(tempSatelliteVisibilityState);
            }}
          >
            <FormControlLabel
              classes={{ label: clsx(label, 'sdds-detail-02') }}
              className={layerFormControlLabel}
              control={<StyledRadio />}
              label={t`Base layer`}
              value="OFF"
            />
            <Divider className={menuDivider} />
            <FormControlLabel
              classes={{ label: clsx(label, 'sdds-detail-02') }}
              className={layerFormControlLabel}
              control={<StyledRadio />}
              label={t`Satellite layer`}
              value="ON"
            />
          </RadioGroup>
        </FormControl>
        <Divider className={menuDivider} />
        <FormControl>
          <FormControlLabel
            checked={showPoiName === 'ON'}
            classes={{ label: clsx(label, 'sdds-detail-02') }}
            className={formControlLabel}
            control={<Checkbox color="primary" />}
            label={t`Poi names`}
            onChange={(event) => {
              setCheckPoi(event.target.checked ? 'ON' : 'OFF');
            }}
          />
        </FormControl>
        <Divider className={menuDivider} />
        <FormControl>
          <FormControlLabel
            checked={showVehicleName === 'ON'}
            classes={{ label: clsx(label, 'sdds-detail-02') }}
            className={formControlLabel}
            control={<Checkbox color="primary" />}
            label={t`Vehicle names`}
            onChange={(event) => {
              setCheckVehicle(event.target.checked ? 'ON' : 'OFF');
            }}
          />
        </FormControl>
      </Menu>
    </>
  );
};
