/**
 * Progress of a request being sent.
 */
enum Progress {
  /**
   * The request is still in progress.
   */
  IN_PROGRESS = 'IN_PROGRESS',

  /**
   * The request is still in progress, but it’s taking longer than 10 seconds.
   */
  VERY_SLOW = 'VERY_SLOW',

  /**
   * The request failed.
   */
  FAILED = 'FAILED',

  /**
   * The request finished successfully.
   */
  DONE = 'DONE',
}

export default Progress;
