import { IconButton, Menu, MenuItem, Tooltip, makeStyles } from '@material-ui/core';

import clsx from 'clsx';
import { useRef } from 'react';
import { t } from 'ttag';
import {
  highEmphasisDarkTheme,
  blue700,
  blue800,
  blue900,
  grey100,
  grey868,
  grey846,
  grey700,
  grey400,
} from '../../theme/color';
import NotificationCard from './NotificationCard';

import getColor from '../../model/notification/getColor';
import { INotification } from '../../model/notification/notificationType';
import NotificationDisplayType from '../../model/notification/NotificationDisplayType';
import getLabel from '../../model/notification/getLabel';
import getDescription from '../../model/notification/getDescription';

interface IProps {
  notificationPanelState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  markAsNotVisible: () => void;
  notifications: INotification[];
  setSelectedEquipmentId: (arg0: string) => void;
  setSnap: (arg0: boolean) => void;
  selectedAreaId: string;
}

const useStyles = makeStyles(
  {
    button: {
      borderLeft: `1px solid ${blue800}`,
      borderRadius: 0,
      height: '64px',
      width: '64px',
      paddingLeft: '16px',
      paddingRight: '16px',
      backgroundColor: blue900,
      '&:hover, &:focus': {
        backgroundColor: blue800,
      },
      '&:active': {
        backgroundColor: blue700,
      },
    },
    list: {
      fontSize: '14px',
      width: '395px',
      padding: 0,
    },
    empty: {
      padding: '50px 92px',
      backgroundColor: grey846,
      font: '14px "Scania Sans Semi Condensed"',
      fontStyle: 'italic',
      letterSpacing: '-0.01em',
      alignItems: 'center',
      borderTop: `1px solid ${grey868}`,
    },
    notificationItem: {
      color: highEmphasisDarkTheme,
      fontSize: '14px',
      height: '96px',
      backgroundColor: grey868,
      '&:hover': {
        backgroundColor: grey100,
      },
      '&:first-child': {
        fontWeight: 'bold',
        backgroundColor: grey846,
        padding: '48px 249px 24px 17px',
      },
    },
    notificationButtonFocus: {
      backgroundColor: blue700,
    },
    paper: {
      borderRadius: '4px',
      width: '395px',
      '&::-webkit-scrollbar': {
        color: grey700,
        width: '4px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: grey400,
      },
      overflow: 'overlay',
      overflowX: 'hidden',
    },
  },
  { index: 1 },
);

const NotificationMenu = (props: IProps) => {
  const {
    notificationPanelState,
    markAsNotVisible,
    notifications,
    setSelectedEquipmentId,
    setSnap,
    selectedAreaId,
  } = props;
  const { button, notificationItem, notificationButtonFocus, empty, paper, list } = useStyles();
  const [notificationOpen, setNotificationOpen] = notificationPanelState;
  const notificationButtonRef = useRef(null);
  const isOnArea = (notification: INotification) => notification?.areaId === selectedAreaId;
  const isUnread =
    notifications &&
    notifications.filter((note) => isOnArea(note)).filter((element) => element.read === false).length > 0;

  const sortedNotifications = [...notifications]?.sort((a, b) => (b.timestamp > a.timestamp ? 1 : -1));

  return (
    <div>
      <Tooltip title={t`Notifications`}>
        <IconButton
          aria-controls="notifications-menu"
          aria-haspopup="true"
          aria-label={t`Notifications`}
          className={clsx(button, notificationOpen ? notificationButtonFocus : null)}
          disableRipple
          onClick={() => {
            const open = !notificationOpen;
            setNotificationOpen(open);
            if (open) {
              markAsNotVisible();
            }
          }}
          ref={notificationButtonRef}
          data-testid="notifications-menu"
        >
          <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.7324 1.22327C10.7324 0.809053 10.3966 0.473267 9.98243 0.473267C9.56822 0.473267 9.23243 0.809053 9.23243 1.22327V2.01737C5.45071 2.40143 2.49962 5.59521 2.49962 9.47826V9.56204C2.48986 9.61031 2.48474 9.66027 2.48474 9.71142V16.9991L2.48478 17.0068H1.73694C1.32272 17.0068 0.986938 17.3426 0.986938 17.7568C0.986938 18.171 1.32272 18.5068 1.73694 18.5068L18.2632 18.5068C18.6774 18.5068 19.0132 18.171 19.0132 17.7568C19.0132 17.3426 18.6774 17.0068 18.2632 17.0068H17.5116V9.37835C17.5116 9.32339 17.5057 9.26982 17.4944 9.21822C17.3658 5.44205 14.4454 2.37429 10.7324 2.01403V1.22327ZM16.0116 17.0068L3.9847 17.0068L3.98474 16.9991V11.2641H3.99962V9.47826C3.99962 6.16477 6.68574 3.47865 9.99924 3.47865C13.3127 3.47865 15.9989 6.16477 15.9989 9.47826V11.2121H16.0116V17.0068ZM8.44361 19.971C8.0294 19.971 7.69361 20.3068 7.69361 20.721C7.69361 21.1352 8.0294 21.471 8.44361 21.471H11.5425C11.9568 21.471 12.2925 21.1352 12.2925 20.721C12.2925 20.3068 11.9568 19.971 11.5425 19.971H8.44361Z"
              fill="#F9FAFB"
            />
          </svg>
          {isUnread && (
            <div
              style={{
                width: '8px',
                height: '8px',
                backgroundColor: 'rgb(250, 110, 35)',
                position: 'absolute',
                borderRadius: '4px',
                top: '19px',
                left: '33px',
              }}
            />
          )}
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={notificationButtonRef.current}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        transformOrigin={{ vertical: 'top', horizontal: 199 }}
        classes={{ list, paper }}
        getContentAnchorEl={null}
        marginThreshold={0}
        id="notification-menu"
        onClose={() => setNotificationOpen(false)}
        open={notificationOpen}
      >
        <MenuItem className={clsx(notificationItem, 'sdds-headline-04')} disableRipple>
          Notifications
        </MenuItem>

        {(!sortedNotifications.filter(isOnArea) || sortedNotifications.filter(isOnArea).length === 0) && (
          <div className={empty}>There are no notifications to show</div>
        )}

        {sortedNotifications?.filter(isOnArea).map((note) => (
          <NotificationCard
            notificationDisplayType={NotificationDisplayType.Panel}
            key={note.id}
            onClick={() => {
              if (note.id) {
                setSelectedEquipmentId(note.vehicleId);
                setSnap(true);
              }
              setNotificationOpen(false);
            }}
            label={getLabel(note.notificationType)}
            description={getDescription(note.notificationType, note.displayName, note.missionName)}
            color={getColor(note)}
            timestamp={note.timestamp}
            read={note.read}
          />
        ))}
      </Menu>
    </div>
  );
};

export default NotificationMenu;
