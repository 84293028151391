import { getMissions, getMissionStates } from '@ats/graphql';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import geoJsonObservable from './geoJson.observable';
import getRoute from '../route/getRoute';
import type RoutedFeature from '../route/RoutedFeature';
import getExecutingMission from '../../mission/getExecutingMission';
import { selectedVehicleId } from '../../observables';

const routeObservable = selectedVehicleId.pipe(
  switchMap((externalEquipmentReference) =>
    externalEquipmentReference
      ? combineLatest([
          getMissions({ externalEquipmentReference }),
          getMissionStates({ externalEquipmentReference }),
          geoJsonObservable,
        ]).pipe(
          map(([missions, states, geoJson]) => {
            const executing = getExecutingMission(states);
            let route: ReadonlyArray<RoutedFeature> = [];

            if (executing) {
              const mission = missions.find(({ id }) => id === executing.missionId);

              if (mission) {
                route = getRoute(mission, executing, geoJson);
              }
            }

            return route;
          }),
        )
      : of([]),
  ),
);

export default routeObservable;
