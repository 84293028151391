import Keycloak from 'keycloak-js';
import environment from './environment';

const keycloakConfig = {
  url: environment.keycloak.url,
  realm: 'fg-ext',
  clientId: environment.keycloak.clientId,
};
const keycloakClient = new Keycloak(keycloakConfig);

export default keycloakClient;
