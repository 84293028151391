import {
  MissionState_cancelled as MissionStateCancelled,
  MissionState_failed as MissionStateFailed,
  MissionState_updateFailed as MissionStateUpdateFailed,
  MissionState_rejected as MissionStateRejected,
} from '@ats/graphql';

import { Button, Card, CardContent, CardHeader, Divider } from '@material-ui/core';
import clsx from 'clsx';
import { useEffect } from 'react';
import { useCardStyles } from './Card.styles';
import { useCommonStyles } from '../../theme/theme';
import MissionStateCard from './MissionStateCard';

interface IProps {
  missionStateOverview: {
    stateDisplayName: string;
    id: string;
    state: MissionStateCancelled | MissionStateFailed | MissionStateUpdateFailed | MissionStateRejected | null;
  } | null;
  dismissCard: () => void;
  title: string;
  setRenderButton: (render: boolean) => void;
}

const MissionStateOverview = (props: IProps) => {
  const { missionStateOverview, dismissCard, title, setRenderButton } = props;
  const { ghostButton } = useCommonStyles();
  const { card, action, cardHeader, cardHeaderTypography, cardContent, noBackground } = useCardStyles();

  useEffect(() => {
    setRenderButton(false);
  }, [setRenderButton]);

  return (
    <Card className={clsx(card, noBackground)}>
      <CardHeader
        action={
          missionStateOverview && (
            <Button disableRipple className={clsx(ghostButton, 'sdds-headline-07')} onClick={dismissCard}>
              Clear
            </Button>
          )
        }
        classes={{ action }}
        className={cardHeader}
        title={title}
        titleTypographyProps={{ className: clsx(cardHeaderTypography, 'sdds-headline-04') }}
      />
      {missionStateOverview ? (
        <>
          <Divider style={{ marginBottom: '24px' }} />
          <CardContent className={cardContent}>
            <MissionStateCard missionStateOverview={missionStateOverview} />
          </CardContent>
        </>
      ) : null}
    </Card>
  );
};
export default MissionStateOverview;
