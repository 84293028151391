import { SvgIcon } from '@material-ui/core';
import { ICommonIconProps } from './CommonIconProps';

export const Octagon = ({ className, testid }: ICommonIconProps) => {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" className={className} data-testid={testid}>
      <polygon points="0 4.6863, 0 11.3137, 4.6863 16, 11.3137 16, 16 11.3137, 16 4.6863, 11.3137 0, 4.6863 0" />
    </SvgIcon>
  );
};
export default Octagon;
