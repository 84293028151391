import type { Mission } from '@ats/graphql';
import type { FeatureCollection, Geometry, Position } from 'geojson';
import type { DeepReadonly } from 'ts-essentials';
import getEndCoordinates from './getEndCoordinates';
import getLanes from './getLanes';
import getOpenArea from './getOpenArea';
import type IExecuting from './IExecuting';
import type RoutedFeature from './RoutedFeature';
import { trimEnd, trimStart } from './trim';
import type IFeatureProperties from '../IFeatureProperties';

const getRoute = (
  mission: Mission,
  { executing: { actionIndex, commandIndex }, position }: IExecuting,
  geoJson: DeepReadonly<FeatureCollection<Geometry, IFeatureProperties>> | null,
): ReadonlyArray<RoutedFeature> => {
  const points = mission.actions[actionIndex].commands[commandIndex]?.drive?.points;
  const route: RoutedFeature[] = [];

  if (points) {
    points.forEach((point, pointIndex) => {
      const from = getEndCoordinates(route, position);
      const id = `mission/${mission.id}/action/${actionIndex}/command/${commandIndex}/point/${pointIndex}`;
      const lanes = geoJson && getLanes(geoJson, point.wayId);

      const addRouteUnknown = (to: DeepReadonly<Position>) => {
        if (from && (from.longitude !== to[0] || from.latitude !== to[1])) {
          route.push({
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: [[from.longitude, from.latitude], to],
            },
            id,
            properties: {
              routeKnown: false,
            },
          });
        }
      };

      if (lanes?.length) {
        const insideOpenArea = from && geoJson && getOpenArea(from, geoJson);

        if (insideOpenArea) {
          const to = lanes[0].geometry.coordinates[0];

          if (to) {
            addRouteUnknown(to);
          }
        }

        route.push(
          ...lanes.map((lane, laneIndex) => {
            const laneId = `${id}/lane/${laneIndex}`;
            const { geometry, properties } = lane;
            let { coordinates } = geometry;

            if (!pointIndex && !insideOpenArea && from) {
              coordinates = trimStart(coordinates, from);
            }
            if (pointIndex === points.length - 1) {
              const { latitude, longitude } = point.position;

              if (latitude && longitude) {
                coordinates = trimEnd(coordinates, { latitude, longitude });
              }
            }

            return {
              ...lane,
              geometry: {
                ...geometry,
                coordinates,
              },
              id: laneId,
              properties: {
                ...properties,
                id: laneId,
                routeKnown: true,
              },
            };
          }),
        );
      } else {
        const to = point.position;

        if (to.longitude && to.latitude) {
          addRouteUnknown([to.longitude, to.latitude]);
        }
      }
    });
  }

  return route;
};

export default getRoute;
