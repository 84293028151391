import { SvgIcon } from '@material-ui/core';

interface IProps {
  className?: string;
}

export const PoiMarkerIcon = ({ className }: IProps) => (
  <SvgIcon
    width="46"
    height="78"
    viewBox="0 0 46 78"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle cx="23" cy="55" r="22.5" fill="#0D0F13" />
    <path
      d="M23 67C29.6274 67 35 61.6274 35 55C35 48.3726 29.6274 43 23 43C16.3726 43 11 48.3726 11 55C11 61.6274 16.3726 67 23 67Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44 55C44 66.598 34.598 76 23 76C11.402 76 2 66.598 2 55C2 43.402 11.402 34 23 34C34.598 34 44 43.402 44 55ZM41 55C41 64.9411 32.9411 73 23 73C13.0589 73 5 64.9411 5 55C5 45.0589 13.0589 37 23 37C32.9411 37 41 45.0589 41 55Z"
      fill="white"
    />
    <path
      d="M42 19.6001C42 23.1653 40.8127 27.1172 38.9988 31.0644C37.1876 35.0059 34.768 38.9079 32.3408 42.3638C29.9147 45.8183 27.4874 48.818 25.6664 50.9543C24.7561 52.0221 23.9978 52.8736 23.4677 53.4575C23.283 53.6609 23.1261 53.8318 23 53.9681C22.8739 53.8318 22.717 53.6609 22.5323 53.4575C22.0022 52.8736 21.2439 52.0221 20.3336 50.9543C18.5126 48.818 16.0853 45.8183 13.6592 42.3638C11.232 38.9079 8.81242 35.0059 7.0012 31.0644C5.18733 27.1172 4 23.1653 4 19.6001C4 9.10669 12.5066 0.600098 23 0.600098C33.4934 0.600098 42 9.10669 42 19.6001Z"
      fill="#1D2229"
      stroke="white"
    />
    <path
      d="M32.25 19.6001C32.25 24.7087 28.1086 28.8501 23 28.8501C17.8914 28.8501 13.75 24.7087 13.75 19.6001C13.75 14.4915 17.8914 10.3501 23 10.3501C28.1086 10.3501 32.25 14.4915 32.25 19.6001Z"
      fill="#1D2229"
      stroke="white"
    />
  </SvgIcon>
);

export default PoiMarkerIcon;
