import type { Feature, FeatureCollection, Geometry, LineString } from 'geojson';
import type { DeepReadonly } from 'ts-essentials';
import type IFeatureProperties from '../IFeatureProperties';

const getLanes = (
  geoJson: DeepReadonly<FeatureCollection<Geometry, IFeatureProperties>>,
  wayId: number,
): DeepReadonly<Feature<LineString>[]> => {
  const osmFeatureId = `way/${wayId}`;

  return geoJson.features.filter(
    ({ id, geometry, properties }) =>
      (id === osmFeatureId || Number(properties?.laneGroupRef) === wayId) &&
      properties?.type !== 'open_area_boundary' &&
      geometry.type === 'LineString',
  ) as Feature<LineString>[];
};

export default getLanes;
