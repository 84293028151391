import { makeStyles, Snackbar, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import clsx from 'clsx';

import Events from '../model/events';
import { white } from '../theme/color';

const useStyles = makeStyles(
  {
    root: {
      zIndex: 1400, // Places the toaster above the map, controls and camera view, aswell as above the right panel
      position: 'absolute',
      bottom: 24,
      right: 24,
    },
    toastContainer: {
      width: '352px',
      background: white,
    },
    toastContent: {
      color: 'rgba(13, 15, 19, 0.87)',
    },
    toastClose: {
      position: 'absolute',
      right: '2px',
      top: '2px',
    },
  },
  { index: 1 },
);

const ToastContainer = () => {
  const { root, toastContent, toastContainer, toastClose } = useStyles();

  const [content, setContent] = useState(null);

  const open = !!content;
  const { message, title, type, testId } = content || {};

  const reset = () => setContent(null);

  const callback = ({ detail }) => {
    setContent({ title: detail.title, message: detail.message, type: detail.type, testId: detail.testId });
  };

  useEffect(() => {
    window.addEventListener(Events.TOAST, callback);
    return () => window.removeEventListener(Events.TOAST, callback);
  }, []);

  return (
    <Snackbar
      className={root}
      open={open}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      id="cnd-toast"
    >
      <div
        className={
          type === 'success'
            ? clsx(toastContainer, 'sdds-toast', 'sdds-toast-success')
            : clsx(toastContainer, 'sdds-toast', 'sdds-toast-error')
        }
        data-testid={testId}
      >
        <div className="sdds-toast-icon">
          <svg width="16" height="16" viewBox="0 0 16 16" fill={white} xmlns="http://www.w3.org/2000/svg" />
        </div>

        <div className="sdds-toast-content">
          <div className="sdds-toast-header">
            <span className={clsx(toastContent, 'sdds-toast-headline')}>{title}</span>

            <IconButton onClick={reset} className={toastClose}>
              <Close style={{ fill: 'rgba(13, 15, 19, 0.87)' }} />
            </IconButton>
          </div>

          <div className="sdds-toast-body">
            <span className={clsx(toastContent, 'sdds-toast-subheadline')}>{message}</span>
          </div>
        </div>
      </div>
    </Snackbar>
  );
};

export default ToastContainer;
