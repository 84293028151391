import type { Position } from 'geojson';
import { getDistance } from 'ol/sphere';
import type { DeepReadonly } from 'ts-essentials';
import type ICoordinates from './ICoordinates';

const getDistances = (
  coordinates: DeepReadonly<Position[]>,
  { latitude, longitude }: ICoordinates,
): ReadonlyArray<number> => {
  const fromCoordinate = [longitude, latitude];

  return coordinates.map((coordinate) => getDistance(coordinate as number[], fromCoordinate));
};

export default getDistances;
