import { getEquipmentStatuses } from '@ats/graphql';
import { of } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import isOnline from '../../vehicle/isOnline';
import getVehicleStateColor from '../../vehicle/state/getVehicleStateColor';
import { selectedVehicleId } from '../../observables';

const selectedVehicleColorObservable = selectedVehicleId.pipe(
  switchMap((externalEquipmentReference) =>
    externalEquipmentReference
      ? getEquipmentStatuses({ externalEquipmentReference }).pipe(
          map(([status]) =>
            status ? getVehicleStateColor(status.operationalState, status.mode, isOnline(status.timestamp)) : null,
          ),
        )
      : of(null),
  ),
  distinctUntilChanged(),
);

export default selectedVehicleColorObservable;
