import { useEffect, useMemo } from 'react';
import { memoizeWith } from 'ramda';
import { usePoi } from '@ats/graphql';

import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Cluster from 'ol/source/Cluster';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { fromLonLat } from 'ol/proj';
import { Style, Text, Fill, Circle } from 'ol/style';
import OlIcon from 'ol/style/Icon';

import Events from '../../model/events';
import {
  selectedPoiId as selectedPoiIdObservable,
  mapInteractionModule as mapInteractionModuleObservable,
  displayPoiLabelSetting as displayPoiLabelSettingObservable,
} from '../../model/observables';
import { createTrackingFeature } from './useTrackerLabelsLayer';
import scaleModifier from './scaleModifier';
import { degreesToRadians } from './Map.helpers';
import { poiIcon, markerIcon, poiHeadingIcon, poiDisabledIcon } from './Icons/PoiIcons';

export function createOLPoiFeature({ id, position, displayName }) {
  let selected = false;
  let disabled = false;
  let poiIsInsideCluster = false;
  let hover = false;

  const feature = new Feature({
    id,
    name: 'poi',
  });

  const poiIconStyle = new Style({
    image: new OlIcon({
      src: `data:image/svg+xml;utf8,${encodeURIComponent(poiIcon)}`,
      opacity: 1,
      scale: 1,
    }),
  });

  const poiDisabledIconStyle = new Style({
    image: new OlIcon({
      src: `data:image/svg+xml;utf8,${encodeURIComponent(poiDisabledIcon)}`,
      opacity: 0,
    }),
  });

  const poiHeadingIconStyle = new Style({
    image: new OlIcon({
      rotateWithView: true,
      anchor: [0.5, 2.5],
      rotation: 0,
      opacity: 1,
      scale: 1,
      src: `data:image/svg+xml;utf8,${encodeURIComponent(poiHeadingIcon)}`,
    }),
  });

  const markerIconStyle = new Style({
    image: new OlIcon({
      anchor: [0.5, 1],
      opacity: 0,
      src: `data:image/svg+xml;utf8,${encodeURIComponent(markerIcon)}`,
    }),
  });

  feature.setStyle((_feature, resolution) => {
    const hoverModifier = hover ? 1.1 : 1;
    const scale = scaleModifier(resolution) * hoverModifier;
    poiIconStyle.getImage().setScale(scale);
    poiHeadingIconStyle.getImage().setScale(scale);
    poiDisabledIconStyle.getImage().setScale(scale);
    markerIconStyle.getImage().setScale(scale);
    return [poiIconStyle, poiDisabledIconStyle, poiHeadingIconStyle, markerIconStyle];
  });

  const setPosition = (_position) => {
    const { latitude, longitude, heading } = _position;
    const point = new Point(fromLonLat([longitude, latitude]));
    feature.setGeometry(point);
    poiHeadingIconStyle.getImage().setRotation(degreesToRadians(heading));
    feature.changed();
  };
  setPosition(position); // Set position on init

  const setSelected = (_selected) => {
    selected = _selected;
    markerIconStyle.getImage().setOpacity(selected ? 1 : 0);
    poiHeadingIconStyle.getImage().setOpacity(selected ? 0 : 1);
    feature.changed();
  };

  const setDisabled = (_disabled) => {
    disabled = _disabled;
    poiDisabledIconStyle.getImage().setOpacity(disabled ? 1 : 0);
    feature.changed();
  };

  const subscription1 = selectedPoiIdObservable.subscribe((value) => setSelected(id === value));

  const subscription2 = mapInteractionModuleObservable.subscribe((value) => {
    const isDisabled = ['POIPOSITION', 'POIROTATION'].includes(value) && selected;
    setDisabled(isDisabled);
  });

  feature.setProperties({
    id,
    unsubscribe: () => {
      subscription1.unsubscribe();
      subscription2.unsubscribe();
    },
    getDisplayName: () => displayName,
    setPosition,
    setSelected,
    setDisabled,
    setHover: (_hover) => {
      hover = _hover;
      feature.changed();
    },
    setPoiIsInsideCluster: (value) => {
      poiIsInsideCluster = value;
    },
    getPoiIsInsideCluster: () => {
      return poiIsInsideCluster;
    },
  });

  return feature;
}

const getClusterStyle = memoizeWith(Number, function getClusterStyle(size, feature) {
  const clusterIcon = new Style({
    image: new Circle({
      fill: new Fill({
        color: 'rgba(255,255,255,1)',
      }),
      radius: 28,
    }),
    text: new Text({
      text: size.toString(),
      font: 'bold 16px "Scania Sans Semi Condensed"',
      textBaseline: 'middle',
      textAlign: 'center',
      fill: new Fill({
        color: '#000',
      }),
    }),
  });
  feature.setStyle((_feature, resolution) => {
    const hover = feature.getProperties().getHover();
    const hoverModifier = hover ? 1.1 : 1;
    const scale = scaleModifier(resolution) * hoverModifier;
    clusterIcon.getImage().setScale(scale);
    return clusterIcon;
  });
  return feature.getStyle();
});

function customClusterStyle(clusterFeature) {
  const features = clusterFeature.get('features');
  const size = features.length;
  if (size === 1) return features[0].getStyle();
  return getClusterStyle(size, clusterFeature);
}

const createCluster = memoizeWith(
  (point, features) =>
    features
      .map((feature) => {
        const [lat, lon] = features[0].getGeometry().getCoordinates();
        return `${feature.get('id')}@${lat},${lon}`;
      })
      .join('_'),
  (point, features) => {
    const feature = new Feature({
      geometry: point,
      features,
    });
    feature.setStyle(customClusterStyle(feature));

    let hover = false;

    feature.setProperties({
      setHover: (_hover) => {
        hover = _hover;
        feature.changed();
      },
      getHover: () => {
        return hover;
      },
    });

    return feature;
  },
);

export function poiCRUD(source, map, trackerLayer, pois) {
  if (!pois) {
    source.getFeatures().forEach((feature) => {
      source.removeFeature(feature);

      const detail = { id: feature.getProperties().id };
      const event = new CustomEvent(Events.POI_REMOVED, { detail });
      window.dispatchEvent(event);
    });
    return;
  }

  // CRUD for POIs
  pois.forEach((poi) => {
    const target = source.getFeatures().find((feature) => feature.getProperties().id === poi.id);

    if (!target) {
      // Create
      const poiFeature = createOLPoiFeature(poi);
      source.addFeature(poiFeature);

      const tracker = createTrackingFeature(map, 1, trackerLayer, poiFeature, displayPoiLabelSettingObservable);
      trackerLayer.getSource().addFeature(tracker);
    } else {
      // Update
      target.getProperties().setPosition(poi.position);
    }
  });

  // Remove
  source.getFeatures().forEach((feature) => {
    const target = pois.find((poi) => poi.id === feature.getProperties().id);
    if (target) return;

    source.removeFeature(feature);

    const detail = { id: feature.getProperties().id };
    const event = new CustomEvent(Events.POI_REMOVED, { detail });
    window.dispatchEvent(event);
  });
}

export default function usePoiLayer(areaId, map, trackerLayer) {
  const source = useMemo(() => new VectorSource(), []);
  const cluster = useMemo(() => new Cluster({ distance: 35, source, createCluster }), [source]);
  const layer = useMemo(
    () =>
      new VectorLayer({
        source: cluster,
        updateWhileAnimating: true,
        updateWhileInteracting: true,
      }),
    [cluster],
  );

  const [pois] = usePoi({ areaId });

  useEffect(() => poiCRUD(source, map, trackerLayer, pois), [source, pois, map, trackerLayer]);

  useEffect(() => {
    const callback = () => {
      layer
        .getSource()
        .getFeatures()
        .forEach((_clusterFeature) => {
          const features = _clusterFeature.get('features');
          if (features.length === 1) {
            features[0].getProperties().setPoiIsInsideCluster(false);
          } else {
            features.forEach((feature) => feature.getProperties().setPoiIsInsideCluster(true));
          }
        });
    };
    layer.on('prerender', callback);
    return () => layer.un('prerender', callback);
  }, [layer]);

  return layer;
}
