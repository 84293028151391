import environment from './environment';

export const api = {
  /* eslint-disable camelcase */
  aws_appsync_authenticationType: 'AWS_LAMBDA',
  aws_appsync_graphqlEndpoint: environment.appSync.endpoint,
  aws_appsync_region: environment.appSync.region,
  /* eslint-enable */
};

export const auth = {
  type: 'AWS_LAMBDA',
};
