const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;

const formatHumanTimestamp = (dateObj: Date | null) => {
  if (!dateObj) return '-';
  const now = new Date();

  if (now.getTime() - dateObj.getTime() >= HOUR * 4) return 'many hours ago';

  if (now.getTime() - dateObj.getTime() >= HOUR * 2) return 'a few hours ago';

  if (now.getTime() - dateObj.getTime() >= HOUR) return 'one hour ago';

  if (now.getTime() - dateObj.getTime() >= MINUTE * 30) return 'half an hour ago';

  if (now.getTime() - dateObj.getTime() >= MINUTE * 2) return 'a few minutes ago';

  if (now.getTime() - dateObj.getTime() >= MINUTE) return 'one minute ago';

  if (now.getTime() - dateObj.getTime() >= SECOND * 30) return 'half a minute ago';

  if (now.getTime() - dateObj.getTime() >= SECOND * 3) return 'a few seconds ago';

  return 'just now';
};

export default formatHumanTimestamp;
