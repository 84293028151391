import { SystemName } from '@ats/graphql';

export const hasRightToDispatch = (systemName: SystemName | undefined) =>
  systemName === SystemName.ICE_UI || systemName === SystemName.NONE || !systemName;

export const getDispatcher = (systemName: SystemName | undefined): string => {
  switch (systemName) {
    case SystemName.ICE_UI:
      return 'ICE UI';
    case SystemName.DICE_UI:
      return 'DICE';
    case SystemName.WENCO:
      return 'Wenco';
    default:
      return 'None';
  }
};
