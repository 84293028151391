/**
 * This function handles in which cases the pop up notification should be visible
 */
const getVisibility = (
  externalEquipmentReference: string,
  selectedEquipmentId: string | null,
  notificationOpen: boolean,
): boolean => {
  if (externalEquipmentReference !== selectedEquipmentId && !notificationOpen) return true;
  if (externalEquipmentReference !== selectedEquipmentId && notificationOpen) return false;
  if (externalEquipmentReference === selectedEquipmentId && !notificationOpen) return false;
  if (externalEquipmentReference === selectedEquipmentId && notificationOpen) return false;

  return true;
};
export default getVisibility;
