import type { EquipmentStatus, Mission } from '@ats/graphql';
import type { FeatureCollection, Geometry } from 'geojson';
import type { DeepReadonly } from 'ts-essentials';
import getRoute from '../getRoute';
import type RoutedFeature from '../RoutedFeature';
import type IFeatureProperties from '../../IFeatureProperties';

const getRoutePreview = (
  mission: Mission,
  status: EquipmentStatus | null,
  geoJson: DeepReadonly<FeatureCollection<Geometry, IFeatureProperties>> | null,
): ReadonlyArray<RoutedFeature> => {
  const route: RoutedFeature[] = [];
  let position = status && status.position;

  mission.actions.forEach((action, actionIndex) => {
    action.commands.forEach(({ drive }, commandIndex) => {
      if (drive && drive.points.length) {
        route.push(...getRoute(mission, { executing: { actionIndex, commandIndex }, position }, geoJson));
        position = drive.points[drive.points.length - 1].position;
      }
    });
  });

  return route;
};

export default getRoutePreview;
