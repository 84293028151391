/*  
    Used to represent the big Views, either Map or Vehicle views
*/
export enum View {
  MapView,
  VehicleView,
}

/*
    Only applicable in Vehicle View, SubView represents opening any preview card to fullscreen 
*/
export enum SubView {
  Dashboard,
  RiskMap,
  // RemoteAssessment - To be implemented
  // Map - Unclear what this means
}

/*
    Used to represent the different Views in Mission Widget in Equipment Panel
*/
export enum MissionView {
  DefaultView,
  SelectMission,
  PreviewMission,
  ActiveMission,
  Loading,
  ClickAndDriveView,
  MissionState,
}
