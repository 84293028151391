import type { FeatureCollection, Geometry } from 'geojson';
import type { DeepReadonly } from 'ts-essentials';
import bisectInOpenArea from './bisectInOpenArea';
import bisectOnRoad from './bisectOnRoad';
import type IBisectedRoute from './IBisectedRoute';
import getOpenArea from '../getOpenArea';
import type RoutedFeature from '../RoutedFeature';
import type IFeatureProperties from '../../IFeatureProperties';
import type ICoordinates from '../ICoordinates';

const useRoadIfOpenAreaEntranceWithinMeters = 2.5;

interface IHeading {
  heading: number;
}

const bisectRoute = (
  route: ReadonlyArray<RoutedFeature>,
  position: ICoordinates,
  { heading }: IHeading,
  geoJson: DeepReadonly<FeatureCollection<Geometry, IFeatureProperties>> | null,
): IBisectedRoute => {
  let bisected;

  if (position) {
    const openArea = geoJson && getOpenArea(position, geoJson);

    if (openArea) {
      bisected = bisectOnRoad(route, position, heading, useRoadIfOpenAreaEntranceWithinMeters);

      if (!bisected) {
        bisected = bisectInOpenArea(route, position, openArea);
      }
    } else {
      bisected = bisectOnRoad(route, position, heading);
    }
  }

  return bisected || { future: route, past: [] };
};

export default bisectRoute;
