import { doLog, LogEntryType, LogLevel, ActionList as ActionListType, Mission, MissionInput } from '@ats/graphql';
import { Button, Card, CardContent, CardHeader, CardActions, CircularProgress, Divider } from '@material-ui/core';
import clsx from 'clsx';
import { t } from 'ttag';
import { memo, useEffect } from 'react';
import PreviewActionListContainer from './PreviewActionList.container';
import Progress from '../../send/Progress';
import { getButtonTextDispatch } from '../../send/getButtonText';
import { useCardStyles } from './Card.styles';
import { useCommonStyles } from '../../theme/theme';
import Events from '../../model/events';
import { extractRootLevelTags } from '../../model/mission/extractTagsData';
import { selectedVehicleId } from '../../model/observables';

interface IProps {
  setActions: (actions: MissionInput['actions'] | null) => void;
  setTags: (tags: MissionInput['tags'] | null) => void;
  setActionList: (actionList: ActionListType | null) => void;
  actionLists: ReadonlyArray<ActionListType> | null;
  previewMissionId: string | null;
  dispatchingProgress: Progress | null;
  dispatchMission: (
    actions: ActionListType['actions'],
    actionListId: ActionListType['id'],
    tags: ActionListType['tags'],
  ) => Promise<Mission>;
  clear: () => void;
  areaId: string;
  canDispatchtMission: boolean;
  renderReleaseComponent: () => JSX.Element | null;
  vehicleMode: string | null;
  setRenderButton: (render: boolean) => void;
  vehicleDisplayName: string;
  dispatcherRight: boolean;
}

const PreviewMission = (props: IProps) => {
  const {
    canDispatchtMission,
    renderReleaseComponent,
    areaId,
    actionLists,
    clear,
    dispatchingProgress,
    dispatchMission,
    previewMissionId,
    setActions,
    setTags,
    setActionList,
    vehicleMode,
    setRenderButton,
    vehicleDisplayName,
    dispatcherRight,
  } = props;

  const { dispatchButton, ghostButton } = useCommonStyles();
  const {
    button,
    card,
    cardHeader,
    cardHeaderTypography,
    cardContent,
    cardActions,
    action,
    circularProgress,
    noBackground,
  } = useCardStyles();

  const dispatching = dispatchingProgress === Progress.IN_PROGRESS || dispatchingProgress === Progress.VERY_SLOW;
  const actionList: ActionListType | undefined = actionLists?.find(({ id }) => id === previewMissionId);
  const isDispatchable = vehicleMode === 'Autonomous' || vehicleMode === 'Shadow';

  if (!previewMissionId || !actionList) {
    throw new Error(`Missing preview mission ID`);
  }

  const { displayName, actions, id, tags } = actionList;
  const actionListTags = extractRootLevelTags(tags);

  useEffect(() => {
    setActionList(actionList);
    setActions(actions);
    setTags(tags);
    setRenderButton(true);
  }, [setRenderButton, setActionList, actionList, setActions, actions, setTags, tags]);

  const dispatchMissionOnclick = () => {
    dispatchMission(actions, id, tags)
      .then((response) => {
        doLog(LogLevel.Debug, {
          type: LogEntryType.UI,
          externalEquipmentReference: selectedVehicleId.getValue(),
          message: `Preview mission for ${vehicleDisplayName}`,
        });
        return response;
      })
      .catch(() => {
        doLog(LogLevel.Warning, {
          type: LogEntryType.UI,
          externalEquipmentReference: selectedVehicleId.getValue(),
          message: `Preview mission failed for ${vehicleDisplayName}`,
        });

        const detail = {
          title: t`Dispatch failed`,
          message: t`Failed to dispatch ${vehicleDisplayName}. Please try again`,
        };
        const event = new CustomEvent(Events.TOAST, { detail });
        window.dispatchEvent(event);
      });
  };
  return (
    <Card className={clsx(card, noBackground)}>
      <CardHeader
        action={
          <Button
            disableRipple
            className={clsx(ghostButton, 'sdds-headline-07')}
            disabled={dispatching}
            onClick={clear}
            data-testid="clear-mission-button"
          >
            Clear
          </Button>
        }
        classes={{ action }}
        className={cardHeader}
        title={actionListTags && actionListTags.displayText ? actionListTags.displayText : displayName}
        titleTypographyProps={{ className: clsx(cardHeaderTypography, 'sdds-headline-04') }}
      />
      <Divider />
      <CardContent className={cardContent}>
        <PreviewActionListContainer areaId={areaId} actionList={actionList} />
      </CardContent>
      <CardActions className={cardActions}>
        {canDispatchtMission ? (
          <Button
            className={clsx(button, dispatchButton, 'sdds-detail-02')}
            disabled={dispatching || !isDispatchable || !dispatcherRight}
            fullWidth
            disableRipple
            onClick={() => dispatchMissionOnclick()}
            data-testid="dispatch-mission-button"
          >
            {dispatching && <CircularProgress className={circularProgress} size="3.5rem" />}
            {getButtonTextDispatch(dispatchingProgress)}
          </Button>
        ) : (
          renderReleaseComponent()
        )}
      </CardActions>
    </Card>
  );
};

export default memo(PreviewMission);
