import {
  MissionState,
  MissionState_cancelled as MissionStateCancelled,
  MissionState_failed as MissionStateFailed,
  MissionState_updateFailed as MissionStateUpdateFailed,
  MissionState_rejected as MissionStateRejected,
} from '@ats/graphql';
import { t } from 'ttag';

interface IStates {
  (states: ReadonlyArray<MissionState> | null): {
    stateDisplayName: string;
    id: string;
    state: MissionStateCancelled | MissionStateFailed | MissionStateUpdateFailed | MissionStateRejected;
  } | null;
}

const getUnfinishedMissionState: IStates = (states) => {
  if (states === null || states[0] === undefined) return null;
  const { missionId, cancelled, failed, updateFailed, rejected } = states[0];

  // filter out REASON_REQUESTED_FROM_OFFBOARD because the user initiated the cancelling of the mission herself
  if (cancelled && cancelled.reason !== 'REASON_REQUESTED_FROM_OFFBOARD')
    return { stateDisplayName: t`Mission cancelled`, id: missionId, state: cancelled };

  if (failed) return { stateDisplayName: t`Mission failed`, id: missionId, state: failed };

  if (updateFailed) return { stateDisplayName: t`Mission update failed`, id: missionId, state: updateFailed };

  if (rejected) return { stateDisplayName: t`Mission rejected`, id: missionId, state: rejected };

  return null;
};

export default getUnfinishedMissionState;
