import { dispatchEquipment, Equipment, EquipmentStatus, SystemName } from '@ats/graphql';
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  makeStyles,
  Menu,
  RadioGroup,
  SvgIcon,
  Tooltip,
} from '@material-ui/core';
import clsx from 'clsx';
import { memo, useEffect, useState } from 'react';
import { blue400, grey100, grey500, grey700, grey800 } from '../../theme/color';
import { ICommonIconProps, useIconStyles } from './CommonIconProps';
import StyledRadio from './StyledRadio';
import Events from '../../model/events';
import useControlIconStyles from './useControlIconStyles';
import { getDispatcher } from '../../model/vehicle/dispatcherRight';

interface IProps extends ICommonIconProps {
  online: boolean;
  vehicle: Equipment;
  system: SystemName | undefined;
}

const useStyles = makeStyles(
  {
    root: {
      position: 'absolute',
      left: '22px',
      top: '310px',
      borderRadius: '4px',
    },
    label: {
      color: grey100,
    },
    formControlLabel: {
      width: '192px',
      height: '47px',
      margin: '0px',
      paddingLeft: '4px',
      '&:hover': {
        backgroundColor: grey700,
      },
    },
    menuDivider: {
      width: '192px',
      margin: '0px 0px 0px 0px',
    },
    radioGroup: {
      marginTop: '0px',
    },
  },
  { index: 1 },
);

export const DispatcherButton = ({ className, testid, online, system, vehicle }: IProps) => {
  const iconStyles = useIconStyles();
  const { button, activeStyle } = useControlIconStyles();
  const { root, label, formControlLabel, menuDivider, radioGroup } = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [activeButton, setActiveButton] = useState<boolean>(false);
  const [value, setValue] = useState<SystemName>(SystemName.NONE);
  const activeClass = activeButton ? activeStyle : '';
  const open = Boolean(anchorEl);

  const systemNameOrder = {
    [SystemName.WENCO]: 1,
    [SystemName.ICE_UI]: 2,
    [SystemName.DICE_UI]: 3,
    [SystemName.NONE]: 4,
  };

  const systemNameToBeSorted = Object.values(SystemName);
  const sortedSystemName = systemNameToBeSorted.sort((a: SystemName, b: SystemName) => {
    return systemNameOrder[a] - systemNameOrder[b];
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setActiveButton(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setActiveButton(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAnchorEl(null);
    setActiveButton(false);

    dispatchEquipment(vehicle.externalEquipmentReference, {
      dispatcher: { systemName: event.target.value as SystemName },
    })
      .then((data: EquipmentStatus) => {
        setValue(data && data.dispatcher ? data.dispatcher.systemName : SystemName.NONE);
      })
      .catch(() => {
        const detail = { title: `Failed to set the dispatcher right for ${event.target.value}` };
        const toasEvent = new CustomEvent(Events.TOAST, { detail });
        window.dispatchEvent(toasEvent);
      });
  };

  useEffect(() => {
    if (system !== SystemName.NONE) {
      setValue(system ?? SystemName.NONE);
    } else {
      setValue(SystemName.NONE);
    }
  }, [system]);

  return online ? (
    <span className={root}>
      <Tooltip
        disableFocusListener
        disableTouchListener
        title={
          <span className={iconStyles.tooltipTypography}>
            {system !== SystemName.NONE
              ? `Dispatcher:
            ${getDispatcher(system)}`
              : 'Dispatcher rights'}
          </span>
        }
      >
        <span>
          <Button
            data-testid="dispatcher-rights-button"
            className={[button, activeClass].join(' ')}
            onClick={handleClick}
          >
            <SvgIcon
              className={className}
              width="16px"
              height="16px"
              viewBox="0 0 16 16"
              transform="scale(0.8)"
              data-testid={testid}
            >
              <path
                d="M6 6.5C6 5.96875 5.53125 5.5 5 5.5H4.5C3.375 5.5 2.5 6.40625 2.5 7.5V9C2.5 10.125 3.375 11 4.5 11H5C5.53125 11 6 10.5625 6 10V6.5ZM11.5 11C12.5938 11 13.5 10.125 13.5 9V7.5C13.5 6.40625 12.5938 5.5 11.5 5.5H11C10.4375 5.5 10 5.96875 10 6.5V10C10 10.5625 10.4375 11 11 11H11.5ZM8 0C3.53125 0 0.125 3.71875 0 8V8.5C0 8.78125 0.21875 9 0.5 9H1C1.25 9 1.5 8.78125 1.5 8.5V8C1.5 4.4375 4.40625 1.5 8 1.5C11.5625 1.5 14.5 4.4375 14.5 8H14.4688C14.4688 8.09375 14.5 13.1875 14.5 13.1875C14.5 13.9375 13.9062 14.5 13.1562 14.5H10C10 13.6875 9.3125 13 8.5 13H7.5C6.65625 13 6 13.6875 6 14.5C6 15.3438 6.65625 16 7.5 16H13.1562C14.7188 16 16 14.75 16 13.1875V8C15.8438 3.71875 12.4375 0 8 0Z"
                fill={system === SystemName.NONE ? grey500 : blue400}
              />
            </SvgIcon>
          </Button>
        </span>
      </Tooltip>
      <Menu
        data-testid="dispatcher-rights-menu"
        open={open}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={handleClose}
        MenuListProps={{
          disablePadding: true,
          style: {
            padding: '0px 0px 0px 0px',
          },
        }}
        PaperProps={{
          style: {
            height: 192,
            width: 192,
            backgroundColor: grey800,
            paddingTop: '0px',
            borderRadius: '4px',
            marginTop: '15px',
          },
        }}
      >
        <FormControl className={radioGroup}>
          <RadioGroup aria-labelledby="dispatcher-label" value={value} onChange={handleChange}>
            {sortedSystemName.map((val) => (
              <div key={val}>
                <FormControlLabel
                  className={formControlLabel}
                  classes={{ label: clsx(label, 'sdds-detail-02') }}
                  label={getDispatcher(val)}
                  control={<StyledRadio />}
                  value={val}
                />
                <Divider className={menuDivider} />
              </div>
            ))}
          </RadioGroup>
        </FormControl>
      </Menu>
    </span>
  ) : (
    <span className={root}>
      <span>
        <Button data-testid="dispatcher-rights-button" className={[button, activeClass].join(' ')} disabled={!online}>
          <SvgIcon
            className={className}
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            transform="scale(0.8)"
            data-testid={testid}
          >
            <path
              d="M6 6.5C6 5.96875 5.53125 5.5 5 5.5H4.5C3.375 5.5 2.5 6.40625 2.5 7.5V9C2.5 10.125 3.375 11 4.5 11H5C5.53125 11 6 10.5625 6 10V6.5ZM11.5 11C12.5938 11 13.5 10.125 13.5 9V7.5C13.5 6.40625 12.5938 5.5 11.5 5.5H11C10.4375 5.5 10 5.96875 10 6.5V10C10 10.5625 10.4375 11 11 11H11.5ZM8 0C3.53125 0 0.125 3.71875 0 8V8.5C0 8.78125 0.21875 9 0.5 9H1C1.25 9 1.5 8.78125 1.5 8.5V8C1.5 4.4375 4.40625 1.5 8 1.5C11.5625 1.5 14.5 4.4375 14.5 8H14.4688C14.4688 8.09375 14.5 13.1875 14.5 13.1875C14.5 13.9375 13.9062 14.5 13.1562 14.5H10C10 13.6875 9.3125 13 8.5 13H7.5C6.65625 13 6 13.6875 6 14.5C6 15.3438 6.65625 16 7.5 16H13.1562C14.7188 16 16 14.75 16 13.1875V8C15.8438 3.71875 12.4375 0 8 0Z"
              fill={grey500}
            />
          </SvgIcon>
        </Button>
      </span>
    </span>
  );
};

export default memo(DispatcherButton);
