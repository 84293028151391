const MapViewIcon = () => (
  <svg
    data-testid="equipment-panel-map-view-button"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 4.66668L7.83333 2.48001L13.22 4.66668L18.5 2.48001V15.28L13.22 17.52L7.83333 15.28L2.5 17.52V4.66668Z"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M13.2137 5.00934V17.1185M7.88672 3.11243V14.9877" stroke="white" />
  </svg>
);

export default MapViewIcon;
