import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

interface IProps {
  (): [string[], (id: string) => void];
}

const useMissionStateCardOverview: IProps = () => {
  const [dismissedIds, setDismissedIds] = useState<string[]>([]);

  useEffect(() => {
    const dismissedCookie = Cookies.get('dismissed-ids');
    if (dismissedCookie) {
      setDismissedIds(JSON.parse(dismissedCookie));
    }
  }, []);

  useEffect(() => {
    Cookies.set('dismissed-ids', JSON.stringify(dismissedIds));
  }, [dismissedIds]);

  const setDismissedId = (id: string) => {
    setDismissedIds((ids) => [...ids, id]);
  };

  return [dismissedIds, setDismissedId];
};

export default useMissionStateCardOverview;
