import { render } from 'react-dom';

import { defineCustomElements, addTheme } from '@scania/components';
import { theme as scania } from '@scania/theme-light';

import App from './app/App';
import { unregister } from './serviceWorkerRegistration';

render(<App />, document.getElementById('root'));

unregister();

defineCustomElements();
addTheme(scania);
