import CSS from 'csstype';

interface IProps {
  opacity: number;
  click: React.MouseEventHandler<SVGSVGElement> | undefined;
  style?: Pick<CSS.Properties, 'cursor'>;
}
export const UpArrow = ({ opacity, click, style }: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31"
    height="12"
    viewBox="0 0 31 12"
    fill="none"
    onClick={click}
    style={style}
  >
    <path d="M15.5 0L30.6554 11.0742L0.344556 11.0742L15.5 0Z" fill="white" fillOpacity={opacity} />
  </svg>
);
export const DownArrow = ({ opacity, click, style }: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31"
    height="12"
    viewBox="0 0 31 12"
    fill="none"
    onClick={click}
    style={style}
  >
    <path d="M15.5 12L0.344555 0.75L30.6554 0.75L15.5 12Z" fill="white" fillOpacity={opacity} />
  </svg>
);
export const LeftArrow = ({ opacity, click, style }: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="32"
    viewBox="0 0 12 32"
    fill="none"
    onClick={click}
    style={style}
  >
    <path d="M0 15.8667L11.725 0.393745L11.725 31.3397L0 15.8667Z" fill="white" fillOpacity={opacity} />
  </svg>
);
export const RightArrow = ({ opacity, click, style }: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="32"
    viewBox="0 0 13 32"
    fill="none"
    onClick={click}
    style={style}
  >
    <path d="M12.6333 15.8667L0.908301 31.3397L0.908301 0.39368L12.6333 15.8667Z" fill="white" fillOpacity={opacity} />
  </svg>
);
