export enum LastCommunicationState {
  LAST_COMMUNICATION_ACTIVE = 'ACTIVE',
  LAST_COMMUNICATION_DEVIATION = 'DEVIATION',
  LAST_COMMUNICATION_IDLE = 'IDLE',
  LAST_COMMUNICATION_MANUAL = 'MANUAL',
  LAST_COMMUNICATION_OFFLINE = 'OFFLINE',
  LAST_COMMUNICATION_STAND_DOWN = 'STAND_DOWN',
}

export default interface IVehicleStateColor {
  readonly foreground: string;
  readonly shadow: string;
  readonly lastCommunicationStateColor: LastCommunicationState;
}
