import { Card, CardContent, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { memo } from 'react';
import { grey868, white } from '../../theme/color';

interface IProps {
  status: string;
  className?: string;
}

export const useStyles = makeStyles(
  {
    card: {
      boxShadow: 'none',
      width: '320px',
      height: '66px',
      backgroundColor: grey868,
    },

    cardContent: {
      display: 'flex',
      justifyContent: 'center',
      padding: '0px',
      '&:last-child': {
        paddingBottom: 0,
      },
    },
    typographyTitle: {
      color: white,
      padding: '24px 0px 24px',
      width: '250px',
      lineHeight: '18px',
    },
    typographyStatus: {
      color: white,
      padding: '24px 0px 24px',
      lineHeight: '18px',
    },
  },
  { index: 1 },
);

const PaddockStatus = (props: IProps) => {
  const { status, className } = props;
  const { card, cardContent, typographyTitle, typographyStatus } = useStyles();
  return (
    <>
      <Card className={clsx(card, className)}>
        <CardContent className={cardContent}>
          <Typography className={clsx(typographyTitle, 'sdds-headline-07')}>Paddock Status</Typography>
          <Typography className={clsx(typographyStatus, 'sdds-headline-07')}>{`${status}`}</Typography>
        </CardContent>
      </Card>
    </>
  );
};

export default memo(PaddockStatus);
