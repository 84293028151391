import { Card, CardActions, CardContent, CardHeader, Divider, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { t } from 'ttag';

import ClickAndDriveButton from './ClickAndDriveButton';
import { mapInteractionModule as mapInteractionModuleObservable } from '../../model/observables';
import { dimWhite, ultraDimWhite } from '../../theme/color';

const useStyles = makeStyles(
  {
    card: {
      boxShadow: 'none',
      padding: '24px',
      width: '368px',
      paddingTop: '0',
    },
    cardHeader: {
      padding: '0 0 0 0',
      width: '144px',
      height: '18px',
    },
    cardHeaderTypography: {
      color: dimWhite,
      opacity: 1,
    },
    cardContent: { padding: '12px 0 0 10px' },

    cardContentTypography: {
      color: ultraDimWhite,
      opacity: 1,
      fontStyle: 'italic',
      fontSize: '14px',
      width: '308px',
      height: '32px',
    },
    childOfContent: {
      margin: '24px 0px 17px 0px',
    },
  },
  { index: 1 },
);
interface IProps {
  title: string;
  darkMode: boolean;
}

const NoPredefinedMissionView = ({ title, darkMode }: IProps) => {
  const { cardHeader, childOfContent, cardHeaderTypography, cardContentTypography, cardContent, card } = useStyles();

  return (
    <Card className={clsx(card)}>
      <CardHeader
        className={cardHeader}
        title={title}
        titleTypographyProps={{ className: clsx(cardHeaderTypography, 'sdds-body-02') }}
      />
      <CardContent className={cardContent}>
        <Typography className={clsx(cardContentTypography, 'Scania Sans Semi Condensed')}>
          {t`Site has not been configured with any pre-defined missions`}
        </Typography>
      </CardContent>
      <Divider className={childOfContent} />
      <CardActions>
        <ClickAndDriveButton
          testId="click-and-drive-button"
          disabled={darkMode}
          onClick={() => mapInteractionModuleObservable.next('POSITION')}
        />
      </CardActions>
    </Card>
  );
};

export default NoPredefinedMissionView;
