import { clone } from 'ramda';
import { useEffect, useState } from 'react';
import { INotification } from '../model/notification/notificationType';
import useMissionStateNotifications from '../model/notification/useMissionStateNotifications';

const useNotifications = (selectedEquipmentId: string | null, selectedAreaId: string | null) => {
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [notificationOpen, setNotificationOpen] = useState(false);

  const [missionStateNotifications] = useMissionStateNotifications(
    selectedAreaId,
    selectedEquipmentId,
    notificationOpen,
  );

  const markAsNotVisible = (id: string) => {
    setNotifications((freshNotifications) => {
      let newNotifications = clone(freshNotifications);
      if (id) {
        const idx = notifications.findIndex((x) => x.id === id);
        newNotifications[idx].visible = false;
      } else {
        newNotifications = newNotifications.map((x) => ({ ...x, visible: false }));
      }
      return newNotifications;
    });
  };

  const markAsRead = (id: string) => {
    setNotifications((freshNotifications) => {
      let newNotifications = clone(freshNotifications);
      newNotifications = newNotifications.map((notification) => {
        if (notification.id === id) notification.read = true;
        return notification;
      });
      return newNotifications;
    });
  };

  useEffect(() => {
    if (!notificationOpen) {
      setNotifications((freshNotifications) => {
        let newNotifications = clone(freshNotifications);
        newNotifications = newNotifications.map((notification) => {
          if (notification.areaId === selectedAreaId) notification.read = true;
          return notification;
        });
        return newNotifications;
      });
    }
    // Only re-render when the notification menu is getting closed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationOpen]);

  const derivedMissionStateNotifications = missionStateNotifications ? missionStateNotifications.join('-') : '';

  useEffect(() => {
    if (!missionStateNotifications) return;

    const newMissionStateNotifications = missionStateNotifications.filter((note) => {
      const { id } = note;
      const isNewNote = notifications?.find(({ id: prevId }) => prevId === id) === undefined;
      return isNewNote;
    });
    if (newMissionStateNotifications.length > 0) {
      setNotifications([...newMissionStateNotifications, ...notifications]);
    }
    // Only re-render if the missionStateNotifications array change, but use derivedMissionStateNotifications instead
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [derivedMissionStateNotifications]);

  return [notifications, markAsNotVisible, markAsRead, notificationOpen, setNotificationOpen];
};

export default useNotifications;
