import IVehicle from '../IVehicle';

const getSortedVehicles = (equipmentStatuses: IVehicle[] | null) => {
  const equipments = equipmentStatuses
    ? equipmentStatuses.slice(0).sort((a, b) => (b.displayName.toUpperCase() < a.displayName.toUpperCase() ? 1 : -1))
    : null;

  return equipments;
};

export default getSortedVehicles;
