import { OperationalState, EquipmentMode } from '@ats/graphql';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import getVehicleStateColor from '../../model/vehicle/state/getVehicleStateColor';
import getVehicleStateLabel from '../../model/vehicle/state/getVehicleStateLabel';
import { white } from '../../theme/color';

interface IProps {
  operationalState: OperationalState | null;
  mode: EquipmentMode | null;
  online: boolean;
  animating?: boolean;
  onlyBasicInfo: boolean;
}

const useStyles = makeStyles(
  {
    container: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    halo: {
      position: 'absolute',
      width: '100px',
      height: '100px',
      borderRadius: '50px',
      zIndex: 8,
      animation: '2.5s ease-in infinite pulsating-halo',
    },
    circle: {
      zIndex: 10,
      width: '32px',
      height: '32px',
      margin: '17px',
      borderRadius: '25px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: white,
    },
  },
  { index: 1 },
);

const StatusCircle = ({ operationalState, mode, online, animating, onlyBasicInfo }: IProps) => {
  const { container, halo, circle } = useStyles();

  const color = getVehicleStateColor(operationalState, mode, online);
  const labelFull = getVehicleStateLabel(operationalState, mode, online, onlyBasicInfo);
  const label = labelFull ? labelFull.substr(0, 1).toUpperCase() : '-';

  return (
    <div className={container}>
      {animating && <div className={halo} style={{ backgroundColor: color.foreground }} />}
      <div className={clsx(circle, 'sdds-detail-04')} style={{ backgroundColor: color.foreground }}>
        {label}
      </div>
    </div>
  );
};

export default StatusCircle;
