import { useEffect, useState } from 'react';

interface IProps {
  children: JSX.Element;
  hoverState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  pausedState: [Date | null, React.Dispatch<React.SetStateAction<Date | null>>];
  markAsNotVisible: (id?: string | undefined) => void;
  timestamp: Date;
}

const SECONDS_SINCE_CREATION_TO_CLOSE = 7;
const SECONDS_SINCE_PAUSE_TO_CLOSE = 2;
const SECOND = 1000;

const NotificationCardContainer = (props: IProps) => {
  const { children, timestamp, markAsNotVisible, hoverState, pausedState } = props;
  const [hovering, setHovering] = hoverState;
  const [pausedTime, setPausedTime] = pausedState;
  const [compareTime, setCompareTime] = useState(new Date());

  const onFocus = () => {
    setHovering(true);
    setPausedTime(new Date());
  };

  const onBlur = () => {
    setHovering(false);
    setPausedTime(new Date());
  };

  useEffect(() => {
    const diff = compareTime.getTime() - timestamp.getTime();
    if (hovering) return;
    if (pausedTime) {
      const pauseDiff = compareTime.getTime() - pausedTime.getTime();
      if (Math.round(pauseDiff / SECOND) > SECONDS_SINCE_PAUSE_TO_CLOSE) {
        markAsNotVisible();
        setPausedTime(null);
      }
      return;
    }

    if (Math.round(diff / SECOND) > SECONDS_SINCE_CREATION_TO_CLOSE) {
      markAsNotVisible();
    }
  }, [compareTime, markAsNotVisible, timestamp, hovering, pausedTime, setPausedTime]);

  useEffect(() => {
    const timerRef = setInterval(() => setCompareTime(new Date()), 500);
    return () => clearInterval(timerRef);
  }, [setCompareTime]);

  return (
    <div onMouseOver={onFocus} onFocus={onFocus} onMouseOut={onBlur} onBlur={onBlur}>
      {children}
    </div>
  );
};

export default NotificationCardContainer;
