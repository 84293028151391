import { transform, fromLonLat, toLonLat } from 'ol/proj';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { makeStyles } from '@material-ui/core';
import GeoJSON from 'ol/format/GeoJSON';
import LineString from 'ol/geom/LineString';

import { useObservable } from '@libreact/use-observable';
import { useEffect, useState } from 'react';

import { positionIcon, getRadiansBetweenPoints, createLineStringStyle, createMarkerStyle } from '../Map.helpers';
import closestPoint from '../closestPoint';

import {
  mapInteractionModule as mapInteractionModuleObservable,
  clickAndDrivePosition,
  selectedVehiclePosition as selectedVehiclePositionObservable,
} from '../../../model/observables';

import geoJsonObservable from '../../../model/map/observable/geoJson.observable';

const moduleName = 'POSITION';
const line = new Feature({ name: 'cndSelectionLine', id: 2 });
const target = new Feature({ name: 'cndDestination', id: 3 });

let source = null;
let state = null;
let selectedVehiclePosition = null;
let mouseCoordinate = null;
let features = null;

export function pointerMove(event) {
  const { coordinate } = event || {};
  if (state !== moduleName) return true; // Keep the geometry - some other module might still want to see the line
  if (!selectedVehiclePosition) {
    // Remove the line, not ready to be drawn yet
    line.setGeometry(null);
    target.setGeometry(null);
    return true;
  }
  if (coordinate) {
    mouseCoordinate = transform(coordinate, 'EPSG:3857', 'EPSG:4326');
  } else {
    // A nice "default" line, a bit to the right of the vehicle
    mouseCoordinate = [selectedVehiclePosition.longitude + 0.001, selectedVehiclePosition.latitude];
  }

  const { longitude, latitude } = selectedVehiclePosition;
  const headingInRadians = getRadiansBetweenPoints(fromLonLat([longitude, latitude]), fromLonLat(mouseCoordinate));

  line.setGeometry(
    new LineString(
      [[longitude, latitude], mouseCoordinate].map((coord) => {
        return fromLonLat([coord[0], coord[1]]);
      }),
    ),
  );
  line.setStyle(createLineStringStyle());

  target.setGeometry(new Point(fromLonLat(mouseCoordinate)));
  target.setStyle(createMarkerStyle([0.5, 0.5], positionIcon, 1.2, headingInRadians));
}

function init() {
  if (!source) return;
  source.addFeature(line);
  source.addFeature(target);

  pointerMove(null); // Kickstart rendering without the user actually moving the mouse over the map

  geoJsonObservable.subscribe((geoJson) => {
    if (!geoJson) return;

    features = new GeoJSON({
      featureProjection: 'EPSG:3857',
    }).readFeatures(geoJson);
  });
}

function cleanup() {
  if (!source || !line || !target) return;
  source.removeFeature(line);
  source.removeFeature(target);
}

mapInteractionModuleObservable.subscribe((value) => {
  if (state !== value && state === moduleName) {
    cleanup();
  }
  state = value;
  if (state === moduleName) {
    init();
  }
});

selectedVehiclePositionObservable.subscribe((value) => {
  selectedVehiclePosition = value;
});

export function addSource(_source) {
  source = _source;
}

export function click() {
  if (state !== moduleName) return true;
  const cP = closestPoint(features, mouseCoordinate);
  clickAndDrivePosition.next(toLonLat(cP));
  mapInteractionModuleObservable.next('ROTATION');

  return false;
}

const useStyles = makeStyles(
  {
    clickAwayRoot: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
    },
  },
  { index: 1 },
);

export const ClickAndDrivePositionClickAwayContainer = () => {
  const [open, setOpen] = useState(false);
  const [mapInteractionModule] = useObservable(mapInteractionModuleObservable);
  const { clickAwayRoot } = useStyles();

  useEffect(() => {
    if (mapInteractionModule === moduleName) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [mapInteractionModule]);

  return open ? <div className={clickAwayRoot} onClick={() => mapInteractionModuleObservable.next('DEFAULT')} /> : null;
};
