import { memo } from 'react';
import { CardHeader, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { DegradationPlanAction } from '@ats/graphql';
import { Circle, Octagon, Triangle } from '../icons';
import getDegradationActionLabel from '../../model/degradation/getDegradationActionLabel';
import { grey400, red500, yellow } from '../../theme/color';

interface IProps {
  action: DegradationPlanAction;
}

const useStyles = makeStyles(
  {
    root: {
      paddingBottom: '8px',
    },
    avatar: {
      display: 'flex',
      marginRight: '2rem',
    },
    icon: {
      width: '4rem',
    },
    red: {
      fill: red500,
    },
    yellow: {
      fill: yellow,
    },
    grey: {
      fill: grey400,
    },
    typography: {
      color: 'rgba(255, 255, 255, 0.87)',
    },
  },
  { index: 1 },
);

const DegradationHeader = (props: IProps) => {
  const { action } = props;
  const { avatar, icon, red, yellow, grey, root, typography } = useStyles();
  const label = getDegradationActionLabel(action);

  return (
    <CardHeader
      avatar={
        <>
          {(action === DegradationPlanAction.PanicStop ||
            action === DegradationPlanAction.SoftBrakeAlongPath ||
            action === DegradationPlanAction.HardBrakeAlongPath ||
            action === DegradationPlanAction.ExtremeBrakeAlongPath) && <Octagon className={clsx(icon, red)} />}
          {action === DegradationPlanAction.ReducedSpeed && <Triangle className={clsx(icon, yellow)} />}
          {action === DegradationPlanAction.None && <Circle className={clsx(icon, yellow)} />}
          {action === DegradationPlanAction.Unknown && <Circle className={clsx(icon, grey)} />}
        </>
      }
      classes={{ avatar, root }}
      title={label}
      titleTypographyProps={{ className: clsx(typography, 'sdds-headline-06') }}
    />
  );
};

export default memo(DegradationHeader);
