// Whole file directly copied from @ats/storybook

import { gettext } from 'ttag';

/**
 * These words should be lowercase,
 * unless they are the first or last words in a title.
 */
const wordsToLowerCase: { [word: string]: true } = {};

[
  /**
   * Articles in English
   */
  'A',
  'An',
  'The',

  /**
   * Prepositions in English, excluding:
   * * "Down", since "Stand Down" should be consistent.
   * * Archaic, dialectal, formal, obsolete, poetic, and rare words.
   * * Abbreviations.
   *
   * @see https://en.wikipedia.org/wiki/List_of_English_prepositions
   */
  'Aboard',
  'About',
  'Above',
  'Absent',
  'Across',
  'After',
  'Against',
  'Along',
  'Alongside',
  'Amid',
  'Among',
  'Around',
  'As',
  'Astride',
  'At',
  'Atop',
  'Bar',
  'Before',
  'Behind',
  'Below',
  'Beneath',
  'Beside',
  'Besides',
  'Between',
  'Beyond',
  'But',
  'By',
  'Circa',
  'Come',
  'Dehors',
  'Despite',
  'During',
  'Except',
  'For',
  'From',
  'In',
  'Inside',
  'Into',
  'Less',
  'Like',
  'Minus',
  'Near',
  'Nearer',
  'Nearest',
  'Notwithstanding',
  'Of',
  'Off',
  'On',
  'Onto',
  'Opposite',
  'Out',
  'Outside',
  'Over',
  'Past',
  'Per',
  'Plus',
  'Post',
  'Pre',
  'Pro',
  'Save',
  'Short',
  'Since',
  'Than',
  'Through',
  'Throughout',
  'Till',
  'To',
  'Toward',
  'Towards',
  'Under',
  'Underneath',
  'Unlike',
  'Until',
  'Up',
  'Upon',
  'Upside',
  'Versus',
  'Via',
  'With',
  'Within',
  'Without',

  /**
   * Coordinating Conjunctions in English
   *
   * @see https://en.wikipedia.org/wiki/Conjunction_(grammar)#Coordinating_conjunctions
   */
  'For',
  'And',
  'Nor',
  'But',
  'Or',
  'Yet',
  'So',
].forEach((word) => {
  wordsToLowerCase[word] = true;
});

/**
 * Convert text into Title Text,
 * and then translate it into the user's language.
 *
 * Also, use the word "Vehicle" instead of "Equipment".
 *
 * @param text Text to change into Title Text.
 */
const prettyText = (text: string | undefined | null) => {
  if (text) {
    text = text.replace(/([a-z])([A-Z])/g, '$1 $2');
    text = text.replace(/([a-zA-Z])(\d)/g, '$1 $2');
    text = text.replace(/(\d)([a-zA-Z])/g, '$1 $2');

    const hasLowerCase = /.*[a-z].*/.test(text);
    const words = text.split(/[ _-]+/).filter((word) => !!word);

    text = words
      .map((word, index) => {
        const wordUpperCase = word.toUpperCase();

        if (word !== wordUpperCase || !hasLowerCase) {
          word = wordUpperCase[0] + word.substring(1).toLowerCase();

          if (word === 'Equipment') {
            word = 'Vehicle';
          } else if (word === 'Id') {
            word = 'ID';
          }

          if (wordsToLowerCase[word] && index && index < words.length - 1) {
            word = word.toLowerCase();
          }
        }

        return word;
      })
      .join(' ');

    text = gettext(text);
  }

  return text;
};

export default prettyText;
