import { green, red500 } from '../../theme/color';
import NotificationType, { INotification } from './notificationType';
/**
 * This function returns for every notification type the corresponding notification color
 */
const getColor = (notification: INotification) => {
  switch (notification.notificationType as NotificationType) {
    case NotificationType.MissionStateCompleted:
      return green;
    case NotificationType.MissionStateFailed:
      return red500;
    default:
      return `null`;
  }
};
export default getColor;
