import { useState, useEffect, useRef } from 'react';
import { useObservable } from '@libreact/use-observable';
import { getAssetsPromise } from '@ats/graphql';
import { CircularProgress, Button, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import useRenderAfterTime from '../model/vehicle/useRenderAfterTime';
import formatHumanTimestamp from '../model/format/formatHumanTimestamp';
import RefreshIcon from './icons/RefreshIcon';
import { UpArrow, DownArrow, LeftArrow, RightArrow } from './icons/Arrows';
import { scaleImage } from './scaleImage';
import Events from '../model/events';
import { useCommonStyles } from '../theme/theme';
import { highEmphasisLightTheme, grey846, grey900 } from '../theme/color';
import { selectedVehicleId as selectedVehicleIdObservable } from '../model/observables';
import { getTimestamp } from '../model/vehicle/useVehicles';
import isOnline from '../model/vehicle/isOnline';

const useStyles = makeStyles(
  {
    root: {
      position: 'absolute',
      width: '100vw',
      height: 'calc(100vh - 64px)',
      zIndex: 1200,
    },
    floatingSelectionLabel: {
      backgroundColor: grey900,
      color: '#fff',
      position: 'absolute',
      padding: '8px',
      borderRadius: '4px',
    },
    floatingTimestampLabel: {
      position: 'absolute',
      backgroundColor: grey900,
      color: '#fff',
      borderRadius: '4px',
      padding: '12px',
    },
    reloadLabel: {
      position: 'absolute',
      width: '40px',
      height: '40px',
      backgroundColor: grey900,
      borderRadius: '4px',
      padding: 0,
    },
    canvas: {
      width: '100%',
      height: '100%',
    },
    canvasContainer: {
      position: 'relative',
      flexBasis: 'auto',
      flexGrow: 1,
      flexShrink: 1,
      overflow: 'hidden',
      backgroundColor: highEmphasisLightTheme,
      border: `1px solid ${grey846}`,
    },
    buttonContainer: {
      flexBasis: '132px',
      flexGrow: 0,
      flexShrink: 0,
      backgroundColor: highEmphasisLightTheme,
      display: 'flex',
      '& button': {
        padding: '12px',
        margin: '6px',
      },
    },
    buttonContainerPreviewCard: {
      position: 'absolute',
      display: 'flex',
    },
    visibleArea: {
      marginRight: '368px',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100vh - 64px)',
      overflow: 'hidden',
    },
    leftButtons: {
      flexBasis: 'auto',
      flexGrow: 1,
      flexShrink: 0,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'right',
      alignItems: 'center',
    },
    middleButtons: {
      flexBasis: '132px',
      flexGrow: 0,
      flexShrink: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    middleButtonsPreviewCard: {
      flexBasis: '35px',
      flexGrow: 0,
      flexShrink: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    rightButtons: {
      flexBasis: 'auto',
      flexGrow: 1,
      flexShrink: 0,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'left',
      alignItems: 'center',
    },
    bold: {
      fontWeight: 'bold',
    },
    loader: {
      position: 'absolute',
      left: '50%',
      top: '50%',
    },
    vehicleViewloader: {
      position: 'relative',
      left: '50%',
      top: '50%',
    },
  },
  { index: 1 },
);

enum CameraAngle {
  front = 'Front',
  rear = 'Rear',
  left = 'Left',
  right = 'Right',
}

interface IProps {
  reduced?: boolean;
  offset: number;
  externalEquipmentReference?: string;
}

const CameraView = (props: IProps) => {
  const { offset, reduced, externalEquipmentReference } = props;
  const mounted = useRef(false);
  const [cameraAngle, setCameraAngle] = useState<CameraAngle>(CameraAngle.front);
  const [timestampDate, setTimestampDate] = useState<Date | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [edgeCoordinates, setEdgeCoordinates] = useState<{ left: number; top: number }>({ left: 0, top: 0 });
  const [selectedEquipmentId] = useObservable(selectedVehicleIdObservable);
  const canvasRef = useRef() as React.MutableRefObject<HTMLCanvasElement>;
  const base64ref = useRef() as React.MutableRefObject<string | null>;
  const nextRender = new Date();
  const prevExternalEquipmentReference = useRef<string | null>();
  const online = selectedEquipmentId && isOnline(getTimestamp(selectedEquipmentId));
  nextRender.setSeconds(nextRender.getSeconds() - 5);
  useRenderAfterTime(nextRender.getTime());

  const {
    root,
    floatingSelectionLabel,
    floatingTimestampLabel,
    bold,
    buttonContainer,
    buttonContainerPreviewCard,
    canvasContainer,
    canvas,
    visibleArea,
    leftButtons,
    middleButtons,
    rightButtons,
    reloadLabel,
    loader,
  } = useStyles();
  const { secondaryButton, secondaryButtonSelected } = useCommonStyles();

  const cancelIfUnmounted = (base64string: string): string => {
    if (mounted.current === false) {
      throw new Error('Cancelled');
    }
    return base64string;
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const load = (cameraAngle: CameraAngle) => {
    setLoading(true);
    if (!canvasRef || !base64ref?.current) {
      renderImage(canvasRef.current, null);
    } else {
      renderPlaceholder();
    }
    setTimestampDate(null);
    loadImage(cameraAngle);
  };

  const loadImage = (cameraAngle: CameraAngle) => {
    const vehicleId = externalEquipmentReference || selectedEquipmentId;
    if (!vehicleId) return;
    if (online) {
      const resource = cameraAngle.toLowerCase();
      const assetName = `camera%2F${resource}`;

      getAssetsPromise({ externalEquipmentReference: vehicleId, assetName })
        .then(cancelIfUnmounted)
        .then(storeData)
        .then((base64img: string) => renderImage(canvasRef.current, base64img))
        .then(() => setLoading(false))
        .catch((e: Error) => {
          if (e.message === 'Cancelled') return;
          const event = new CustomEvent(Events.TOAST, {
            detail: { title: 'Download failed', message: 'Camera image failed to load' },
          });
          window.dispatchEvent(event);
          setLoading(false);
          setTimestampDate(null);
          base64ref.current = null;
        });
    } else {
      setLoading(false);
      setTimestampDate(null);
      base64ref.current = null;
    }
  };

  const renderPlaceholder = () => {
    const ref = canvasRef.current;
    const ctx = ref.getContext('2d');
    if (!ref || !base64ref.current || !ctx) return;
    const image = new Image();
    image.src = base64ref.current;
    image.onload = () => {
      const result = scaleImage(image.width, image.height, canvasRef.current.width, canvasRef.current.height);
      setEdgeCoordinates({ left: result.left, top: result.top });
      ctx.drawImage(image, result.left, result.top, result.width, result.height);
      ctx.fillStyle = 'rgba(43, 50, 61, 0.6)';
      ctx.fillRect(result.left, result.top, result.width, result.height);
    };
  };

  const storeData = (base64data: string): string => {
    const base64withHeaders = `data:image/jpeg;base64,${base64data}`; // prepend same headers as blobify example
    base64ref.current = base64withHeaders;
    setTimestampDate(new Date());
    return base64withHeaders;
  };

  function renderImage(canvas: HTMLCanvasElement | null, base64img: string | null) {
    if (canvas === null) return;

    const ref = canvas;

    const ctx = ref.getContext('2d');

    if (!ctx) return;

    if (base64img === null) {
      ctx.clearRect(0, 0, ref.width, ref.height);
      return;
    }

    const image = new Image();
    image.src = base64img;
    image.onload = () => {
      const result = scaleImage(image.width, image.height, canvas.width, canvas.height);
      setEdgeCoordinates({ left: result.left, top: result.top });
      ctx.drawImage(image, result.left, result.top, result.width, result.height);
    };
  }

  const setSize = () => {
    canvasRef.current.width = canvasRef.current.clientWidth;
    canvasRef.current.height = canvasRef.current.clientHeight;
  };

  useEffect(() => {
    const listener = () => {
      setSize();
      if (!base64ref.current) return;
      renderImage(canvasRef.current, base64ref.current);
    };
    window.addEventListener(Events.MAP_AREA_CHANGED, listener);
    return () => window.removeEventListener(Events.MAP_AREA_CHANGED, listener);
  }, []);

  useEffect(() => {
    const listener = () => {
      setSize();
      if (!base64ref.current) return;
      renderImage(canvasRef.current, base64ref.current);
    };
    window.addEventListener('resize', listener);

    return () => window.removeEventListener('resize', listener);
  }, []);

  useEffect(() => {
    if (prevExternalEquipmentReference.current !== externalEquipmentReference) {
      base64ref.current = null;
      setCameraAngle(CameraAngle.front);
      return;
    }
    load(cameraAngle);
    // We know load is always updated since implicit rerender from camera angle
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [online, cameraAngle, externalEquipmentReference, prevExternalEquipmentReference.current]);

  useEffect(() => {
    prevExternalEquipmentReference.current = externalEquipmentReference;
  }, [externalEquipmentReference]);

  useEffect(() => {
    setSize();
  }, []);
  return (
    <>
      {!reduced ? (
        <span className={root} data-testid="#camera-view">
          <div className={visibleArea} style={{ marginLeft: `${offset}px` }}>
            <div className={canvasContainer}>
              <canvas ref={canvasRef} className={canvas} />
              {loading && <CircularProgress className={loader} size={25} />}
            </div>
            <div className={buttonContainer}>
              <div className={leftButtons}>
                <Button
                  className={clsx(
                    secondaryButton,
                    cameraAngle === CameraAngle.left && secondaryButtonSelected,
                    'sdds-detail-02',
                  )}
                  disableRipple
                  onClick={() => setCameraAngle(CameraAngle.left)}
                >
                  Left camera
                </Button>
              </div>
              <div className={middleButtons}>
                <Button
                  className={clsx(
                    secondaryButton,
                    cameraAngle === CameraAngle.front && secondaryButtonSelected,
                    'sdds-detail-02',
                  )}
                  disableRipple
                  onClick={() => setCameraAngle(CameraAngle.front)}
                >
                  Front camera
                </Button>
                <Button
                  className={clsx(
                    secondaryButton,
                    cameraAngle === CameraAngle.rear && secondaryButtonSelected,
                    'sdds-detail-02',
                  )}
                  disableRipple
                  onClick={() => setCameraAngle(CameraAngle.rear)}
                >
                  Rear camera
                </Button>
              </div>
              <div className={rightButtons}>
                <Button
                  className={clsx(
                    secondaryButton,
                    cameraAngle === CameraAngle.right && secondaryButtonSelected,
                    'sdds-detail-02',
                  )}
                  disableRipple
                  onClick={() => setCameraAngle(CameraAngle.right)}
                >
                  Right camera
                </Button>
              </div>
            </div>
            <div
              className={clsx(floatingSelectionLabel, 'sdds-detail-02')}
              style={{ top: edgeCoordinates.top + 30, left: edgeCoordinates.left + 32 }}
            >
              {cameraAngle} camera
            </div>
            <div
              className={clsx(floatingTimestampLabel, 'sdds-detail-02')}
              style={{ right: edgeCoordinates.left, bottom: edgeCoordinates.top + 132 }} // show button on the bottom right side of the image
            >
              <span className={bold}>Last Updated: </span>
              {formatHumanTimestamp(timestampDate)}
            </div>
            <Button
              className={clsx(secondaryButton, reloadLabel, 'sdds-detail-02')}
              style={{ right: edgeCoordinates.left + 24, bottom: edgeCoordinates.top + 180 }} // show button on the bottom right side of the image (with some margin)
              disableRipple
              onClick={() => load(cameraAngle)}
            >
              <RefreshIcon />
            </Button>
          </div>
        </span>
      ) : (
        <>
          <div className={canvasContainer}>
            <canvas ref={canvasRef} className={canvas} />
            {loading && <CircularProgress className={loader} size={25} />}

            <div
              className={clsx(floatingTimestampLabel, 'sdds-detail-02')}
              style={{ right: edgeCoordinates.left, bottom: edgeCoordinates.top }}
            >
              <span className={bold}>Last Updated: </span>
              {formatHumanTimestamp(timestampDate)}
            </div>
            {online && (
              <>
                <Button
                  className={clsx(secondaryButton, reloadLabel, 'sdds-detail-02')}
                  style={{
                    right: edgeCoordinates.left + 23,
                    bottom: edgeCoordinates.top + 50,
                    width: '40px',
                    height: '40px',
                  }}
                  disableRipple
                  onClick={() => load(cameraAngle)}
                >
                  <RefreshIcon />
                </Button>

                <div
                  className={buttonContainerPreviewCard}
                  style={{ left: edgeCoordinates.left + 37, bottom: edgeCoordinates.top + 27, flexBasis: '35px' }}
                >
                  <div className={leftButtons}>
                    <LeftArrow
                      click={() => setCameraAngle(CameraAngle.left)}
                      opacity={cameraAngle === CameraAngle.left ? 0.87 : 0.38}
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                  <div className={middleButtons} style={{ flexBasis: 0, gap: '30px' }}>
                    <UpArrow
                      click={() => setCameraAngle(CameraAngle.front)}
                      opacity={cameraAngle === CameraAngle.front ? 0.87 : 0.38}
                      style={{ cursor: 'pointer' }}
                    />
                    <DownArrow
                      click={() => setCameraAngle(CameraAngle.rear)}
                      opacity={cameraAngle === CameraAngle.rear ? 0.87 : 0.38}
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                  <div className={rightButtons}>
                    <RightArrow
                      click={() => setCameraAngle(CameraAngle.right)}
                      opacity={cameraAngle === CameraAngle.right ? 0.87 : 0.38}
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default CameraView;
