import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import clsx from 'clsx';
import { ChangeEvent, useState } from 'react';
import { t } from 'ttag';
import useModalStyles from './useModalStyles';
import Smoothing from '../config/setting/Smoothing';
import AlertsMuted from '../config/setting/AlertsMuted';
import Events from '../model/events';
import StyledRadio from './icons/StyledRadio';

interface IProps {
  settingsOpenState: [boolean, (settingsOpen: boolean) => boolean];
  smoothingState: [Smoothing, (smoothing: Smoothing) => Smoothing];
  alertsMutedState: [AlertsMuted, (alertsMuted: AlertsMuted) => AlertsMuted];
}

const SettingsModal = (props: IProps) => {
  const {
    settingsOpenState: [settingsOpen, setSettingsOpen],
    smoothingState: [smoothing, setSmoothing],
    alertsMutedState: [alertsMuted, setAlertsMuted],
  } = props;
  const [tempSmoothingState, setTempSmoothingState] = useState(smoothing);
  const [tempAlertsMuted, setTempAlertsMuted] = useState(alertsMuted);
  const {
    paper,
    dialogTitle,
    cancelButton,
    closeButton,
    dialogContent,
    formControl,
    formLabel,
    formSound,
    formVehiclebehaviour,
    formControlLabel,
    label,
    blueButton,
    actionButtons,
  } = useModalStyles();

  const close = () => {
    setSettingsOpen(false);
    setTempSmoothingState(smoothing);
    setTempAlertsMuted(alertsMuted);
  };

  const save = () => {
    setSettingsOpen(false);
    setSmoothing(tempSmoothingState as Smoothing);
    setAlertsMuted(tempAlertsMuted);
  };

  return (
    <Dialog classes={{ paper }} onClose={close} open={settingsOpen}>
      <DialogTitle className={dialogTitle} disableTypography>
        <Typography className="sdds-headline-05" variant="h5">
          {t`Settings`}
        </Typography>
      </DialogTitle>
      <IconButton aria-label={t`close`} className={closeButton} onClick={close}>
        <Close />
      </IconButton>
      <DialogContent className={dialogContent}>
        <FormControl className={formControl}>
          <FormLabel className={clsx(formLabel, formVehiclebehaviour, 'sdds-detail-02')} id="smoothing-label">
            {t`Vehicle behavior on map`}
          </FormLabel>
          <RadioGroup
            aria-labelledby="smoothing-label"
            name="smoothing"
            value={tempSmoothingState}
            onChange={(event) => setTempSmoothingState(event.target.value as Smoothing)}
          >
            <FormControlLabel
              classes={{ label: clsx(label, 'sdds-detail-02') }}
              className={formControlLabel}
              control={<StyledRadio />}
              label={t`Raw data`}
              onChange={() => window.dispatchEvent(new Event(Events.REMOVE_ALL_VEHICLES))}
              value="OFF"
            />
            <FormControlLabel
              classes={{ label: clsx(label, 'sdds-detail-02') }}
              className={formControlLabel}
              control={<StyledRadio />}
              label={t`Catch up`}
              onChange={() => window.dispatchEvent(new Event(Events.REMOVE_ALL_VEHICLES))}
              value="CATCH-UP"
            />
            <FormControlLabel
              classes={{ label: clsx(label, 'sdds-detail-02') }}
              className={formControlLabel}
              control={<StyledRadio />}
              disabled
              label={t`Dead reckoning`}
              value="DEAD-RECKONING"
            />
          </RadioGroup>
        </FormControl>
        <FormControl className={formControl}>
          <FormLabel className={clsx(formLabel, formSound, 'sdds-detail-02')} htmlFor="sound">{t`Sound`}</FormLabel>
          <FormControlLabel
            checked={tempAlertsMuted === 'OFF'}
            classes={{ label: clsx(label, 'sdds-detail-02') }}
            className={formControlLabel}
            control={<Checkbox color="primary" id="sound" data-testid="profile-menu-settings-alerts-muted" />}
            label={t`Allow sound for notifications`}
            onChange={(event) => {
              setTempAlertsMuted(
                (event as ChangeEvent<HTMLInputElement>).target.checked ? AlertsMuted.off : AlertsMuted.on,
              );
            }}
          />
        </FormControl>
        <FormControl className={formControl}>
          <Box className={actionButtons}>
            <Button className={cancelButton} disableRipple onClick={close}>
              <p className="sdds-detail-02">{t`Cancel`}</p>
            </Button>
            <Button
              className={blueButton}
              disableRipple
              onClick={save}
              id="save"
              data-testid="profile-menu-settings-save-button"
            >
              <p className="sdds-detail-02">{t`Save`}</p>
            </Button>
          </Box>
        </FormControl>
      </DialogContent>
    </Dialog>
  );
};

export default SettingsModal;
