import { min } from 'ramda';
import getDistances from '../getDistances';
import getHeading from '../getHeading';
import type ICoordinates from '../ICoordinates';
import type RoutedFeature from '../RoutedFeature';

const headingThreshold = 90;
const wrongDirectionPenalty = 10;

const getIndexClosestFeature = (
  route: ReadonlyArray<RoutedFeature>,
  coordinates: ICoordinates,
  heading: number | null,
  maxDistanceMeters?: number,
): { featureIndex: number; coordinateIndex: number } | null => {
  let indexes = null;

  if (route.length) {
    const allDistances = route.map(({ geometry }) =>
      getDistances(geometry.coordinates, coordinates).map(
        (distance, index) =>
          distance +
          (heading && Math.abs(heading - getHeading(geometry.coordinates, index)) > headingThreshold
            ? wrongDirectionPenalty
            : 0),
      ),
    );
    const minDistances = allDistances.map((distances) => distances.reduce(min, Infinity));
    const minDistance = minDistances.reduce(min, Infinity);

    if (!maxDistanceMeters || minDistance <= maxDistanceMeters) {
      const featureIndex = minDistances.indexOf(minDistance);
      const coordinateIndex = allDistances[featureIndex].indexOf(minDistance);

      if (featureIndex >= 0 && coordinateIndex >= 0) {
        indexes = { featureIndex, coordinateIndex };
      }
    }
  }

  return indexes;
};

export default getIndexClosestFeature;
