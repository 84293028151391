import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import routeObservable from './route.observable';

const destinationMarkerObservable = combineLatest([routeObservable]).pipe(
  map(([route]) => {
    if (!route.length) return { future: [], past: [] };
    return { future: route };
  }),
);

export default destinationMarkerObservable;
