import type { ActionList as ActionListType, MissionInput } from '@ats/graphql';
import { sendMission } from '@ats/graphql';
import PreviewMission from './PreviewMission';
import Progress from '../../send/Progress';

interface IProps {
  areaId: string;
  actionLists: ReadonlyArray<ActionListType> | null;
  clear: () => void;
  dispatchingProgress: Progress | null;
  externalEquipmentReference: string;
  mapVersion: string | null;
  previewMissionId: string | null;
  setActions: (actions: MissionInput['actions'] | null) => void;
  setTags: (tags: MissionInput['tags'] | null) => void;
  setActionList: (actionList: ActionListType | null) => void;
  startDispatching: (request: Promise<unknown> | null) => void;
  canDispatchtMission: boolean;
  renderReleaseComponent: () => JSX.Element | null;
  vehicleMode: string | null;
  setRenderButton: (render: boolean) => void;
  vehicleDisplayName: string;
  dispatcherRight: boolean;
}

const PreviewMissionContainer = (props: IProps) => {
  const {
    canDispatchtMission,
    renderReleaseComponent,
    areaId,
    actionLists,
    clear,
    dispatchingProgress,
    externalEquipmentReference,
    mapVersion,
    previewMissionId,
    setActions,
    setTags,
    setActionList,
    startDispatching,
    vehicleMode,
    setRenderButton,
    vehicleDisplayName,
    dispatcherRight,
  } = props;

  const dispatchMission = (
    actions: ActionListType['actions'],
    actionListId: ActionListType['id'],
    tags: ActionListType['tags'],
  ) => {
    if (mapVersion === null) throw new Error('Mission cannot be sent without the map version');
    const promise = sendMission({
      actions,
      externalEquipmentReference,
      mapVersion,
      actionListId,
      tags,
    });

    startDispatching(promise);

    return promise;
  };

  return (
    <PreviewMission
      canDispatchtMission={!!canDispatchtMission}
      setRenderButton={setRenderButton}
      renderReleaseComponent={renderReleaseComponent}
      areaId={areaId}
      actionLists={actionLists}
      clear={clear}
      dispatchingProgress={dispatchingProgress}
      dispatchMission={dispatchMission}
      previewMissionId={previewMissionId}
      setActions={setActions}
      setTags={setTags}
      setActionList={setActionList}
      vehicleMode={vehicleMode}
      vehicleDisplayName={vehicleDisplayName}
      dispatcherRight={dispatcherRight}
    />
  );
};

export default PreviewMissionContainer;
