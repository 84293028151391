import { makeStyles } from '@material-ui/core';
import { getStaffFg, Staff } from '@ats/graphql';
import { useEffect, useState } from 'react';
import { blue, red, yellow } from '../../theme/color';
import ControlCard from './ControlCard';
import IVehicle from '../../model/vehicle/IVehicle';
import Events from '../../model/events';

interface IProps {
  dispatcher: string;
  lockedBy: IVehicle['owner'];
  standDownUserList: Array<string | null> | null;
  active: boolean;
}

export const useStyles = makeStyles(
  {
    cardTopBlueBorder: {
      borderTop: `4px solid ${blue}`,
    },
    cardTopYellowBorder: {
      borderTop: `4px solid ${yellow}`,
    },
    cardTopRedBorder: {
      borderTop: `4px solid ${red}`,
    },
  },
  { index: 1 },
);

const ControlContainer = (props: IProps) => {
  const { lockedBy, standDownUserList, active, dispatcher } = props;
  const { cardTopYellowBorder, cardTopRedBorder, cardTopBlueBorder } = useStyles();

  // This is used to keep react from running the useEffect when the array is the same (but a different reference)
  const derivedStandDownUserList = standDownUserList ? standDownUserList.join('-') : '';

  const [standdownByNames, setStanddownByNames] = useState<string[]>([]);

  const [lockedByName, setLockedByName] = useState<string | null>();

  useEffect(() => {
    if (!lockedBy) {
      setLockedByName(null);
      return;
    }
    getStaffFg(lockedBy)
      .then((staff: Staff | null) => {
        setLockedByName(staff ? `${staff.givenName} ${staff.familyName}` : null);
      })
      .catch(() => {
        const detail = { title: `Could not get name for staff with id ${lockedBy}` };
        const event = new CustomEvent(Events.TOAST, { detail });
        window.dispatchEvent(event);
        setLockedByName(null);
      });
  }, [lockedBy]);

  useEffect(() => {
    setStanddownByNames([]);
    if (!standDownUserList) return;
    standDownUserList.forEach((externalId: string | null) => {
      if (!externalId) return;
      getStaffFg(externalId)
        .then((staff: Staff | null) => {
          setStanddownByNames((standdownByNames) => [
            ...standdownByNames,
            staff ? `${staff.givenName} ${staff.familyName}` : 'Unknown user',
          ]);
        })
        .catch(() => {
          const detail = { title: `Could not resolve the staff name for ExternalId ${externalId}` };
          const event = new CustomEvent(Events.TOAST, { detail });
          window.dispatchEvent(event);
          setStanddownByNames((standdownByNames) => [...standdownByNames, 'Unknown user']);
        });
    });
    // Only re-render if the standDownUserList array change, but use derivedStandDownUserList instead
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [derivedStandDownUserList]);

  return (
    <>
      {dispatcher && (
        <ControlCard
          cardTitle="Dispatcher rights"
          cardDescription={dispatcher}
          className={cardTopBlueBorder}
          dispatcher={dispatcher}
        />
      )}

      {lockedByName && (
        <ControlCard
          cardTitle="Controls are locked by"
          cardDescription={`${lockedByName ?? 'Unknown user'}`}
          className={cardTopYellowBorder}
        />
      )}
      {active && (
        <ControlCard
          cardTitle="In-vehicle stand down is active"
          cardDescription="In-vehicle release is required"
          className={cardTopRedBorder}
        />
      )}

      {standDownUserList && standDownUserList.length > 0 && (
        <ControlCard
          cardTitle="Personal stand down by"
          cardDescription={standdownByNames}
          className={cardTopRedBorder}
        />
      )}
    </>
  );
};

export default ControlContainer;
