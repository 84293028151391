import { DegradationPlanAction } from '@ats/graphql';
import { blue500, grey400, yellow, red500 } from '../../theme/color';

interface IDegradationStateColor {
  readonly foreground: string;
}

export const reducedSpeed: IDegradationStateColor = {
  foreground: yellow,
};
export const none: IDegradationStateColor = {
  foreground: blue500,
};
export const unknown: IDegradationStateColor = {
  foreground: grey400,
};
export const brake: IDegradationStateColor = {
  foreground: red500,
};

const getDegradationStateColor = (action: DegradationPlanAction | undefined): IDegradationStateColor => {
  switch (action) {
    case DegradationPlanAction.PanicStop:
    case DegradationPlanAction.ExtremeBrakeAlongPath:
    case DegradationPlanAction.HardBrakeAlongPath:
    case DegradationPlanAction.SoftBrakeAlongPath:
      return brake;
    case DegradationPlanAction.ReducedSpeed:
    case DegradationPlanAction.None:
      return reducedSpeed;
    case DegradationPlanAction.Unknown:
      return unknown;
    default:
      return none;
  }
};

export default getDegradationStateColor;
