import { makeStyles } from '@material-ui/core';
import CSS from 'csstype';

export interface ICommonIconProps {
  className?: string;
  style?: Pick<CSS.Properties, 'position' | 'left' | 'top' | 'fill'>;
  testid?: string;
}

export const useIconStyles = makeStyles(
  {
    tooltipTypography: {
      width: 'auto',
      height: 'auto',
      display: 'flex',
      padding: '0',
      margin: '0',
      flex: 'none',
      order: 0,
      flexGrow: 0,
      whiteSpace: 'pre-line',
    },
  },
  { index: 1 },
);
