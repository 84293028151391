import { makeStyles } from '@material-ui/core';
import {
  grey100,
  grey868,
  grey900,
  blue,
  dimWhite,
  semiDimWhite,
  blue600,
  white,
  blue500,
  blue400,
  disabledText,
} from '../../theme/color';

// eslint-disable-next-line import/prefer-default-export
export const usePoiPositionFormStyles = makeStyles(
  {
    cancelButton: {
      height: '56px',
      left: ' 26px ',
      marginTop: '391px',
      position: 'absolute',
      width: '152px',
      bottom: ' 26px ',
      borderRadius: '4px',
      borderColor: 'rgba(255, 255, 255, 0.38)',
      border: `1px solid`,
      '&:hover': {
        border: `1px solid ${dimWhite} !important`,
      },
      '&:focus': {
        border: `1px solid ${blue}`,
      },
      '&:active': {
        border: `1px solid ${semiDimWhite} !important`,
      },
    },
    divider: {
      backgroundColor: grey868,
      height: '1px',
      marginTop: '50px',
      position: 'absolute',
      width: '320px',
    },
    editButton: {
      height: '56px',
      margin: '391px 24px 0px 24px',
      position: 'absolute',
      width: '320px',
      borderRadius: '4px',
      bottom: ' 26px ',
      borderColor: 'rgba(255, 255, 255, 0.38)',
      border: `1px solid`,
      '&:hover': {
        border: `1px solid`,
        borderColor: 'rgba(255, 255, 255, 0.6)',
        backgroundColor: grey900,
      },
      '&:focus': {
        border: `1px solid ${blue}`,
      },
    },
    form: {
      backgroundColor: grey900,
      height: 'inherit',
      marginTop: '123px',
      position: 'relative',
    },
    label: {
      color: 'rgba(255, 255, 255, 0.87)',
      display: 'inline-block',
      float: 'left',
      paddingBottom: '24px',
      paddingLeft: '24px',
      width: '48px',
    },
    poiIcon: {
      left: '155px',
      height: '77px',
      width: '70px',
      position: 'absolute',
    },
    saveButton: {
      bottom: ' 26px ',
      height: '56px',
      marginTop: '391px',
      position: 'absolute',
      right: '24px',
      width: '152px',
      border: 'none',
      backgroundColor: blue600,
      color: white,
      '&:hover': {
        backgroundColor: blue500,
        border: 'none',
      },
      '&:focus': {
        backgroundColor: blue400,
        border: 'none',
      },
      '&:disabled': {
        color: disabledText,
        backgroundColor: grey868,
      },
    },
    selectOnMapButton: {
      left: '183px',
      padding: '12px',
      top: '49px',
    },
    tableRow: {
      clear: 'both',
      content: '',
      display: 'table',
    },
    table: {
      marginTop: '120px',
    },
    textField: {
      color: grey100,
      display: 'inline',
      height: '32px',
      position: 'absolute',
      right: '24px',
      width: '233px',
      '& .MuiInputBase-root': {
        '& input': {
          textAlign: 'right',
          width: '210px',
        },
      },
      '& input::-webkit-outer-spin-button': {},
      '& input::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
      '& input::-webkit-input-placeholder': {
        textAlign: 'right',
      },
      '&:focus-within': {
        background: grey868,
      },
    },
  },
  { index: 1 },
);
