import type { Position } from '@ats/graphql';
import type ICoordinates from './ICoordinates';
import type RoutedFeature from './RoutedFeature';

const getEndCoordinates = (route: ReadonlyArray<RoutedFeature>, start: Position | null): ICoordinates | null => {
  const routeLength = route.length;
  let latitude = start?.latitude;
  let longitude = start?.longitude;

  if (routeLength) {
    const { coordinates } = route[routeLength - 1].geometry;
    const coordinatesLength = coordinates.length;

    if (coordinatesLength) {
      [longitude, latitude] = coordinates[coordinatesLength - 1];
    }
  }

  return latitude && longitude ? { latitude, longitude } : null;
};

export default getEndCoordinates;
