import type { Position } from 'geojson';
import type { DeepReadonly } from 'ts-essentials';
import getIndexClosestCoordinates from './getIndexClosestCoordinates';
import type ICoordinates from './ICoordinates';

export const trimStart = (coordinates: DeepReadonly<Position[]>, start: ICoordinates): DeepReadonly<Position[]> => {
  const startIndex = getIndexClosestCoordinates(coordinates, start);

  return coordinates.slice(startIndex);
};

export const trimEnd = (coordinates: DeepReadonly<Position[]>, end: ICoordinates): DeepReadonly<Position[]> => {
  const endIndex = getIndexClosestCoordinates(coordinates, end);
  return endIndex >= 0 ? coordinates.slice(0, endIndex + 1) : coordinates;
};
