import type { Position } from 'geojson';
import { min } from 'ramda';
import type { DeepReadonly } from 'ts-essentials';
import getDistances from './getDistances';
import type ICoordinates from './ICoordinates';

const getIndexClosestCoordinates = (coordinates: DeepReadonly<Position[]>, from: ICoordinates): number => {
  const distances = getDistances(coordinates, from);
  const minDistance = distances.reduce(min, Infinity);

  return distances.indexOf(minDistance);
};

export default getIndexClosestCoordinates;
