import { SvgIcon } from '@material-ui/core';
import CSS from 'csstype';
import { ICommonIconProps } from './CommonIconProps';

interface IProps extends ICommonIconProps {
  click: React.MouseEventHandler<SVGSVGElement> | undefined;
  style?: Pick<CSS.Properties, 'position' | 'left' | 'top' | 'width' | 'height' | 'color' | 'cursor'>;
}
export const Copy = ({ click, className, style, testid }: IProps) => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      onClick={click}
      className={className}
      style={style}
      data-testid={testid}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6996 3.87115C11.6996 3.11176 11.084 2.49615 10.3246 2.49615H3.87695C3.11755 2.49615 2.50195 3.11175 2.50195 3.87115V11.9218C2.50195 12.6812 3.11756 13.2968 3.87695 13.2968H10.3246C11.084 13.2968 11.6996 12.6812 11.6996 11.9218V3.87115ZM10.3246 1.24615C11.7744 1.24615 12.9496 2.4214 12.9496 3.87115V5.45303H16.1227C17.5724 5.45303 18.7477 6.62829 18.7477 8.07803V16.1301C18.7477 17.5799 17.5724 18.7551 16.1227 18.7551H9.0543C7.60455 18.7551 6.4293 17.5799 6.4293 16.1301V14.5468H3.87695C2.4272 14.5468 1.25195 13.3715 1.25195 11.9218V3.87115C1.25195 2.4214 2.4272 1.24615 3.87695 1.24615H10.3246ZM7.6793 14.5468V16.1301C7.6793 16.8895 8.29491 17.5051 9.0543 17.5051H16.1227C16.882 17.5051 17.4977 16.8895 17.4977 16.1301V8.07803C17.4977 7.31864 16.882 6.70303 16.1227 6.70303H12.9496V11.9218C12.9496 13.3715 11.7744 14.5468 10.3246 14.5468H7.6793Z"
        fill="#F9FAFB"
      />
    </SvgIcon>
  );
};
export default Copy;
