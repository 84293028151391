import { SvgIcon } from '@material-ui/core';
import { ICommonIconProps } from './CommonIconProps';

export const QueueIcon = ({ className }: ICommonIconProps) => (
  <SvgIcon
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle cx="28" cy="28" r="27.5" fill="white" stroke="#0D0F13" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.8712 35.8093H33.1283H30.0475V36.8678C30.0536 37.4468 29.7522 37.9846 29.2573 38.2777C28.7625 38.5709 28.1499 38.5742 27.6517 38.2867C27.1539 37.9989 26.8464 37.4645 26.8464 36.8856V34.8481L26.2519 32.6096C26.1967 32.3431 26.1689 32.0716 26.1689 31.7992H23.088V32.8579C23.0941 33.4365 22.7927 33.9746 22.2979 34.2676C21.803 34.5606 21.1904 34.5641 20.6924 34.2764C20.1942 33.9888 19.8871 33.4544 19.8871 32.8754V30.838L19.2924 28.5997C19.2373 28.333 19.2096 28.0615 19.2096 27.7893H16.1287V28.8476C16.1349 29.4266 15.8334 29.9645 15.3385 30.2577C14.8437 30.5505 14.2311 30.554 13.7329 30.2663C13.2349 29.9788 12.9278 29.4443 12.9278 28.8654V26.8279L12.3331 24.5894C12.0361 23.1544 12.5481 21.6711 13.6643 20.7323L15.5649 17.1266C15.7861 16.707 16.1739 16.4025 16.6314 16.2891C19.5277 15.5703 22.5535 15.5703 25.4499 16.2891C25.9072 16.4025 26.2951 16.707 26.5162 17.1266L27.9044 19.7604C29.4225 19.7526 30.9352 19.9335 32.4092 20.2992C32.8665 20.4126 33.2545 20.7174 33.4757 21.1367L34.8639 23.7705C36.3816 23.7627 37.8947 23.9436 39.3686 24.3093C39.826 24.4227 40.2138 24.7272 40.435 25.1468L42.3357 28.7524C43.4519 29.6913 43.9639 31.1746 43.6669 32.6096L43.0722 34.8481V36.8856C43.0722 37.7771 42.3557 38.5 41.4717 38.5C40.5879 38.5 39.8712 37.7771 39.8712 36.8856V35.8093ZM23.5908 20.2991C23.341 20.361 23.1093 20.4808 22.9135 20.6489H15.5833L17.0141 17.9347C19.6553 17.2484 22.4259 17.2484 25.0669 17.9347L26.0824 19.8607C25.2429 19.9494 24.4104 20.0958 23.5908 20.2991ZM29.8728 24.6588C30.0688 24.4909 30.3005 24.3711 30.5501 24.3092C31.3697 24.1059 32.2022 23.9595 33.0417 23.871L32.0264 21.945C29.3852 21.2586 26.6147 21.2586 23.9735 21.945L22.543 24.6588H29.8728ZM29.5023 28.6689L30.9329 25.9549C33.5741 25.2686 36.3445 25.2686 38.9857 25.9549L40.4165 28.6689H29.5023Z"
      fill="#0D0F13"
    />
  </SvgIcon>
);

export default QueueIcon;
