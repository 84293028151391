import { Avatar, IconButton, Button, Menu, MenuItem, Tooltip, Typography, makeStyles } from '@material-ui/core';
import FeedbackIcon from '@material-ui/icons/Feedback';
import { useObservable } from '@libreact/use-observable';
import { useEquipment } from '@ats/graphql';
import clsx from 'clsx';
import { useRef, useState } from 'react';
import { t } from 'ttag';
import { IUserAttributes } from '../model/user';
import {
  highEmphasisLightTheme,
  blue700,
  blue800,
  blue900,
  grey100,
  grey200,
  grey600,
  grey700,
  white,
} from '../theme/color';
import { selectedAreaId as selectedAreaIdObservable } from '../model/observables';
import environment from '../config/environment';
import NotificationMenu from './notification/NotificationMenu';
import Chevron from './icons/Chevron';
import { INotification } from '../model/notification/notificationType';
import { View } from '../model/ViewTypes';

interface IProps {
  giveFeedback: () => void;
  openSettingsPanel: () => void;
  openLogDumpPanel: () => void;
  signOut: () => void;
  user: IUserAttributes | null;
  markAsNotVisible: () => void;
  notifications: INotification[];
  selectedEquipmentState: [string | null, React.Dispatch<React.SetStateAction<string>>];
  setSnap: (arg0: boolean) => void;
  selectedAreaId: string;
  notificationPanelState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  viewState: [View, React.Dispatch<React.SetStateAction<View>>];
}

const useStyles = makeStyles(
  {
    root: {
      alignItems: 'center',
      backgroundColor: blue900,
      boxShadow: `0px 1px ${blue800}`,
      display: 'flex',
      height: '64px',
      position: 'relative',
      zIndex: 1,
    },
    avatar: {
      backgroundColor: grey700,
      color: white,
      height: '32px',
      width: '32px',
    },
    avatarFocus: {
      backgroundColor: grey600,
      color: white,
      height: '32px',
      width: '32px',
    },
    button: {
      border: 0,
      fontSize: '14px',
      borderRight: `1px solid ${blue800}`,
      borderRadius: 0,
      height: '64px',
      minWidth: '65px',
      paddingLeft: '24px',
      paddingRight: '24px',
      backgroundColor: blue900,
      transition: 'none',
      '&:hover, &:focus': {
        border: 'unset',
        marginRight: '1px',
        backgroundColor: blue800,
      },
      '&:active': {
        backgroundColor: blue700,
      },
      '&:visited': {
        outline: 'unset',
      },
    },
    rectangleButton: {
      width: '64px',
      height: '64px',
      '&:hover, &:focus': {
        outline: 'unset',
        marginRight: 'unset',
        backgroundColor: blue800,
      },
    },
    borderLeft: {
      borderLeft: `1px solid ${blue800}`,
      borderRight: 'unset',
    },
    buttonFocus: {
      backgroundColor: white,
    },
    list: {
      fontSize: '14px',
      minWidth: '320px',
      padding: 0,
    },
    vehicleList: {
      fontSize: '14px',
      minWidth: '190px',
      padding: 0,
    },
    menuVehicleItem: {
      background: '#37414F',
      '&:hover': {
        backgroundColor: '#556376',
      },
      '&:first-child': {
        borderTop: 'unset',
      },
      borderTop: '2px solid #56657A',
      color: white,
      fontSize: '14px',
      height: '48px',
      paddingLeft: '24px',
      paddingRight: '24px',
    },
    menuItem: {
      background: white,
      '&:hover': {
        backgroundColor: grey100,
      },
      '&:first-child': {
        borderTop: `1px solid ${white}`,
        height: '84px',
        fontWeight: 'bold',
        backgroundColor: white,
      },
      borderTop: `1px solid ${grey200}`,
      color: highEmphasisLightTheme,
      fontSize: '14px',
      height: '64px',
      paddingLeft: '24px',
      paddingRight: '24px',
    },
    border: {
      borderRight: `1px solid ${blue800}`,
    },
    siteName: {
      color: 'rgba(255, 255, 255, 0.87)',
      font: '14px "Scania Sans Headline"',
      lineHeight: '16px',
      letterSpacing: '-0.02em',
      paddingLeft: '24px',
      paddingRight: '24px',
      paddingTop: '24px',
      paddingBottom: '24px',
    },
    filler: {
      flexGrow: 1,
    },
    iconPadding: {
      marginLeft: '10px',
    },
  },
  { index: 1 },
);

const AppBar = (props: IProps) => {
  const {
    notifications,
    selectedEquipmentState,
    setSnap,
    selectedAreaId,
    giveFeedback,
    openSettingsPanel,
    openLogDumpPanel,
    signOut,
    markAsNotVisible,
    notificationPanelState,
    user,
    viewState,
  } = props;
  const [areaId] = useObservable(selectedAreaIdObservable);
  const [equipment] = useEquipment({ areaId });
  const safeEquipment = equipment || [];
  const [selectedEquipmentId, setSelectedEquipmentId] = selectedEquipmentState;
  const [view, setView] = viewState;
  const {
    root,
    siteName,
    button,
    buttonFocus,
    rectangleButton,
    avatar,
    avatarFocus,
    list,
    vehicleList,
    menuItem,
    menuVehicleItem,
    borderLeft,
    filler,
    iconPadding,
    border,
  } = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [vehicleDropdownOpen, setVehicleDropdownOpen] = useState<boolean>(false);
  const profileButtonRef = useRef(null);
  const vehicleButtonRef = useRef(null);

  const isMap = view === View.MapView;
  const isVehicleView = view === View.VehicleView;
  const showDropdown = isVehicleView || (isMap && !selectedEquipmentId);

  const borderRight = environment.env === 'dev' ? border : null;

  return (
    <div className={root}>
      {environment.env === 'dev' && environment.buildInfo ? (
        <Tooltip
          title={
            <div id="buildInfo">
              <Typography color="inherit" variant="h3" noWrap>
                <strong>Branch:</strong>{' '}
                <span data-testid="buildInfo-branchName">{`${environment.buildInfo.branchName}`}</span>
              </Typography>
              <Typography color="inherit" variant="h3" noWrap>
                <strong>Last commit time:</strong>{' '}
                <span data-testid="buildInfo-lastCommitTimestamp">
                  {`${new Date(environment.buildInfo.lastCommitTimestamp).toLocaleDateString('sv-SE', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  })} ${new Date(environment.buildInfo.lastCommitTimestamp).toLocaleTimeString('sv-SE')}`}
                </span>
              </Typography>
              <Typography color="inherit" variant="h3" noWrap>
                <strong>Last commit hash:</strong>{' '}
                <span data-testid="buildInfo-lastCommitHash">{`${environment.buildInfo.lastCommitHash}`}</span>
              </Typography>
              {environment.buildInfo.mergeRequest && (
                <Typography color="inherit" variant="h3" noWrap>
                  <strong>Merge request:</strong>{' '}
                  <span data-testid="buildInfo-mergeRequest">{`${environment.buildInfo.mergeRequest}`}</span>
                </Typography>
              )}
            </div>
          }
          enterDelay={1000}
        >
          <Typography
            className={clsx(siteName, borderRight)}
            style={{ color: 'yellow' }}
            variant="h1"
            data-testid="iceHeader"
          >
            {`Intelligent Control Environment - ${environment.buildInfo.branchName}`}
          </Typography>
        </Tooltip>
      ) : (
        <Typography className={clsx(siteName, borderRight)} variant="h1" data-testid="iceHeader">
          Intelligent Control Environment
        </Typography>
      )}
      {environment.env === 'dev' && environment.appSync.endpoint.includes('api.dev.ice.devtest') && (
        <Typography className={clsx(siteName, borderRight)} style={{ color: 'cyan' }} variant="h2">
          Using dev AppSync
        </Typography>
      )}
      {environment.env && environment.env !== 'prod' ? (
        <>
          <Button className={button} disableRipple onClick={() => setView(View.MapView)}>
            Map overview
          </Button>
          <Button
            className={button}
            disableRipple
            ref={vehicleButtonRef}
            onClick={() => {
              if (showDropdown) {
                setVehicleDropdownOpen(true);
              } else {
                setView(View.VehicleView);
              }
            }}
          >
            Vehicle view {showDropdown ? <Chevron className={iconPadding} /> : ''}
          </Button>
        </>
      ) : null}
      <Menu
        id="vehicle-view-select"
        anchorEl={vehicleButtonRef.current}
        onClose={() => setVehicleDropdownOpen(false)}
        open={vehicleDropdownOpen}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        classes={{ list: vehicleList }}
        className={borderLeft}
        getContentAnchorEl={null}
        marginThreshold={0}
      >
        {safeEquipment.map((vehicle) => {
          const { displayName, externalEquipmentReference } = vehicle;
          return (
            <MenuItem
              className={clsx(menuVehicleItem, 'sdds-detail-02')}
              disableRipple
              key={externalEquipmentReference}
              onClick={() => {
                setSelectedEquipmentId(externalEquipmentReference);
                setView(View.VehicleView);
                setVehicleDropdownOpen(false);
              }}
            >
              {displayName}
            </MenuItem>
          );
        })}
      </Menu>
      <div className={filler} />
      <Tooltip title={t`Give Feedback`}>
        <IconButton
          aria-label={t`Give Feedback`}
          className={clsx(button, borderLeft, rectangleButton)}
          disableRipple
          onClick={giveFeedback}
        >
          <FeedbackIcon />
        </IconButton>
      </Tooltip>
      <NotificationMenu
        notificationPanelState={notificationPanelState}
        markAsNotVisible={markAsNotVisible}
        notifications={notifications}
        setSelectedEquipmentId={setSelectedEquipmentId}
        setSnap={setSnap}
        selectedAreaId={selectedAreaId}
      />
      <IconButton
        aria-controls="profile-menu"
        aria-haspopup="true"
        aria-label={t`Profile`}
        className={clsx(button, borderLeft, rectangleButton, open ? buttonFocus : null)}
        disableRipple
        onClick={() => setOpen(!open)}
        ref={profileButtonRef}
        data-testid="profile-menu"
      >
        <Avatar className={open ? clsx(avatarFocus, 'sdds-headline-07') : clsx(avatar, 'sdds-headline-07')}>
          {user?.given_name[0]}
          {user?.family_name[0]}
        </Avatar>
      </IconButton>
      <Menu
        anchorEl={profileButtonRef.current}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        classes={{ list }}
        getContentAnchorEl={null}
        marginThreshold={0}
        id="profile-menu"
        onClose={() => setOpen(false)}
        open={open}
      >
        <MenuItem className={clsx(menuItem, 'sdds-detail-02')} disableRipple>
          {user?.given_name} {user?.family_name}
        </MenuItem>
        <MenuItem
          className={clsx(menuItem, 'sdds-detail-02')}
          disableRipple
          onClick={() => {
            setOpen(false);
            openLogDumpPanel();
          }}
          data-testid="profile-menu-logdump"
        >
          {t`Log dump`}
        </MenuItem>
        <MenuItem
          className={clsx(menuItem, 'sdds-detail-02')}
          disableRipple
          onClick={() => {
            setOpen(false);
            openSettingsPanel();
          }}
          data-testid="profile-menu-settings"
        >
          {t`Settings`}
        </MenuItem>
        <MenuItem
          className={clsx(menuItem, 'sdds-detail-02')}
          disableRipple
          onClick={() => {
            setOpen(false);
            signOut();
          }}
          data-testid="profile-menu-logout"
        >
          {t`Log out`}
        </MenuItem>
      </Menu>
      <IconButton className={clsx(button, borderLeft, rectangleButton)} disableRipple href="/">
        <img src="/static/image/icon/32x32.png" alt="Scania" />
      </IconButton>
    </div>
  );
};

export default AppBar;
