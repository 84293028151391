import {
  CircularProgress,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { t } from 'ttag';
import clsx from 'clsx';
import Events from '../model/events';
import { blue400, blue500, blue600, grey100, grey900 } from '../theme/color';

const useStyles = makeStyles(
  {
    paper: {
      backgroundColor: grey900,
      width: '544px',
      height: '268px',
    },
    dialogTitle: {
      padding: '16px',
      color: grey100,
      width: '400px',
    },
    closeButton: {
      position: 'absolute',
      right: '4px',
      top: '4px',
    },
    dialogContent: {
      paddingLeft: '16px',
    },
    controlBox: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: '40px',
      paddingBottom: '18px',
      position: 'absolute',
      bottom: '0px',
    },
    descriptionBox: {
      color: 'rgba(255, 255, 255, 0.87)',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '-0.02em',
      width: '420px',
    },
    saveButton: {
      width: '175px',
      height: '54px',
      backgroundColor: blue600,
      borderRadius: '4px',
      marginLeft: '20px',
      position: 'static',
      fontSize: '14px',
      color: '#fff',
      textTransform: 'none',
      display: 'flex',
      '&:hover': {
        backgroundColor: blue500,
        border: 'none',
      },
      '&:focus': {
        background: blue400,
        border: 'none',
      },
    },
    spacedItem: {
      marginLeft: '11px',
    },
    cancelButton: {
      width: '84px',
      height: '54px',
      padding: '20px',
      borderRadius: '4px',
      borderColor: 'rgba(255, 255, 255, 0.38)',
      border: `1px solid`,
      '&:hover': {
        border: `1px solid`,
        borderColor: 'rgba(255, 255, 255, 0.6)',
        backgroundColor: grey900,
      },
    },
  },
  { index: 1 },
);

const ModalContainer = () => {
  const {
    controlBox,
    paper,
    descriptionBox,
    dialogTitle,
    cancelButton,
    closeButton,
    dialogContent,
    saveButton,
    spacedItem,
  } = useStyles();

  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(false);

  const open = !!content;
  const { title, description, subDescription, cancelLabel, executeLabel, onLoadingLabel, executeCallback } =
    content || {};

  const close = () => {
    if (loading) return;
    setContent(null);
  };

  const save = () => {
    setLoading(true);

    executeCallback().finally(() => {
      setContent(null);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (content === null) {
      setLoading(false);
    }
  }, [content, setLoading]);

  const modalCallback = ({ detail }) => {
    setContent({
      title: detail.title,
      description: detail.description,
      subDescription: detail.subDescription,
      cancelLabel: detail.cancelLabel,
      executeLabel: detail.executeLabel,
      onLoadingLabel: detail.onLoadingLabel,
      executeCallback: detail.executeCallback,
    });
  };

  useEffect(() => {
    window.addEventListener(Events.MODAL, modalCallback);
    return () => window.removeEventListener(Events.MODAL, modalCallback);
  }, []);

  return (
    <Dialog classes={{ paper }} onClose={close} open={open}>
      <IconButton aria-label={t`close`} disabled={loading} className={closeButton} onClick={close}>
        <Close />
      </IconButton>
      <DialogTitle className={dialogTitle} disableTypography>
        <Typography className="sdds-headline-05" variant="h5">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent className={dialogContent}>
        <Box className={descriptionBox}>
          <Typography className="sdds-detail-01">{description}</Typography>
          <Typography className="sdds-detail-01">{subDescription}</Typography>
        </Box>
        <Box className={controlBox}>
          <Button className={cancelButton} disabled={loading} disableRipple onClick={close}>
            <p className="sdds-detail-02">{cancelLabel}</p>
          </Button>
          <Button
            className={clsx(saveButton, 'sdds-detail-02')}
            disableRipple
            onClick={save}
            id="save"
            disabled={loading}
            data-testid="profile-menu-settings-save-button"
          >
            {loading ? (
              <>
                <CircularProgress size={12} />
                <span className={spacedItem}>{onLoadingLabel}</span>
              </>
            ) : (
              executeLabel
            )}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ModalContainer;
