import type { Control } from '@ats/graphql';
import { AreaType } from '@ats/graphql';
import { Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Divider } from '@material-ui/core';
import clsx from 'clsx';
import { memo, useEffect } from 'react';
import { useObservable } from '@libreact/use-observable';
import { t } from 'ttag';
import type IMissionOverview from '../../model/mission/overview/IMissionOverview';
import type IVehicleStateColor from '../../model/vehicle/state/IVehicleStateColor';
import { getButtonTextClear, getButtonTextStandDown } from '../../send/getButtonText';
import Progress from '../../send/Progress';
import ActiveMissionActionList from './ActiveMissionActionList';
import { useCardStyles } from './Card.styles';
import { useCommonStyles } from '../../theme/theme';
import { selectedArea as selectedAreaObservable } from '../../model/observables';

interface IProps {
  makeSpaceForReleaseButton: boolean;
  setRenderButton: (render: boolean) => void;
  renderReleaseComponent: () => JSX.Element | null;
  canClear: boolean;
  canStandDown: boolean;
  hasReleaseFailed: boolean;
  hasClearFailed: boolean;
  hasStandDownFailed: boolean;
  title: string;
  clearingProgress: Progress | null;
  dispatchingProgress: Progress | null;
  missionOverview: IMissionOverview;
  clear: () => void;
  standDown: () => Promise<Control | void>;
  standingDownProgress: Progress | null;
  vehicleColor: IVehicleStateColor;
}

const ActiveMission = ({
  setRenderButton,
  renderReleaseComponent,
  canClear,
  canStandDown,
  makeSpaceForReleaseButton,
  hasReleaseFailed,
  hasClearFailed,
  hasStandDownFailed,
  title,
  clearingProgress,
  dispatchingProgress,
  missionOverview,
  clear,
  standDown,
  standingDownProgress,
  vehicleColor,
}: IProps) => {
  const { ghostButton } = useCommonStyles();
  const {
    card,
    cardHeader,
    cardHeaderTypography,
    action,
    cardContent,
    cardActionsError,
    cardActions,
    button,
    buttonToStandDown,
    circularProgress,
    noBackground,
  } = useCardStyles();

  const clearing = clearingProgress === Progress.IN_PROGRESS || clearingProgress === Progress.VERY_SLOW;
  const dispatching = dispatchingProgress === Progress.IN_PROGRESS || dispatchingProgress === Progress.VERY_SLOW;
  const standingDown = standingDownProgress === Progress.IN_PROGRESS || standingDownProgress === Progress.VERY_SLOW;
  const [area] = useObservable(selectedAreaObservable);

  useEffect(() => {
    if ((canStandDown && area?.areaType !== AreaType.AREA_TYPE_H2H) || makeSpaceForReleaseButton) {
      setRenderButton(true);
    } else {
      setRenderButton(false);
    }
  }, [canStandDown, setRenderButton, makeSpaceForReleaseButton, area]);

  return (
    <Card className={clsx(card, noBackground)}>
      <CardHeader
        action={
          canClear && (
            <>
              <Button
                disableRipple
                className={clsx(ghostButton, 'sdds-headline-07')}
                disabled={dispatching}
                onClick={clear}
                data-testid="clear-active-mission-button"
              >
                {clearing && <CircularProgress className={circularProgress} size="3.5rem" />}
                {getButtonTextClear(clearingProgress)}
              </Button>
            </>
          )
        }
        classes={{ action }}
        className={cardHeader}
        title={title}
        titleTypographyProps={{ className: clsx(cardHeaderTypography, 'sdds-headline-04') }}
      />
      <Divider />
      <CardContent className={cardContent}>
        <ActiveMissionActionList missionOverview={missionOverview} vehicleColor={vehicleColor} />
      </CardContent>
      <CardContent className={clsx(cardActionsError, 'sdds-detail-05')}>
        {hasReleaseFailed && t`Failed to release. Try again.`}
        {hasClearFailed && t`Failed to clear mission. Try again.`}
        {hasStandDownFailed && t`Failed to stand down. Try again.`}
      </CardContent>
      <CardActions className={cardActions}>
        {canStandDown && area?.areaType !== AreaType.AREA_TYPE_H2H ? (
          <Button
            className={clsx(button, buttonToStandDown, 'sdds-detail-02')}
            fullWidth
            disableRipple
            onClick={standDown}
            data-testid="standdown-vehicle-button"
          >
            {standingDown && <CircularProgress className={circularProgress} size="3.5rem" />}
            {getButtonTextStandDown(standingDownProgress)}
          </Button>
        ) : null}
        {renderReleaseComponent()}
      </CardActions>
    </Card>
  );
};

export default memo(ActiveMission);
