import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { v4 } from 'uuid';
import { keepAttention } from '../model/duration';
import Progress from './Progress';
import ProgressMap, { IProgressEntry } from './ProgressMap';

const useProgress = (uuid: string | null): readonly [Progress | null, (request: Promise<unknown> | null) => void] => {
  const [, setProgress] = useState<Progress | null>(null);
  const mountedRef = useRef(true);
  const progressMapRef = useRef<ProgressMap>({});
  const uuidRef = useRef<string | null>(uuid);
  const startRequest = useMemo(() => createStartRequest(setProgress, mountedRef, progressMapRef, uuidRef), []);

  useEffect(() => {
    uuidRef.current = uuid;
  }, [uuid]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return [getProgressFromMap(progressMapRef, uuid), startRequest];
};

const getEntryFromMap = (progressMapRef: MutableRefObject<ProgressMap>, uuid: string | null) =>
  progressMapRef.current[uuid || ''] || null;

const getProgressFromEntry = (entry: IProgressEntry | null) => entry?.progress || null;

const getProgressFromMap = (progressMapRef: MutableRefObject<ProgressMap>, uuid: string | null) =>
  getProgressFromEntry(getEntryFromMap(progressMapRef, uuid));

const createStartRequest = (
  setProgress: (progress: Progress | null) => void,
  mountedRef: MutableRefObject<boolean>,
  progressMapRef: MutableRefObject<ProgressMap>,
  uuidRef: MutableRefObject<string | null>,
) => {
  const getEntry = (uuid: string | null) => getEntryFromMap(progressMapRef, uuid);

  const setEntry = (uuid: string | null, entry: IProgressEntry | null) => {
    progressMapRef.current = {
      ...progressMapRef.current,
      [uuid || '']: entry,
    };

    if (uuid === uuidRef.current) {
      // Force react to re-render any components using the hook.
      setProgress(getProgressFromEntry(entry));
    }
  };

  const handleSlowRequest = (uuid: string | null, requestPlusRetriesUuid: string) => {
    const entry = getEntry(uuid);

    if (
      mountedRef.current &&
      entry &&
      entry.requestPlusRetriesUuid === requestPlusRetriesUuid &&
      entry.progress === Progress.IN_PROGRESS
    ) {
      setEntry(uuid, {
        ...entry,
        progress: Progress.VERY_SLOW,
      });
    }
  };

  const handleFailure = (uuid: string | null, requestPlusRetriesUuid: string) => {
    const entry = getEntry(uuid);

    if (mountedRef.current && entry && entry.requestPlusRetriesUuid === requestPlusRetriesUuid) {
      setEntry(uuid, {
        ...entry,
        progress: Progress.FAILED,
      });
    }
  };

  const handleDone = (uuid: string | null, requestPlusRetriesUuid: string) => {
    const entry = getEntry(uuid);

    if (mountedRef.current && entry && entry.requestPlusRetriesUuid === requestPlusRetriesUuid) {
      setEntry(uuid, {
        ...entry,
        progress: Progress.DONE,
      });
    }
  };

  const startRequest = (request: Promise<unknown> | null) => {
    const uuid = uuidRef.current;

    if (request) {
      const entry = getEntry(uuid);
      let requestPlusRetriesUuid: string;

      if (entry && entry.progress === Progress.IN_PROGRESS) {
        // Retry
        requestPlusRetriesUuid = entry.requestPlusRetriesUuid;
      } else {
        // New Request
        requestPlusRetriesUuid = v4();

        setEntry(uuid, {
          progress: Progress.IN_PROGRESS,
          requestPlusRetriesUuid,
        });

        setTimeout(() => handleSlowRequest(uuid, requestPlusRetriesUuid), keepAttention);
      }

      request.then(
        () => handleDone(uuid, requestPlusRetriesUuid),
        () => handleFailure(uuid, requestPlusRetriesUuid),
      );
    } else {
      setEntry(uuid, null);
    }
  };

  return startRequest;
};

export default useProgress;
