import { MissionState } from '@ats/graphql';
import { curry, path, prop, sortBy } from 'ramda';
import IMissionStateExecuting from './IMissionStateExecuting';

const sortActionIndex = sortBy(curry(path(['executing', 'actionIndex'])));
const sortCommandIndex = sortBy(curry(path(['executing', 'commandIndex'])));
const sortTimestamp = sortBy(prop('timestamp'));

const getExecutingMission = (states: ReadonlyArray<MissionState>): IMissionStateExecuting | null => {
  const terminated: { [missionId: string]: true } = {};
  let executingMission: IMissionStateExecuting | null = null;

  states.forEach(({ missionId, rejected, failed, cancelled, done }) => {
    if (rejected || failed || cancelled || done) {
      terminated[missionId] = true;
    }
  });

  const executingStates = states.filter(({ executing }) => !!executing) as IMissionStateExecuting[];

  const executingMissionId = sortTimestamp(executingStates)
    .reverse()
    .find(({ missionId }) => !terminated[missionId])?.missionId;

  if (executingMissionId) {
    const executingStatesForId = sortActionIndex(
      sortCommandIndex(executingStates.filter(({ missionId }) => missionId === executingMissionId)),
    );

    executingMission = executingStatesForId[executingStatesForId.length - 1];
  }

  return executingMission;
};

export default getExecutingMission;
