import { ActionList as ActionListType, Mission, Mission_actions as MissionActions, usePoi } from '@ats/graphql';
import { useObservable } from '@libreact/use-observable';
import LoadingSkeleton from '../LoadingSkeleton';
import PreviewActionList from './PreviewActionList';
import mergeMissionActionCommands, { IMergedCommand } from '../../model/mission/overview/mergeMissionActionCommands';
import previewMissionObservable from '../../model/observable/previewMission.observable';
import { extractRootLevelTags, extractActionLevelTags } from '../../model/mission/extractTagsData';

interface IProps {
  areaId: string;
  actionList: ActionListType;
}

const PreviewActionListContainer = (props: IProps) => {
  const { actionList, areaId } = props;
  const [pois] = usePoi({ areaId });
  const [previewMission] = useObservable(previewMissionObservable, null);

  if (!previewMission || !pois) return <LoadingSkeleton />;
  const missionTags: Mission['tags'] = extractRootLevelTags(previewMission.tags);
  const commands: ReadonlyArray<IMergedCommand> = mergeMissionActionCommands(previewMission.actions);
  const missionActionListId: Mission['actionListId'] = previewMission.actionListId;
  const missionActionTags: MissionActions['tags'][] = previewMission.actions.map(({ tags }) =>
    extractActionLevelTags(tags),
  );

  return (
    <PreviewActionList
      actionList={actionList}
      missionTags={missionTags}
      missionActionListId={missionActionListId}
      commands={commands}
      missionActionTags={missionActionTags}
      pois={pois}
    />
  );
};

export default PreviewActionListContainer;
