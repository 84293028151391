import {
  MissionState_cancelled as MissionStateCancelled,
  MissionState_failed as MissionStateFailed,
  MissionState_updateFailed as MissionStateUpdateFailed,
  MissionState_rejected as MissionStateRejected,
} from '@ats/graphql';

import { Card, CardContent, CardHeader, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { memo } from 'react';
import getPrettifiedMissionStateReason from '../../model/mission/overview/getPrettifiedMissionStateReason';
import { grey868, red500, white, dimWhite } from '../../theme/color';

interface IProps {
  missionStateOverview: {
    stateDisplayName: string;
    id: string;
    state: MissionStateCancelled | MissionStateFailed | MissionStateUpdateFailed | MissionStateRejected | null;
  };
}

export const useStyles = makeStyles(
  {
    box: {
      paddingLeft: '10px',
    },
    card: {
      boxShadow: 'none',
      borderRadius: '4px',
      borderTop: `4px solid`,
    },
    cardOuter: {
      backgroundColor: grey868,
    },
    content: {
      padding: '0px',
      '&:last-child': {
        paddingBottom: '4rem',
      },
    },

    typography: {
      color: dimWhite,
      padding: '0px 16px 0px',
    },
    typographyTitle: {
      color: white,
    },
  },
  { index: 1 },
);

const MissionStateCard = (props: IProps) => {
  const { missionStateOverview } = props;
  const { stateDisplayName, state } = missionStateOverview;
  const { card, cardOuter, content, typography, typographyTitle } = useStyles();

  return (
    <Card style={{ borderTopColor: red500 }} className={clsx(card, cardOuter)}>
      <CardHeader
        style={{ padding: '12px 16px 8px 16px' }}
        title={stateDisplayName}
        titleTypographyProps={{ className: clsx(typographyTitle, 'sdds-headline-07') }}
      />
      <CardContent className={content}>
        <Typography className={clsx(typography, 'sdds-detail-02')}>
          {getPrettifiedMissionStateReason(state?.reason)}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default memo(MissionStateCard);
