import { Poi as IPointOfInterest, usePoi } from '@ats/graphql';
import { makeStyles } from '@material-ui/core';

import { memo, useEffect } from 'react';
import { grey100, grey700, grey900 } from '../../theme/color';
import { Close } from '../icons';
import { Selected } from '../../model/type/Selected';
import PoiDisplayName from './PoiDisplayName';
import PoiPositionForm from './PoiPositionForm';
import Events from '../../model/events';

const useStyles = makeStyles(
  {
    root: {
      backgroundColor: grey900,
      borderTop: `8px solid ${grey700}`,
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100vh - 64px)',
      position: 'relative',
    },
  },
  { index: 1 },
);

interface IProps {
  areaId: string | null;
  selectedPoiId: string | null;
  setSelected: (selected: Selected) => void;
}

const PointOfInterestPanel = (props: IProps) => {
  const { selectedPoiId, setSelected, areaId } = props;
  const [pointsOfInterests] = usePoi({ areaId });

  const { root } = useStyles();

  useEffect(() => {
    if (!!selectedPoiId && pointsOfInterests?.length === 0) {
      setSelected(null);
      window.dispatchEvent(
        new CustomEvent(Events.GENERIC_ERROR, {
          detail: { msg: 'POI data failed to load' },
        }),
      );
    }
  }, [selectedPoiId, setSelected, pointsOfInterests]);

  if (!selectedPoiId || !pointsOfInterests) return null;

  if (pointsOfInterests.length === 0) {
    return null;
  }

  const selectedPoi = pointsOfInterests.find(({ id }: IPointOfInterest) => id === selectedPoiId);

  if (!selectedPoi) return null;

  return (
    <div className={root} id="PointOfInterestPanel">
      <Close
        click={() => setSelected(null)}
        style={{
          position: 'absolute',
          color: grey100,
          top: '24px',
          left: '10px',
          width: '40px',
          height: '40px',
          cursor: 'pointer',
        }}
      />
      <PoiDisplayName pointOfInterest={selectedPoi} />
      <PoiPositionForm pointOfInterest={selectedPoi} areaId={areaId} />
    </div>
  );
};

export default memo(PointOfInterestPanel);
