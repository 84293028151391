import { SvgIcon, Tooltip } from '@material-ui/core';
import { ICommonIconProps, useIconStyles } from './CommonIconProps';
import { white } from '../../theme/color';

interface IProps extends ICommonIconProps {
  heading: number | null | undefined;
  color: string;
}
export const Direction = ({ heading, className, color, testid }: IProps) => {
  const iconStyles = useIconStyles();
  return (
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={<span className={iconStyles.tooltipTypography}>Heading: {heading}&deg;</span>}
    >
      <SvgIcon
        width="48"
        height="48"
        viewBox="0 0 48 48"
        transform={`rotate(${heading ? parseFloat(`${heading}`).toFixed() : 0}) scale(2.1)`}
        fill="none"
        className={className}
        data-testid={testid}
      >
        <circle cx="24" cy="24" r="24" fill={color} />
        <path d="M24 13.5L15 31.5L24 27L33 31.5L24 13.5Z" fill={white} />
      </SvgIcon>
    </Tooltip>
  );
};
export default Direction;
