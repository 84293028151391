import { Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';

import Events from '../../model/events';

const PreviewStateInfoBox = () => {
  const [content, setContent] = useState(null);

  const { message } = content || {};

  const callback = ({ detail }) => {
    setContent({ message: detail.message });
  };

  useEffect(() => {
    window.addEventListener(Events.PREVIEW_LOAD, callback);
    return () => window.removeEventListener(Events.PREVIEW_LOAD, callback);
  }, []);

  return <Typography className="sdds-detail-03">{message}</Typography>;
};

export default PreviewStateInfoBox;
