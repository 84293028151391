import { omit } from 'ramda';

const isEqualShallow = (a, b) => {
  let differenceFound = false;
  Object.keys(a).forEach((key) => {
    if (a[key] !== b[key]) {
      differenceFound = true;
    }
  });
  return !differenceFound;
};

export const identicalIVehicle = (vehicleA, vehicleB) => {
  let differenceFound = false;
  Object.keys(vehicleA).forEach((key) => {
    if (['position', 'releaseConditions', 'timestamp'].includes(key)) {
      // These are the 'special' cases, either deep structure or timestamp
      switch (key) {
        case 'position':
          if (!isEqualShallow(vehicleA.position, vehicleB.position)) {
            differenceFound = true;
          }
          break;
        case 'releaseConditions':
          vehicleA.releaseConditions.forEach((condition, index) => {
            const comparee = vehicleB.releaseConditions[index];
            if (comparee && !isEqualShallow(condition, comparee)) {
              differenceFound = true;
            }
          });
          break;
        case 'timestamp':
          if (vehicleA.timestamp !== vehicleB.timestamp) {
            // This handles the case where timestamps have been omitted, meaning it will think the vehicles are identical
            differenceFound = true;
          }
          break;
        default:
          break;
      }
    } else if (vehicleA[key] !== vehicleB[key]) {
      // Shallow comparing of current key
      differenceFound = true;
    }
  });
  return !differenceFound;
};

export const isEqualOmitEquipmentTimestamps = (prevProps, props) => {
  const lengthA = prevProps.equipmentStatuses && prevProps.equipmentStatuses.length;
  const lengthB = props.equipmentStatuses && props.equipmentStatuses.length;

  if (lengthA !== lengthB) {
    // One is null and the other is an array (common at loading) or they have different amounts of vehicles
    return false;
  }

  let differenceFound = false;
  if (prevProps.equipmentStatuses && props.equipmentStatuses) {
    prevProps.equipmentStatuses.forEach((vehicle, idx) => {
      const propsA = omit(['timestamp'], vehicle);
      const propsB = omit(['timestamp'], props.equipmentStatuses[idx]);
      if (!identicalIVehicle(propsA, propsB)) {
        differenceFound = true;
      }
    });
  }

  const propsWithoutEquipmentStatusesA = omit(['equipmentStatuses'], prevProps);
  const propsWithoutEquipmentStatusesB = omit(['equipmentStatuses'], props);
  if (!isEqualShallow(propsWithoutEquipmentStatusesA, propsWithoutEquipmentStatusesB)) {
    differenceFound = true;
  }

  return !differenceFound;
};
