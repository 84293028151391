import { ReleaseConditionType } from '@ats/graphql';
import type { EquipmentStatus } from '@ats/graphql';
import { IUserAttributes } from '../../user';

const isInStandDownPersonalByUser = ({ releaseConditions }: EquipmentStatus, user: IUserAttributes): boolean =>
  !!releaseConditions?.find(
    (releaseCondition) =>
      releaseCondition?.type === ReleaseConditionType.TYPE_PERSONAL &&
      releaseCondition?.owner === user?.customer?.externalStaffReference,
  );

export default isInStandDownPersonalByUser;
