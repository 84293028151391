import './lightningjs-embed';

export type Module = (event: string) => void;

export interface ILightningJs {
  require(moduleName: string, url: string): Module;
}

const { lightningjs } = window as Window & typeof globalThis & { lightningjs: ILightningJs };

export default lightningjs;
