import GeoJSON from 'ol/format/GeoJSON';
import { boundingExtent, buffer } from 'ol/extent';
import geoJsonObservable from '../../model/map/observable/geoJson.observable';

export default function zoomAndCenterToContent(map) {
  geoJsonObservable.subscribe((geoJson) => {
    if (!geoJson) return;

    const coordinates = new GeoJSON({
      featureProjection: 'EPSG:3857',
    })
      .readFeatures(geoJson)
      .map((feature) => feature.getGeometry().getExtent());

    map.getView().fit(buffer(boundingExtent(coordinates), 500), { size: map.getSize(), maxZoom: 16 });
  });
}
