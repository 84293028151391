import { makeStyles } from '@material-ui/core';
import { grey846 } from '../../theme/color';

const useControlIconStyles = makeStyles(
  {
    button: {
      position: 'absolute',
      top: '-25px',
      borderRadius: '4px',
      border: 'none',
      minWidth: '28px',
      height: '28px',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: grey846,
        border: 'none',
      },
      '&:disabled': {
        opacity: 0.4,
      },
      '&:focus': {
        border: 'none',
      },
    },
    activeStyle: {
      backgroundColor: grey846,
    },
  },
  { index: 1 },
);

export default useControlIconStyles;
