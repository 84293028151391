import { SvgIcon } from '@material-ui/core';
import { ICommonIconProps } from './CommonIconProps';

export const PaddockIcon = ({ className }: ICommonIconProps) => (
  <SvgIcon
    width="56"
    height="56"
    viewBox="4 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g filter="url(#filter0_d_905_30195)">
      <circle cx="32" cy="28" r="27.5" fill="white" stroke="#0D0F13" />
      <path
        d="M48.2457 22.3005C48.098 21.4638 47.409 20.8567 46.5723 20.8075L37.4504 20.3153L36.7777 22.5138V30.6513H15.9582V31.8161V32.2919V35.9013H20.5355C20.7816 36.7216 21.4051 37.3778 22.209 37.706C22.2746 37.7388 22.3402 37.7552 22.4058 37.7716C22.6683 37.8536 22.9637 37.9028 23.259 37.9028C24.5223 37.9028 25.5887 37.0661 25.9496 35.9177C26.0316 35.6388 26.0808 35.3599 26.0808 35.0482C26.0808 34.7692 26.0316 34.4903 25.966 34.2278L27.6394 35.9013H33.7098H34.5301H38.7793C39.1238 37.0497 40.2066 37.9028 41.4699 37.9028C43.0285 37.9028 44.2918 36.6396 44.2918 35.081C44.2918 33.5224 43.0285 32.2591 41.4699 32.2591C40.2066 32.2591 39.1238 33.0958 38.7793 34.2607H34.5301V32.2755H37.3355L37.7293 33.2927H38.7301L38.9762 32.9974C39.5996 32.2591 40.5019 31.8325 41.4535 31.8325C43.2418 31.8325 44.7019 33.2927 44.7019 35.081V36.3935H49.1152V27.3044L48.2457 22.3005ZM20.5355 34.2607H17.5988V32.2919H22.6355C21.6183 32.5216 20.8144 33.2763 20.5355 34.2607ZM23.2918 36.2622C22.6355 36.2622 22.1105 35.7372 22.1105 35.081C22.1105 34.4247 22.6355 33.8997 23.2918 33.8997C23.948 33.8997 24.473 34.4247 24.473 35.081C24.473 35.7372 23.9316 36.2622 23.2918 36.2622ZM29.8051 34.2771H28.3285L26.3433 32.2919H29.8051V34.2771ZM32.8894 34.2771H31.4457V32.2919H32.8894V34.2771ZM41.4699 33.9161C42.1262 33.9161 42.6512 34.4411 42.6512 35.0974C42.6512 35.7536 42.1262 36.2786 41.4699 36.2786C40.8137 36.2786 40.2887 35.7536 40.2887 35.0974C40.2887 34.4411 40.8301 33.9161 41.4699 33.9161ZM47.4254 27.1403L41.716 26.3036L41.109 24.8927L41.191 24.3185L46.9496 24.4333L47.4254 27.1403ZM47.4746 31.3075H46.5394V34.0802L47.4746 34.3099V34.7692H46.3426C46.1785 32.2263 44.0457 30.2083 41.4699 30.2083C40.4363 30.2083 39.4519 30.5364 38.6316 31.1107L38.3855 30.4872V22.7599L38.6152 22.0216L46.4738 22.4317C46.5559 22.4317 46.6051 22.4974 46.6215 22.563L46.6543 22.7927L39.7637 22.645L39.4027 25.106L40.5512 27.7802L47.4582 28.7974V31.3075H47.4746Z"
        fill="#0D0F13"
      />
      <path
        d="M27.377 15.75L14.5 28.627L16.9362 31.0632L29.8131 18.1862L27.377 15.75Z"
        stroke="#0D0F13"
        strokeMiterlimit="10"
      />
      <path d="M34.0213 31.1597L25.6541 22.7925" stroke="#0D0F13" strokeMiterlimit="10" />
    </g>
    <defs>
      <filter
        id="filter0_d_905_30195"
        x="0"
        y="0"
        width="64"
        height="64"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_905_30195" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_905_30195" result="shape" />
      </filter>
    </defs>
  </SvgIcon>
);

export default PaddockIcon;
