import { t } from 'ttag';
import { DegradationPlanReleaseCondition } from '@ats/graphql';

const getReleaseConditionLabel = (releaseCondition: DegradationPlanReleaseCondition) => {
  switch (releaseCondition) {
    case DegradationPlanReleaseCondition.InVehicle:
      return t`In-vehicle`;
    case DegradationPlanReleaseCondition.Offboard:
      return t`Off-board`;
    case DegradationPlanReleaseCondition.Auto:
      return t`Auto`;
    default:
      return t`Unknown`;
  }
};
export default getReleaseConditionLabel;
