import { useEffect, useRef, useState } from 'react';

/**
 * Force React to re-render a component after a specified time.
 *
 * @param timestamp - Timestamp after which React should re-render the component.
 */
const useRenderAfterTime = (timestamp: number | null) => {
  const [counter, setCounter] = useState(0);
  const timeout = useRef<NodeJS.Timeout>();

  const clear = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  };

  useEffect(() => {
    clear();

    if (timestamp) {
      timeout.current = setTimeout(() => {
        setCounter(counter + 1);
      }, Math.max(0, Date.now() - timestamp + 1));
    }

    return clear;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timestamp]);
};

export default useRenderAfterTime;
