import { useState, useEffect } from 'react';
import { lift, divide, sum, length } from 'ramda';
import { GetEquipmentVariables } from '@ats/graphql';
import IVehicle from '../../model/vehicle/IVehicle';
import useVehicles from '../../model/vehicle/useVehicles';
import { black, grey100, red, yellow } from '../../theme/color';

function timeoutColor(diff: number, darkMode: boolean): string {
  if (diff > 15000) {
    return red;
  }
  if (diff > 5000) {
    return yellow;
  }
  if (darkMode) {
    return grey100;
  }
  return black;
}

function formatLabel(time: number): string {
  if (time > 60000 * 60 * 24) {
    const day = time / 1000 / 60 / 60 / 24;
    return `${day.toFixed(0)} d`;
  }
  if (time > 60000 * 60) {
    const hour = time / 1000 / 60 / 60;
    return `${hour.toFixed(0)} h`;
  }
  if (time > 60000) {
    const minute = time / 1000 / 60;
    return `${minute.toFixed(1)} m`;
  }
  const second = time / 1000;
  return `${second.toFixed(0)} s`;
}

interface IProps {
  externalEquipmentReference: IVehicle['externalEquipmentReference'];
  areaId: IVehicle['areaId'];
  darkMode?: boolean;
}
const VehicleDataAgeLabel = (props: IProps) => {
  const { areaId, externalEquipmentReference, darkMode = false } = props;
  const [latestValues, setLatestValues] = useState<number[]>([]);

  const filter: GetEquipmentVariables = {
    externalEquipmentReference,
    areaId,
  };
  const [equipmentStatuses] = useVehicles(filter);

  const vehicle: IVehicle | null =
    equipmentStatuses?.find((v: IVehicle) => v.externalEquipmentReference === externalEquipmentReference) ?? null;

  // Setup a time-out for getting the average data age and save it to the state, this will then cause a re-render of the component
  useEffect(() => {
    const timer: ReturnType<typeof setInterval> = setInterval(() => {
      let dataAge = 0;

      // Given that we got a vehicle and it's timestamp, calculate the age of the vehicle data and add it to the calculator class
      if (vehicle && vehicle.timestamp) {
        const timestamp: IVehicle['timestamp'] = vehicle?.timestamp;

        // TODO: We might have to review this calculation as it depends on the browser time to be in-sync with the back-end source -
        // - from where we get the vehicle data timestamp.
        // const dataAge: number = new Date().getTime() - new Date(timestamp).getTime();

        dataAge = new Date().getTime() - new Date(timestamp).getTime();
      }

      // Keep track of the (up to) 10 latest values to be able to calculate an average
      const tmpArray: number[] = latestValues;
      tmpArray.push(dataAge);
      if (tmpArray.length > 10) tmpArray.shift();
      setLatestValues(tmpArray);
    }, 200);
    return () => {
      clearInterval(timer);
    };
  });

  const averageCalculator = lift(divide)(sum, length);
  const averageDataAge = averageCalculator(latestValues);

  // At first render we might not have the data
  if (!averageDataAge || Number.isNaN(averageDataAge)) {
    return null;
  }

  // If average data age is a valid number but below 500 ms it would be displayed as '0 s'
  // Show an empty span instead (provides testability)
  if (averageDataAge < 500.0) {
    return <span data-testid="vehicleDataAge" data-test-average-value={averageDataAge} />;
  }

  const averageDataAgeStr = formatLabel(averageDataAge);

  return (
    <span
      style={{ whiteSpace: 'nowrap', color: timeoutColor(averageDataAge, darkMode) }}
      data-testid="vehicleDataAge"
      data-test-average-value={averageDataAge}
    >
      {`${averageDataAgeStr}`}
    </span>
  );
};

export default VehicleDataAgeLabel;
