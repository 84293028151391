import { getEquipmentStatuses } from '@ats/graphql';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import geoJsonObservable from './geoJson.observable';
import getRoutePreview from '../route/preview/getRoutePreview';
import previewMissionObservable from '../../observable/previewMission.observable';

const routePreviewObservable = previewMissionObservable.pipe(
  switchMap((mission) =>
    mission
      ? combineLatest([
          getEquipmentStatuses({ externalEquipmentReference: mission.externalEquipmentReference }),
          geoJsonObservable,
        ]).pipe(map(([[status], geoJson]) => getRoutePreview(mission, status || null, geoJson)))
      : of([]),
  ),
);

export default routePreviewObservable;
