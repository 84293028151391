import { offlineTimeMs } from './isOnline';
import IVehicle from './IVehicle';

const getNextOfflineTime = (vehicles: ReadonlyArray<IVehicle> | null): number | null => {
  let nextOfflineTime: number | null = null;

  if (vehicles) {
    vehicles.forEach(({ online, timestamp }) => {
      if (online && timestamp) {
        const time = new Date(timestamp).getTime() + offlineTimeMs;

        if (!nextOfflineTime || time < nextOfflineTime) {
          nextOfflineTime = time;
        }
      }
    });
  }

  return nextOfflineTime;
};

export default getNextOfflineTime;
