import { useState, useEffect, useRef } from 'react';
import { useObservable } from '@libreact/use-observable';
import {
  selectedVehicleId as selectedVehicleIdObservable,
  missionViewContext as missionViewContextObservable,
} from '../model/observables';
import { MissionView } from '../model/ViewTypes';

interface IProps {
  (): [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

const useCameraView: IProps = () => {
  const [cameraView, setCameraView] = useState(false);
  const [selectedEquipmentId] = useObservable(selectedVehicleIdObservable);
  const [missionViewContext] = useObservable(missionViewContextObservable);
  const prevSelectedEquipmentId = useRef<string | null>();

  useEffect(() => {
    if (!cameraView) return;
    if (selectedEquipmentId === null || prevSelectedEquipmentId.current !== selectedEquipmentId) {
      setCameraView(false);
    }
  }, [selectedEquipmentId, cameraView, setCameraView]);

  useEffect(() => {
    prevSelectedEquipmentId.current = selectedEquipmentId;
  }, [selectedEquipmentId]);

  useEffect(() => {
    const displayCameraViewButton =
      missionViewContext === MissionView.DefaultView ||
      missionViewContext === MissionView.MissionState ||
      missionViewContext === MissionView.ActiveMission;

    if (!displayCameraViewButton) {
      setCameraView(false);
    }
  }, [missionViewContext]);

  return [cameraView, setCameraView];
};

export default useCameraView;
