import { IGraphqlGlobals /* , SiteRelation_queueRelations as SiteRelationQueueRelations */ } from '@ats/graphql';
import {
  selectedVehicleId,
  selectedAreaId,
  selectedZoneId,
  selectedQueueId,
  selectedPaddockId,
} from '../model/observables';

//
// Expose a number of observables in the window object to ease debugging and testing
//
interface IExposedStates {
  selectedAreaId: string | null | undefined;
  selectedVehicleId: string | null | undefined;
  selectedQueueId: string | null | undefined;
  selectedPaddockId: string | null | undefined;
  // selectedZone: SiteRelationQueueRelations | null | undefined;
  selectedZoneId: string | null | undefined;
}

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    ui: {
      states: IExposedStates;
    };
    // Expose the graphql globals so that debug functions can be used from code if wanted
    graphql: IGraphqlGlobals;
  }
}

window.ui = window.ui || {
  states: {
    selectedAreaId: undefined,
    selectedVehicleId: undefined,
    selectedQueueId: undefined,
    selectedPaddockId: undefined,
    selectedZoneId: undefined,
  } as IExposedStates,
};

selectedVehicleId.subscribe((id) => {
  window.ui.states.selectedVehicleId = id;
});

selectedAreaId.subscribe((id) => {
  window.ui.states.selectedAreaId = id;
});

selectedQueueId.subscribe((id) => {
  window.ui.states.selectedQueueId = id;
});

selectedPaddockId.subscribe((id) => {
  window.ui.states.selectedPaddockId = id;
});

selectedZoneId.subscribe((id) => {
  window.ui.states.selectedZoneId = id;
});
