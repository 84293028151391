import { Mission, Poi, MissionState, ActionList, Mission_actions as MissionActions } from '@ats/graphql';
import IMissionOverview from './IMissionOverview';
import getStartTime from './getStartTime';
import getCommandLabel from './getCommandLabel';
import IMissionStateExecuting from '../IMissionStateExecuting';
import getExecutingMission from '../getExecutingMission';
import mergeMissionActionCommands, { IMergedCommand } from './mergeMissionActionCommands';
import { extractRootLevelTags, extractActionLevelTags } from '../extractTagsData';

interface IParameters {
  (
    actionLists: ReadonlyArray<ActionList> | null,
    missions: ReadonlyArray<Mission> | null,
    pois: ReadonlyArray<Poi> | null,
    states: ReadonlyArray<MissionState> | null,
  ): IMissionOverview | null;
}
const getMissionOverview: IParameters = (actionLists, missions, pois, states) => {
  if (!actionLists || !missions || !pois || !states) return null;

  // Get the currently executing mission state
  const executingMissionState: IMissionStateExecuting | null = getExecutingMission(states);
  if (!executingMissionState) {
    return null;
  }
  // Get the currently executing mission
  const executingMission: Mission | undefined = missions.find(({ id }) => id === executingMissionState.missionId);
  if (!executingMission) {
    return null;
  }

  const missionTags = extractRootLevelTags(executingMission.tags);

  // Get all commands from all actions in the current mission combined to a single array of commands
  const mergedCommands: ReadonlyArray<IMergedCommand> = mergeMissionActionCommands(executingMission.actions);
  if (mergedCommands.length === 0) {
    return null;
  }
  // Get all tags from all actions in the current mission combined to a single array of tags
  const missionActionTags: MissionActions['tags'][] = executingMission.actions.map(({ tags }) =>
    extractActionLevelTags(tags),
  );

  // Get the index of the currently executing command
  const currentlyExecutingCommandIndex: number = mergedCommands.findIndex(
    ({ actionIndex, commandIndex }) =>
      actionIndex === executingMissionState.executing.actionIndex &&
      commandIndex === executingMissionState.executing.commandIndex,
  );

  // This shouldn't be possible - perhaps when dequeueing a vehicle!? (check CAV-37301)
  if (currentlyExecutingCommandIndex === -1) {
    return null;
  }

  const missionLabel: string | undefined =
    missionTags && missionTags.displayText !== undefined
      ? missionTags.displayText
      : actionLists?.find(({ id }) => id === executingMission.actionListId)?.displayName;

  const previousMissionActionTags =
    executingMissionState.executing.actionIndex > 0 &&
    missionActionTags[executingMissionState.executing.actionIndex - 1];

  const previousActionTagInfo =
    previousMissionActionTags.displayText !== undefined
      ? {
          label: previousMissionActionTags.displayText,

          // For the start time we want the timestamp for the action before the currently executing one, but for the first tag (not the last as with the label)
          startTime: getStartTime(executingMission, executingMissionState.executing.actionIndex - 1, states),
        }
      : null;

  const previousActionCommandInfo =
    currentlyExecutingCommandIndex > 0
      ? {
          label:
            getCommandLabel(
              executingMission.actionListId,
              // Use the last command in the action before the currently executing action
              executingMission.actions[mergedCommands[currentlyExecutingCommandIndex - 1].actionIndex].commands[
                mergedCommands[currentlyExecutingCommandIndex - 1].commandIndex
              ],
              pois,
            ) ?? 'Unknown',
          // For the start time we want the timestamp for the action before the currently executing one, but for the first command (not the last as with the label)
          startTime: getStartTime(
            executingMission,
            mergedCommands[currentlyExecutingCommandIndex - 1].actionIndex,
            states,
            mergedCommands[currentlyExecutingCommandIndex - 1].commandIndex,
          ),
        }
      : null;

  const currentMissionActionTags = missionActionTags[executingMissionState.executing.actionIndex];

  const currentActionTagInfo =
    currentMissionActionTags.displayText !== undefined
      ? {
          label: currentMissionActionTags.displayText,
          startTime: getStartTime(executingMission, executingMissionState.executing.actionIndex, states),
        }
      : null;
  const currentActionCommandInfo = {
    label:
      getCommandLabel(
        executingMission.actionListId,
        executingMission.actions[mergedCommands[currentlyExecutingCommandIndex].actionIndex].commands[
          mergedCommands[currentlyExecutingCommandIndex].commandIndex
        ],
        pois,
      ) ?? 'Unknown',
    startTime: getStartTime(
      executingMission,
      mergedCommands[currentlyExecutingCommandIndex].actionIndex,
      states,
      mergedCommands[currentlyExecutingCommandIndex].commandIndex,
    ),
  };

  const nextMissionActionTags =
    executingMissionState.executing.actionIndex < missionActionTags.length - 1 &&
    missionActionTags[executingMissionState.executing.actionIndex + 1];

  const nextActionTagInfo =
    nextMissionActionTags.displayText !== undefined
      ? {
          label: nextMissionActionTags.displayText,
        }
      : null;
  const nextActionCommandInfo =
    currentlyExecutingCommandIndex < mergedCommands.length - 1
      ? {
          label:
            getCommandLabel(
              executingMission.actionListId,
              executingMission.actions[mergedCommands[currentlyExecutingCommandIndex + 1].actionIndex].commands[
                mergedCommands[currentlyExecutingCommandIndex + 1].commandIndex
              ],
              pois,
            ) ?? 'Unknown',
        }
      : null;

  const moreTags = executingMissionState.executing.actionIndex < missionActionTags.length - 2;
  const moreCommands = currentlyExecutingCommandIndex < mergedCommands.length - 2;

  // actionListId does not exist on ClickAndDrive missions
  const clickAndDriveLabel: string = !executingMission.actionListId ? 'Click and Drive' : '';

  const missionOverview: IMissionOverview = {
    label: missionLabel || clickAndDriveLabel || 'Mission',
    // Previous command, if available
    previous: previousActionTagInfo || previousActionCommandInfo,
    // Current command
    current: currentActionTagInfo || currentActionCommandInfo,
    // Next command if there are commands after the currently executing one in the array
    next: nextActionTagInfo || nextActionCommandInfo,
    // Do we have even more tags/commands in the queue?
    more: moreTags || moreCommands,
  };

  return missionOverview;
};
export default getMissionOverview;
