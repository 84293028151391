import { Button, Card, CardActions, CardHeader, makeStyles, Divider } from '@material-ui/core';
import clsx from 'clsx';
import { useEffect } from 'react';
import { t } from 'ttag';
import { blue400, blue500, blue600, grey868, white } from '../../theme/color';
import { useCardStyles } from './Card.styles';

const useStyles = makeStyles(
  {
    button: {
      padding: '20px',
      textTransform: 'none',
      '&:disabled': {
        color: 'rgba(255, 255, 255, 0.38)',
        backgroundColor: grey868,
      },
    },
    buttonToIdle: {
      border: 'none',
      backgroundColor: blue600,
      color: white,
      '&:hover': {
        backgroundColor: blue500,
        border: 'none',
      },
      '&:focus': {
        backgroundColor: blue400,
        border: 'none',
      },
      '&:disabled': {
        color: white,
      },
    },
  },
  { index: 1 },
);

interface IPropsDefaultView {
  canAssignMission: boolean;
  assignMission: () => void;
  renderReleaseComponent: () => JSX.Element | null;
  vehicleMode: string | null;
  setRenderButton: (render: boolean) => void;
  dispatcherRight: boolean;
}
const DefaultView = ({
  canAssignMission,
  assignMission,
  renderReleaseComponent,
  vehicleMode,
  setRenderButton,
  dispatcherRight,
}: IPropsDefaultView) => {
  const { button, buttonToIdle } = useStyles();
  const { card, cardHeader, cardHeaderTypography, cardActions, noBackground } = useCardStyles();

  const isDispatchable = vehicleMode === 'Autonomous' || vehicleMode === 'Shadow';

  useEffect(() => {
    setRenderButton(true);
  }, [setRenderButton]);

  return (
    <Card className={clsx(card, noBackground)}>
      <CardHeader
        className={cardHeader}
        title={t`No Mission`}
        titleTypographyProps={{ className: clsx(cardHeaderTypography, 'sdds-headline-04') }}
        data-testid="no-mission"
      />
      <Divider />
      <CardActions className={cardActions}>
        {canAssignMission ? (
          <Button
            className={clsx(button, buttonToIdle, 'sdds-detail-02')}
            fullWidth
            disableRipple
            onClick={assignMission}
            disabled={!isDispatchable}
            data-testid="assign-mission-button"
          >
            {dispatcherRight ? t`Assign mission` : t`View missions`}
          </Button>
        ) : null}
        {renderReleaseComponent()}
      </CardActions>
    </Card>
  );
};

export default DefaultView;
