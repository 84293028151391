import { ChangeEvent, useState } from 'react';
import { useMockedTelemetry } from '@ats/graphql';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Grid } from '@material-ui/core';
import { grey100, grey500, grey900 } from '../../theme/color';
import InfoCard from './telemetry/InfoCard';
import { FuelLevelIcon, FuelRateIcon } from '../icons/InformationClusterIcons';
import prettyText from '../../model/prettyText';
import theme from '../../theme/theme';
import TriggerLog from './developerTab/TriggerLog';

const getTelemetryIcon = (name: string) => {
  switch (name) {
    case 'fuel_rate':
      return <FuelRateIcon />;
    case 'fuel_level':
      return <FuelLevelIcon />;
    default:
      return <></>;
  }
};

interface IProps {
  areaId: string;
  externalEquipmentReference: string;
}

const useStyles = makeStyles(
  {
    root: {
      zIndex: 1300,
      paddingLeft: theme.spacing(8),
      overflow: 'hidden',
    },
    tabs: {
      color: grey500,
      '& button:focus': { color: grey100 },
      margin: theme.spacing(0, 12, 4, 4),
      height: '64px',
      '& .MuiTabs-flexContainer': {
        height: '64px',
      },
    },
    scrollContainer: {
      '&::-webkit-scrollbar': {
        color: 'transparent',
        width: '4px',
      },
      '&:hover': {
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '4px',
          backgroundColor: grey900,
        },
      },
      height: 'calc(100% - 84px)',
      paddingRight: theme.spacing(4),
      margin: theme.spacing(0, 5, 0, 4),
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      overflowX: 'hidden',
      flexBasis: 'auto',
      flexGrow: 1,
      flexShrink: 1,
    },
  },
  { index: 1 },
);

const InformationCluster = (props: IProps) => {
  const { areaId, externalEquipmentReference } = props;
  const { root, tabs, scrollContainer } = useStyles();
  const [value, setValue] = useState(0);
  const [telemetry] = useMockedTelemetry({ externalEquipmentReference, areaId });

  if (!telemetry || !telemetry[0].scopeMetrics) return null;

  const hydratedMetrics = [
    ...telemetry[0].scopeMetrics[0].metrics.filter(({ name }) => name !== 'heading' && name !== 'speed'),
    { name: 'Distance to empty', unit: 'km', gauge: { dataPoints: [{ asInt: 5 }] } },
    { name: 'Load estimate', unit: 'kg', gauge: { dataPoints: [{ asInt: 5 }] } },
    { name: 'Prime mover temperature', unit: '° C', gauge: { dataPoints: [{ asInt: 5 }] } },
    { name: 'Engine hours', unit: 'hours', gauge: { dataPoints: [{ asInt: 5 }] } },
    { name: 'Tire pressure', unit: 'PSI', gauge: { dataPoints: [{ asInt: 5 }] } },
    { name: 'Gear selector', unit: 'Drive', gauge: { dataPoints: [{ asInt: 5 }] } },
  ];

  const handleChange = (_event: ChangeEvent<Record<string, unknown>>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={root}>
      <Tabs value={value} onChange={handleChange} aria-label="vehicle information" className={tabs}>
        <Tab label="Telemetry" id="1" data-testid="telemetry-tab" />
        <Tab label="Developer tab" id="2" data-testid="developer-web-tab" />
      </Tabs>
      <div className={scrollContainer}>
        {value === 0 && (
          <Grid container spacing={8}>
            {hydratedMetrics.map(({ name, gauge, unit }) => (
              <Grid item key={name}>
                <InfoCard
                  title={prettyText(name) || ''}
                  description={`${gauge?.dataPoints[0].asInt} ${unit}`}
                  icon={getTelemetryIcon(name)}
                />
              </Grid>
            ))}
          </Grid>
        )}

        {value === 1 && (
          <Grid container spacing={8}>
            <Grid item key="onboardLog">
              <TriggerLog externalEquipmentReference={externalEquipmentReference} />
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
};

export default InformationCluster;
