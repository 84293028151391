import { Mission, Mission_actions as MissionActions, ActionList } from '@ats/graphql';

export const isValidJSON = (text: string) => {
  try {
    JSON.parse(text);
    return true;
  } catch {
    return false;
  }
};

export const extractRootLevelTags = (tags: Mission['tags'] | ActionList['tags']) =>
  tags && isValidJSON(tags) ? JSON.parse(tags) : null;

export const extractActionLevelTags = (tags: MissionActions['tags']) =>
  tags && isValidJSON(tags) ? JSON.parse(tags) : {};
