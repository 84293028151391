import {
  useEquipment,
  useEquipmentStatuses,
  GetEquipmentVariables,
  Equipment,
  getEquipment,
  getEquipmentStatuses,
  EquipmentStatus,
} from '@ats/graphql';
import { Subscription } from 'rxjs';
import { selectedAreaId } from '../observables';
import getNextOfflineTime from './getNextOfflineTime';
import IVehicle from './IVehicle';
import mergeVehicles from './mergeVehicles';
import useRenderAfterTime from './useRenderAfterTime';

const useVehicles = (filters: GetEquipmentVariables): [ReadonlyArray<IVehicle> | null, unknown] => {
  const [equipments, errorEquipments] = useEquipment(filters);
  const [statuses, errorStatuses] = useEquipmentStatuses(filters);
  const vehicles = filters.areaId ? mergeVehicles(equipments, statuses) : null;

  useRenderAfterTime(getNextOfflineTime(vehicles));

  return [vehicles, errorEquipments || errorStatuses];
};

export default useVehicles;

const byId = (id: string) => ({ externalEquipmentReference }: Equipment | EquipmentStatus) =>
  id === externalEquipmentReference;

/*
  Help function to get the display name from a vehicle id (externalEquipmentReference)
*/
export const getDisplayName = (() => {
  let equipment: Equipment[] | null = null;
  let subscription: Subscription | null = null;
  selectedAreaId.subscribe((areaId: string | null) => {
    subscription?.unsubscribe();
    if (!areaId) return;
    subscription = getEquipment({ areaId }).subscribe((equipmentPrime) => {
      equipment = [...equipmentPrime];
    });
  });
  return (id: string) => equipment?.find(byId(id))?.displayName ?? 'Unknown vehicle';
})();

/*
  Help function to get the timestamp from a vehicle id (externalEquipmentReference)
*/
export const getTimestamp = (() => {
  let equipment: EquipmentStatus[] | null = null;
  let subscription: Subscription | null = null;
  selectedAreaId.subscribe((areaId: string | null) => {
    subscription?.unsubscribe();
    if (!areaId) return;
    subscription = getEquipmentStatuses({ areaId }).subscribe((equipmentPrime) => {
      equipment = [...equipmentPrime];
    });
  });
  return (id: string) => equipment?.find(byId(id))?.timestamp ?? null;
})();
