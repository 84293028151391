import { makeStyles } from '@material-ui/core';
import { red400, red500, red600, grey700, red, white, semiDimWhite } from '../../theme/color';

export const useCardStyles = makeStyles(
  {
    card: {
      boxShadow: 'none',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      flexShrink: 0,
      flexBasis: 'auto',
    },
    cardHeader: {
      marginLeft: '24px',
      marginRight: '24px',
      padding: '24px 0 0',
    },
    cardHeaderTypography: {
      color: semiDimWhite,
      opacity: 1,
    },
    action: {
      marginTop: '0px',
    },
    cardContent: {
      flexGrow: 1,
      margin: '0px 4px 16px',
      padding: '0 20px',
    },
    cardActionsError: {
      color: red,
      padding: '0 24px 8px',
    },
    cardActions: {
      position: 'fixed',
      bottom: '0',
      padding: '24px',
      width: '368px',
    },
    button: {
      padding: '20px',
      textTransform: 'none',
    },
    buttonToStandDown: {
      border: 'none',
      background: red600,
      color: white,
      '&:hover': {
        backgroundColor: red500,
        border: 'none',
      },
      '&:focus': {
        background: red400,
        border: 'none',
      },
      '&:disabled': {
        color: white,
      },
    },
    circularProgress: {
      color: 'inherit',
      marginRight: '20px',
    },
    childOfContent: {
      margin: '24px 0px 17px 0px',
    },
    noBackground: {
      backgroundColor: 'unset',
    },
  },
  { index: 1 },
);

export const useCardErrorStyles = makeStyles(
  {
    cardHeaderError: {
      borderBottom: `1px solid ${grey700}`,
      marginLeft: '24px',
      marginRight: '24px',
      padding: '8px 0',
    },
    cardHeaderErrorTypography: {
      color: red,
      textAlign: 'right',
    },
  },
  { index: 1 },
);
