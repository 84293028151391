import { Mission, Mission_actions_commands as MissionActionsCommands, Poi } from '@ats/graphql';
import getClosestPoiName from './getClosestPoiName';

const getCommandDestinationName = (
  missionActionId: Mission['actionListId'],
  command: MissionActionsCommands,
  pois: ReadonlyArray<Poi> | null,
): string | null => {
  if (!command.drive || !pois || !missionActionId) return null;

  const commandDrivePoints = command.drive.points;
  return getClosestPoiName(
    pois,
    commandDrivePoints.length >= 1 ? commandDrivePoints[commandDrivePoints.length - 1].position : null,
  );
};

export default getCommandDestinationName;
