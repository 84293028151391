import { SvgIcon } from '@material-ui/core';
import { ICommonIconProps } from './CommonIconProps';

export const DashedLine = ({ className }: ICommonIconProps) => (
  <SvgIcon
    width="52"
    height="1"
    viewBox="0 0 52 1"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <line y1="0.5" x2="52" y2="0.5" fill="white" stroke="white" strokeDasharray="4 4" />
  </SvgIcon>
);

export default DashedLine;
