import { Card, CardContent, Link, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { grey868, grey600, highEmphasisLightTheme, highEmphasisDarkTheme, blue500 } from '../../theme/color';
import { ICommonIconProps, useIconStyles } from '../icons/CommonIconProps';
import NotificationDisplayType from '../../model/notification/NotificationDisplayType';

interface IProps extends ICommonIconProps {
  notificationDisplayType: NotificationDisplayType;
  label: string | null;
  color: string;
  description: string | null;
  onClick?:
    | (React.MouseEventHandler<HTMLAnchorElement> &
        React.MouseEventHandler<HTMLSpanElement> &
        React.MouseEventHandler<HTMLButtonElement>)
    | undefined;
  timestamp: Date;
  className?: string;
  read: boolean;
}

export const useStyles = makeStyles({
  card: {
    boxShadow: 'none',
    width: '395px',
    height: '96px',
    backgroundColor: grey868,
    borderTop: `1px solid ${grey600}`,
    cursor: 'pointer',
  },
  cardContent: {
    position: 'relative',
    paddingBottom: '6rem',
    '&:last-child': {
      paddingBottom: '4rem',
    },
    paddingTop: 0,
  },
  cardWhenRead: {
    boxShadow: 'none',
    minWidth: '395px',
    height: '96px',
    backgroundColor: grey868,
    borderTop: `1px solid ${grey600}`,
    cursor: 'pointer',
    opacity: 0.6,
  },
  circle: {
    width: '16px',
    height: '16px',
    marginTop: '12px',
    marginRight: '11px',
    borderRadius: '8px',
    marginLeft: '-8px',
  },
  link: {
    color: blue500,
    marginLeft: '20px',
    bottom: '8px',
  },
  timestampStylePanel: {
    color: 'rgba(255, 255, 255, 0.6)',
    position: 'absolute',
    width: '45px',
    height: '16px',
    left: '319px',
    top: '11px',
  },
  timestampStylePopup: {
    color: highEmphasisLightTheme,
    position: 'absolute',
    width: '45px',
    height: '16px',
    left: '319px',
    top: '11px',
  },
  topRowCard: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'start',
  },
  typographyTitlePanel: {
    color: highEmphasisDarkTheme,
    marginTop: '12px',
    marginBottom: '4px',
    width: '250px',
    verticalAlign: 'middle',
    display: 'inline-block',
  },
  typographyTitlePopup: {
    color: highEmphasisLightTheme,
    marginTop: '12px',
    width: '250px',
    marginBottom: '4px',
    verticalAlign: 'middle',
    display: 'inline-block',
  },
  typographyDescriptionPanel: {
    color: highEmphasisDarkTheme,
    marginLeft: '20px',
  },
  typographyDescriptionPopup: {
    color: highEmphasisLightTheme,
    marginLeft: '20px',
  },
});

const NotificationCard = (props: IProps) => {
  const { notificationDisplayType, label, color, description, onClick, timestamp, className, read } = props;
  const iconStyles = useIconStyles();
  const {
    card,
    cardContent,
    cardWhenRead,
    link,
    timestampStylePanel,
    timestampStylePopup,
    typographyTitlePanel,
    typographyTitlePopup,
    typographyDescriptionPanel,
    typographyDescriptionPopup,
    topRowCard,
    circle,
  } = useStyles();
  const cardStyle = read ? cardWhenRead : card;
  const typographyTitle =
    notificationDisplayType === NotificationDisplayType.Popup ? typographyTitlePopup : typographyTitlePanel;
  const typographyDescription =
    notificationDisplayType === NotificationDisplayType.Popup ? typographyDescriptionPopup : typographyDescriptionPanel;
  const timestampStyle =
    notificationDisplayType === NotificationDisplayType.Popup ? timestampStylePopup : timestampStylePanel;
  return (
    <Card style={{ borderLeft: `4px solid ${color}` }} className={clsx(cardStyle, className)} onClick={onClick}>
      <CardContent className={cardContent}>
        <div>
          <div className={topRowCard}>
            <div className={circle} style={{ backgroundColor: color }} />
            <Typography className={clsx(typographyTitle, 'sdds-headline-06')}>{label}</Typography>
            <span className={clsx(timestampStyle, 'sdds-detail-05')}>{timestamp.toTimeString().split(' ')[0]}</span>
          </div>
          <span
            className={clsx(typographyDescription, 'sdds-detail-02', iconStyles.tooltipTypography)}
            style={{ marginLeft: '20px', marginBottom: '8px', height: '32px' }}
          >
            {description}
          </span>
          {/* eslint-disable jsx-a11y/anchor-is-valid */}
          <Link className={link} onClick={onClick} component="button">
            Go to vehicle
          </Link>
          {/* eslint-enable jsx-a11y/anchor-is-valid */}
        </div>
      </CardContent>
    </Card>
  );
};

export default NotificationCard;
