import { t } from 'ttag';
import Progress from './Progress';

export const getButtonTextClear = (progress: Progress | null) => {
  let text;

  if (progress === Progress.VERY_SLOW) {
    text = t`Still clearing…`;
  } else if (progress === Progress.IN_PROGRESS) {
    text = t`Clearing…`;
  } else {
    text = t`Clear`;
  }

  return text;
};

export const getButtonTextDispatch = (progress: Progress | null) => {
  let text;

  if (progress === Progress.VERY_SLOW) {
    text = t`Still dispatching…`;
  } else if (progress === Progress.IN_PROGRESS) {
    text = t`Dispatching…`;
  } else {
    text = t`Dispatch`;
  }

  return text;
};

export const getButtonTextRelease = (progress: Progress | null) => {
  let text;

  if (progress === Progress.VERY_SLOW) {
    text = t`Still releasing…`;
  } else if (progress === Progress.IN_PROGRESS) {
    text = t`Releasing…`;
  } else {
    text = t`Release`;
  }

  return text;
};

export const getButtonTextStandDown = (progress: Progress | null) => {
  let text;

  if (progress === Progress.VERY_SLOW) {
    text = t`Still standing down…`;
  } else if (progress === Progress.IN_PROGRESS) {
    text = t`Standing Down…`;
  } else {
    text = t`Stand Down`;
  }

  return text;
};
