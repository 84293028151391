import { Equipment, EquipmentStatus } from '@ats/graphql';
import { indexBy, prop } from 'ramda';
import isOnline from './isOnline';
import IVehicle from './IVehicle';

const mergeVehicles = (
  equipments: ReadonlyArray<Equipment> | null,
  statuses: ReadonlyArray<EquipmentStatus> | null,
): ReadonlyArray<IVehicle> | null => {
  let vehicles = null;

  if (equipments && statuses) {
    const statusesById = indexBy(prop('externalEquipmentReference'), statuses);

    vehicles = equipments
      .filter(({ externalEquipmentReference }) => externalEquipmentReference in statusesById)
      .map((equipment) => {
        const status = statusesById[equipment.externalEquipmentReference];

        const vehicle: IVehicle = {
          ...equipment,
          ...status,
          online: isOnline(status.timestamp),
        };

        return vehicle;
      });
  }

  return vehicles;
};

export default mergeVehicles;
