import { t } from 'ttag';
import { DegradationPlanAction } from '@ats/graphql';
import type { DegradationPlanElementData as IDegradationPlanElementData } from '@ats/graphql';
import getDegradationActionLabel from './getDegradationActionLabel';

const getDegradationsSummary = (degradations: ReadonlyArray<IDegradationPlanElementData>) => {
  const { action } = degradations[0];
  let summary: string;

  if (action !== DegradationPlanAction.None && action !== DegradationPlanAction.Unknown) {
    const actionLabel = getDegradationActionLabel(action).toLowerCase();

    summary = t`${degradations.length} degradations caused the vehicle to ${actionLabel}.`;
  } else {
    summary = t`${degradations.length} degradations.`;
  }

  return summary;
};

export default getDegradationsSummary;
