import { useMergedQueueState } from '@ats/graphql';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { t } from 'ttag';
import { memo } from 'react';
import { grey100 } from '../../theme/color';
import { VehicleStatusButton } from '../left/button/Button';
import { isEqualOmitEquipmentTimestamps } from '../../model/isEqual';
import withEquipment from '../withEquipment';
import IVehicle from '../../model/vehicle/IVehicle';
import getVehicleStateLabel from '../../model/vehicle/state/getVehicleStateLabel';

interface IProps {
  queueId: string;
  areaId: string | null;
  equipmentStatuses: IVehicle[];
  selectedEquipmentState: [string | null, React.Dispatch<React.SetStateAction<string | null>>];
}

const useStyles = makeStyles({
  h3: {
    marginBottom: '24px',
    marginLeft: '24px',
    marginTop: '48px',
    color: grey100,
  },
});

const QueueStatus = (props: IProps) => {
  const { queueId, areaId, equipmentStatuses, selectedEquipmentState } = props;
  const [selectedEquipmentId, setSelectedEquipmentId] = selectedEquipmentState;

  const { h3 } = useStyles();

  const [queueStatus] = useMergedQueueState({
    areaId,
    queueId,
  });

  if (!queueStatus || queueStatus[0].equipment.length === 0) return null;

  return (
    <>
      <h3 className={clsx(h3, 'sdds-headline-07')}>{t`Queue`}</h3>
      {queueStatus[0].equipment.map(({ displayName, externalEquipmentReference }) => {
        const equipment = equipmentStatuses.find(
          ({ externalEquipmentReference: id }) => externalEquipmentReference === id,
        );
        if (!equipment) {
          throw new Error('Unable to get information about vehicle');
        }
        const { operationalState, mode, online } = equipment;

        return (
          <VehicleStatusButton
            variant="contained"
            alertPulse={false}
            icon={null}
            active={false}
            hover={false}
            key={externalEquipmentReference}
            onClick={() => {
              if (selectedEquipmentId && externalEquipmentReference === selectedEquipmentId) {
                setSelectedEquipmentId(null);
              } else {
                setSelectedEquipmentId(externalEquipmentReference);
              }
            }}
            mode={mode}
            operationalState={operationalState}
            online={online}
            name={displayName}
            statusLabel={<>{getVehicleStateLabel(operationalState, mode, online, false)}</>}
          />
        );
      })}
    </>
  );
};

export default withEquipment(memo(QueueStatus, isEqualOmitEquipmentTimestamps));
