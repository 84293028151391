import { Card, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { LastCommunicationState } from '../../model/vehicle/state/IVehicleStateColor';

interface IProps {
  lastCommunicationStateColor: string;
}

export const useStyles = makeStyles(
  {
    PulseContainer: {
      width: '368px',
      height: '8px',
      backgroundSize: '100%',
      boxShadow: 'none',
    },

    lastCommunicationActive: {
      animation: 'lastCommunicationActive',
      animationIterationCount: 'infinite',
      animationDirection: 'alternate',
      animationDuration: `1s`,
      animationTimingFunction: `ease-in-out`,
    },
    lastCommunicationDeviation: {
      animation: 'lastCommunicationDeviation',
      animationIterationCount: 'infinite',
      animationDirection: 'alternate',
      animationDuration: `1s`,
      animationTimingFunction: `ease-in-out`,
    },
    lastCommunicationIdle: {
      animation: 'lastCommunicationIdle',
      animationIterationCount: 'infinite',
      animationDirection: 'alternate',
      animationDuration: `1s`,
      animationTimingFunction: `ease-in-out`,
    },
    lastCommunicationManual: {
      animation: 'lastCommunicationManual',
      animationIterationCount: 'infinite',
      animationDirection: 'alternate',
      animationDuration: `1s`,
      animationTimingFunction: `ease-in-out`,
    },
    lastCommunicationOffline: {
      animation: 'lastCommunicationOffline',
      animationIterationCount: 'infinite',
      animationDirection: 'alternate',
      animationDuration: `1s`,
      animationTimingFunction: `ease-in-out`,
    },
    lastCommunicationStandDown: {
      animation: 'lastCommunicationStandDown',
      animationIterationCount: 'infinite',
      animationDirection: 'alternate',
      animationDuration: `1s`,
      animationTimingFunction: `ease-in-out`,
    },
  },
  { index: 1 },
);

const LastCommunicationPulseBar = ({ lastCommunicationStateColor }: IProps) => {
  const {
    PulseContainer,
    lastCommunicationActive,
    lastCommunicationDeviation,
    lastCommunicationIdle,
    lastCommunicationManual,
    lastCommunicationOffline,
    lastCommunicationStandDown,
  } = useStyles();

  let lastCommunicationPulseColor = null;

  switch (lastCommunicationStateColor) {
    case LastCommunicationState.LAST_COMMUNICATION_ACTIVE:
      lastCommunicationPulseColor = lastCommunicationActive;
      break;
    case LastCommunicationState.LAST_COMMUNICATION_DEVIATION:
      lastCommunicationPulseColor = lastCommunicationDeviation;
      break;
    case LastCommunicationState.LAST_COMMUNICATION_IDLE:
      lastCommunicationPulseColor = lastCommunicationIdle;
      break;
    case LastCommunicationState.LAST_COMMUNICATION_MANUAL:
      lastCommunicationPulseColor = lastCommunicationManual;
      break;
    case LastCommunicationState.LAST_COMMUNICATION_STAND_DOWN:
      lastCommunicationPulseColor = lastCommunicationStandDown;
      break;
    default:
      lastCommunicationPulseColor = lastCommunicationOffline;
  }

  return (
    <>
      <div style={{ maxWidth: '368px', overflow: 'hidden' }}>
        <Card className={clsx(PulseContainer, lastCommunicationPulseColor)} />
      </div>
    </>
  );
};

export default LastCommunicationPulseBar;
