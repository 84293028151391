import { Amplify } from 'aws-amplify';
import { api, auth } from '../config/amplify';

export function configureAmplify() {
  Amplify.configure({
    API: api,
    Auth: auth,
  });
}

export interface ICustomerAttributes {
  /* eslint-disable camelcase */
  readonly externalCustomerReference: string;
  readonly externalStaffReference: string;
  /* eslint-enable camelcase */
}

export interface IUserAttributes {
  /* eslint-disable camelcase */
  readonly family_name: string;
  readonly given_name: string;
  /* eslint-enable camelcase */
  readonly customer: ICustomerAttributes;
}
