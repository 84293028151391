import { Mission, ActionList } from '@ats/graphql';
/**
 * This function returns the name of a mission || "Click and Drive"
 */
const getMissionName = (
  missionId: string,
  missions: readonly Mission[] | null,
  actionLists: readonly ActionList[] | null,
) => {
  let displayName: string | undefined;
  const actionListId = missions?.find(({ id }) => id === missionId)?.actionListId;
  if (actionListId) {
    displayName = actionLists?.find(({ id }) => id === actionListId)?.displayName;
  } else {
    displayName = 'Click and Drive';
  }
  return displayName;
};

export default getMissionName;
