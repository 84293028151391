import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { grey900, white } from '../../../theme/color';

export const useStyles = makeStyles({
  card: {
    boxShadow: 'none',
    width: '100%',
    height: '116px',
    backgroundColor: grey900,
    cursor: 'pointer',
  },
});

interface IProps {
  title: string;
  description: string;
  icon: JSX.Element;
}

const InfoCard = ({ title, description, icon }: IProps) => {
  const { card } = useStyles();

  return (
    <div className={clsx(card, 'sdds-card')}>
      <div className={clsx('sdds-card-header')}>
        <h6 style={{ color: white }} className={clsx('sdds-card-headline')}>
          {title}
        </h6>
        <h6 className={clsx('sdds-card-sub-headline')}>{description}</h6>
      </div>
      <div className={clsx('sdds-card-footer')}>{icon}</div>
    </div>
  );
};

export default InfoCard;
