import { EquipmentMode } from '@ats/graphql';
import { t } from 'ttag';

const getModeLabel = (mode: EquipmentMode | null): string | null => {
  if (mode === null) return t`-`;
  switch (mode) {
    case EquipmentMode.MODE_AUTONOMOUS:
    case EquipmentMode.MODE_UNMANNED:
      return t`Autonomous`;
    case EquipmentMode.MODE_MANUAL:
      return t`Manual`;
    case EquipmentMode.MODE_SHADOW:
      return t`Shadow`;
    case EquipmentMode.MODE_ASSISTED:
      return t`Assisted`;
    case EquipmentMode.MODE_INVALID:
      return t`-`;
    default:
      return '';
  }
};

export default getModeLabel;
