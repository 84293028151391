import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { getLog } from '@ats/graphql';
import { useState } from 'react';
import clsx from 'clsx';
import { t } from 'ttag';
import { generateTxtFile, generateCSVFile } from './logdumpHelpers';
import useModalStyles from './useModalStyles';
import StyledRadio from './icons/StyledRadio';

interface IProps {
  logDumpOpenState: [boolean, (logDumpOpen: boolean) => boolean];
}

enum DownloadType {
  TXT = 'TXT',
  CSV = 'CSV',
}

const LogDumpModal = (props: IProps) => {
  const {
    logDumpOpenState: [logDumpOpen, setLogDumpOpen],
  } = props;
  const [downloadType, setDownloadType] = useState<DownloadType>(DownloadType.CSV);
  const {
    paper,
    dialogTitle,
    cancelButton,
    closeButton,
    formControlLabel,
    formLabel,
    label,
    blueButton,
    actionButtons,
    dialogContent,
  } = useModalStyles();

  const close = () => {
    setLogDumpOpen(false);
  };

  const download = () => {
    const element = document.createElement('a');

    const data = getLog();
    if (!data) {
      return;
    }
    let file;
    let filenameExtension;
    if (downloadType === DownloadType.TXT) {
      file = generateTxtFile(data);
      filenameExtension = 'txt';
    }
    if (downloadType === DownloadType.CSV) {
      file = generateCSVFile(data);
      filenameExtension = 'csv';
    }

    if (!file) return;

    element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(file)}`);
    element.setAttribute('download', `ice-ui-log-dump-${new Date().toISOString()}.${filenameExtension}`);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);

    setLogDumpOpen(false);
  };

  return (
    <Dialog classes={{ paper }} onClose={close} open={logDumpOpen}>
      <DialogTitle className={dialogTitle} disableTypography>
        <Typography className="sdds-headline-05" variant="h5">
          {t`Log dump`}
        </Typography>
      </DialogTitle>
      <IconButton aria-label={t`close`} className={closeButton} onClick={close}>
        <Close />
      </IconButton>
      <DialogContent className={dialogContent}>
        <FormLabel className={clsx(formLabel, 'sdds-detail-02')}>{t`Download logfile`}</FormLabel>
        <RadioGroup value={downloadType} onChange={(event) => setDownloadType(event.target.value as DownloadType)}>
          <FormControlLabel
            classes={{ label: clsx(label, 'sdds-detail-02') }}
            className={formControlLabel}
            control={<StyledRadio />}
            label={t`CSV`}
            value="CSV"
          />
          <FormControlLabel
            classes={{ label: clsx(label, 'sdds-detail-02') }}
            className={formControlLabel}
            control={<StyledRadio />}
            label={t`TXT`}
            value="TXT"
          />
        </RadioGroup>
        <Box className={actionButtons}>
          <Button className={cancelButton} disableRipple onClick={close}>
            <p className="sdds-detail-02">{t`Cancel`}</p>
          </Button>
          <Button
            className={blueButton}
            disableRipple
            onClick={download}
            id="save"
            data-testid="profile-menu-settings-save-button"
          >
            <p className="sdds-detail-02">{t`Download`}</p>
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default LogDumpModal;
