/**
 * A response time of 100ms is perceived as instantaneous.
 *
 * @see https://www.pubnub.com/blog/how-fast-is-realtime-human-perception-and-technology/
 */
export const feelInstantaneous = 100;

/**
 * Response times of 1 second or less are fast enough for users to feel they are interacting freely with the information.
 *
 * @see https://www.pubnub.com/blog/how-fast-is-realtime-human-perception-and-technology/
 */
export const interactFreely = 1000;

/**
 * Response times greater than 10 seconds completely lose the user’s attention.
 *
 * @see https://www.pubnub.com/blog/how-fast-is-realtime-human-perception-and-technology/
 */
export const keepAttention = 10000;
