import { Card, CardContent, makeStyles, Typography, SvgIcon } from '@material-ui/core';
import clsx from 'clsx';
import { memo, useEffect, useState } from 'react';
import { grey868, white } from '../../theme/color';
import { ICommonIconProps, useIconStyles } from '../icons/CommonIconProps';

interface IProps extends ICommonIconProps {
  cardTitle: string;
  cardDescription: string | string[];
  dispatcher?: string;
}

export const useStyles = makeStyles(
  {
    card: {
      boxShadow: 'none',
      width: '320px',
      borderRadius: '4px',
      backgroundColor: grey868,
      marginTop: '8px',
    },
    controlContainer: {
      paddingLeft: '24px',
    },

    cardContent: {
      paddingBottom: '6rem',
      '&:last-child': {
        paddingBottom: '4rem',
      },
      paddingTop: 0,
    },
    typographyTitle: {
      color: white,
      paddingTop: '13px',
      width: '250px',
    },
    typographyDescription: {
      color: 'rgba(255, 255, 255, 0.6)',
      paddingTop: '10px',
      lineHeight: '5rem',
    },
    cardClose: {
      position: 'relative',
      width: '8px',
      height: '8px',
      float: 'right',
      top: '16px',
      cursor: 'pointer',
    },
  },
  { index: 1 },
);

const ControlCard = (props: IProps) => {
  const { cardTitle, cardDescription, className, dispatcher } = props;
  const iconStyles = useIconStyles();
  const { card, cardContent, controlContainer, cardClose, typographyTitle, typographyDescription } = useStyles();
  const [showControlCard, setShowControlCard] = useState(true);

  const onClose = () => setShowControlCard(false);

  useEffect(() => {
    if (dispatcher !== 'None') {
      setShowControlCard(true);
    } else {
      setShowControlCard(false);
    }
  }, [dispatcher]);

  return (
    <>
      {showControlCard ? (
        <div className={controlContainer}>
          <Card className={clsx(card, className)}>
            <CardContent className={cardContent}>
              <SvgIcon className={cardClose} width="8px" height="8px" viewBox="0 0 8 8" onClick={onClose}>
                <path
                  d="M8 0.7L7.3 0L4 3.3L0.7 0L0 0.7L3.3 4L0 7.3L0.7 8L4 4.7L7.3 8L8 7.3L4.7 4L8 0.7Z"
                  fill="white"
                />
              </SvgIcon>

              <Typography className={clsx(typographyTitle, 'sdds-headline-07')}>{cardTitle}</Typography>

              <span className={clsx(typographyDescription, iconStyles.tooltipTypography)}>
                {typeof cardDescription === 'string' ? cardDescription : cardDescription.join('\n')}
              </span>
            </CardContent>
          </Card>
        </div>
      ) : null}
    </>
  );
};

export default memo(ControlCard);
