import { Card, CardContent, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useMapFiles } from '@ats/graphql';
import { t } from 'ttag';
import { grey300, grey868, red, white } from '../../theme/color';
import IVehicle from '../../model/vehicle/IVehicle';

const useStyles = makeStyles(
  {
    card: {
      borderTop: `4px solid ${red}`,
      color: grey868,
      display: 'inline-block',
      flexGrow: 0,
      flexBasis: '50px',
      flexShrink: 0,
      height: '94px',
      left: '24px',
      position: 'relative',
      textAlign: 'center',
      width: '320px',
    },
    cardContent: {
      color: grey300,
      paddingBottom: '8px',
      textAlign: 'left',
    },
  },
  { index: 1 },
);

interface IProps {
  vehicle: IVehicle;
  areaId: string;
}

const MapVersionCard = ({ vehicle, areaId }: IProps) => {
  const { card, cardContent } = useStyles();
  const [mapFiles] = useMapFiles({ areaId });
  const mapVersion = mapFiles?.[0]?.version || null;

  return (
    <>
      {mapVersion && mapVersion !== vehicle.mapVersion && (
        <Card className={card}>
          <CardContent>
            <Typography className={clsx(cardContent, 'sdds-headline-07')} style={{ color: white }}>
              {t`Map version mismatch`}
            </Typography>
            <Typography className={clsx(cardContent, 'sdds-detail-02')}>
              {t`Site version:`} {mapVersion}
              <br />
              {t`Vehicle version:`} {vehicle.mapVersion}
            </Typography>
          </CardContent>
        </Card>
      )}
    </>
  );
};
export default MapVersionCard;
