import { Mission_actions as MissionActions, Mission_actions_commands as MissionActionsCommands } from '@ats/graphql';

export interface IMergedCommand extends MissionActionsCommands {
  readonly actionIndex: number;
  readonly commandIndex: number;
}

/**
 * Merge commands from different actions into a single array of commands.
 */
const mergeMissionActionCommands = (actions: ReadonlyArray<MissionActions>): ReadonlyArray<IMergedCommand> => {
  const mergedCommands: IMergedCommand[] = [];

  actions.forEach(({ commands }, actionIndex) => {
    commands.forEach((command, commandIndex) => {
      mergedCommands.push({ ...command, actionIndex, commandIndex });
    });
  });
  return mergedCommands;
};
export default mergeMissionActionCommands;
