import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import { grey900, grey600, white } from '../../theme/color';

const useStyles = makeStyles(
  {
    root: {
      borderColor: white,
    },
    icon: {
      borderRadius: '50%',
      width: 16,
      height: 16,
      backgroundColor: grey900,
      border: '1px solid white',
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: grey600,
        borderColor: white,
        opacity: 0.5,
      },
    },
    checkedIcon: {
      backgroundColor: white,
      '&:before': {
        display: 'block',
        width: 10,
        height: 10,
        margin: 2,
        backgroundImage: 'radial-gradient(#171719,#171719 50%,transparent 55%)',
        content: '""',
      },
    },
  },
  { index: 1 },
);

export const StyledRadio = (props: RadioProps) => {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
};

export default StyledRadio;
