import GeoJSON from 'ol/format/GeoJSON';
import { Geometry } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import { useEffect, useMemo } from 'react';
import { combineLatest } from 'rxjs';
import bisectedRouteObservable from '../../model/map/observable/bisectedRoute.observable';
import selectedVehicleColorObservable from '../../model/map/observable/selectedVehicleColor.observable';
import { offline } from '../../model/vehicle/state/getVehicleStateColor';
import { white } from '../../theme/color';

const geoJsonReader = new GeoJSON({
  featureProjection: 'EPSG:3857',
});

const useVehicleRouteLayer = () => {
  const source = useMemo<VectorSource<Geometry>>(() => new VectorSource(), []);
  const layer = useMemo<VectorLayer<VectorSource<Geometry>>>(() => new VectorLayer({ source }), [source]);

  useEffect(() => {
    const subscription = combineLatest([bisectedRouteObservable, selectedVehicleColorObservable]).subscribe(
      ([{ future, past }, selectedVehicleColor]) => {
        source.clear();
        if (selectedVehicleColor && selectedVehicleColor !== offline) {
          const { foreground, shadow } = selectedVehicleColor;

          const trails = past.map((geoJson) => {
            const id = `trail/${geoJson.id}`;
            const feature = geoJsonReader.readFeature({
              ...geoJson,
              id,
              properties: {
                ...geoJson.properties,
              },
            });

            if (geoJson.properties.routeKnown) {
              feature.setStyle(
                new Style({
                  stroke: new Stroke({
                    color: white,
                    width: 4,
                  }),
                }),
              );
            } else {
              feature.setStyle(
                new Style({
                  stroke: new Stroke({
                    color: white,
                    lineCap: 'butt',
                    lineDash: [3, 3],
                    width: 4,
                  }),
                }),
              );
            }

            return feature;
          });

          const features = future.map((geoJson) => {
            const feature = geoJsonReader.readFeature(geoJson);

            if (geoJson.properties.routeKnown) {
              feature.setStyle(
                new Style({
                  stroke: new Stroke({
                    color: foreground,
                    width: 4,
                  }),
                }),
              );
            } else {
              feature.setStyle(
                new Style({
                  stroke: new Stroke({
                    color: foreground,
                    lineCap: 'butt',
                    lineDash: [3, 3],
                    width: 4,
                  }),
                }),
              );
            }

            return feature;
          });

          const shadows = future.map((geoJson) => {
            const id = `shadow/${geoJson.id}`;
            const feature = geoJsonReader.readFeature({
              ...geoJson,
              id,
              properties: {
                ...geoJson.properties,
              },
            });

            feature.setStyle(
              new Style({
                stroke: new Stroke({
                  color: shadow,
                  width: 8,
                }),
              }),
            );

            return feature;
          });

          source.addFeatures([...trails, ...features, ...shadows]);
        }
      },
    );

    return () => subscription.unsubscribe();
  }, [source]);

  return layer;
};

export default useVehicleRouteLayer;
