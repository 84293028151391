import { useState, useEffect, useMemo, useRef, memo } from 'react';
import { makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';
import LoadingSkeleton from '../LoadingSkeleton';
import withEquipment from '../withEquipment';
import { Speaker } from '../icons';
import { keepAttention } from '../../model/duration';
import ellipsis from '../../model/format/ellipsis';
import getVehicleStateLabel from '../../model/vehicle/state/getVehicleStateLabel';
import getSortedVehicles from '../../model/vehicle/state/getSortedVehicles';
import {
  CollapseButton,
  VehicleStatusButton,
  TinyVehicleButton,
  AccordionButton,
  StandardButton,
  ExpandButton,
} from './button/Button';
import { grey50, grey100, grey300, grey800, grey900 } from '../../theme/color';
import { isEqualOmitEquipmentTimestamps } from '../../model/isEqual';

import Events from '../../model/events';
import useVehicleDegradationAlert from '../../model/degradation/useVehicleDegradationAlert';

const useStyles = makeStyles(
  {
    root: {
      backgroundColor: grey900,
      overflowX: 'hidden',
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
      '& button:last-child': {
        marginTop: 'auto',
      },
      height: 'calc(100vh - 64px)',
      zIndex: 1300,
    },
    minimizedRoot: {
      flexBasis: '68px !important',
    },
    spaced: {
      margin: '20px',
    },
    text: {
      padding: '22px',
    },
    tooltip: {
      background: grey50,
      '& > p': {
        color: grey900,
        margin: 0,
      },
    },
    siteMinimized: {
      color: grey100,
      display: 'block',
      marginBottom: '88px',
      marginTop: '-1px',
      textAlign: 'center',
      justifyContent: 'center',
    },
    areaList: {
      borderBottom: `1px solid ${grey800}`,
    },
    h3: {
      marginBottom: '24px',
      marginLeft: '24px',
      marginTop: '48px',
      color: grey100,
    },
    noVehicles: {
      marginLeft: '24px',
      color: grey300,
    },
  },
  { index: 1 },
);

function VehicleStatusButtonContainer(props) {
  const {
    vehicle,
    selectedAreaId,
    selectedEquipmentId,
    hoverEquipmentId,
    setSnap,
    setSelectedEquipmentId,
    setHoverEquipmentId,
    alertsMuted,
  } = props;
  const { externalEquipmentReference, displayName, operationalState, mode, online } = vehicle;

  const [alert, setAlert] = useState(false);
  const sound = useMemo(() => new Audio('/static/sound/notification-decorative-01.wav'), []);
  const timerRef = useRef(null);

  useVehicleDegradationAlert({
    areaId: selectedAreaId,
    externalEquipmentReference,
    operationalState,
    mode,
    alertHandler: () => {
      setAlert(true);
      // If this vehicle is already selected, mute the alert automatically after 9 seconds (before sound play again)
      if (selectedEquipmentId === externalEquipmentReference) {
        setTimeout(() => {
          setAlert(false);
        }, 9000);
      }
    },
    alertResetHandler: () => {
      setAlert(false);
    },
  });

  useEffect(() => {
    // TODO: convert alertsMuted to typescript type AlertsMuted
    if (alert && timerRef.current === null && alertsMuted === 'OFF') {
      sound.currentTime = 0;
      try {
        sound.play();
      } catch {
        // Intentionally silently swallow this error
        // CAV-38291
        /* DOMException: play() failed because the user didn't interact with the document first. https://goo.gl/xX8pDD */
      }

      // Repeat the sound every 10:th second an additional 5 times (total 6)
      let repeats = 0;
      timerRef.current = setInterval(() => {
        sound.currentTime = 0;
        try {
          sound.play();
        } catch {
          // See above
        }
        repeats += 1;
        if (repeats === 5) {
          clearInterval(timerRef.current);
          timerRef.current = null;
        }
      }, keepAttention);
    } else {
      try {
        sound.pause();
      } catch {
        // See above
      }
      clearInterval(timerRef.current);
      timerRef.current = null;
    }

    return function cleanup() {
      if (timerRef.current !== null) {
        clearInterval(timerRef.current);
        timerRef.current = null;
        try {
          sound.pause(); // Stop sound if changing area (which we cause this component instance to be removed)
        } catch {
          // See above
        }
      }
    };
  }, [alert, sound, timerRef, alertsMuted]);

  const handleClick = (e) => {
    e.stopPropagation();
    setAlert(false);
  };

  const icon = alert ? (
    <Speaker
      mute={alertsMuted}
      click={handleClick}
      testid={`degradation-alert-speaker-${externalEquipmentReference}`}
    />
  ) : null;

  return (
    <VehicleStatusButton
      variant="contained"
      alertPulse={alert}
      icon={icon}
      active={externalEquipmentReference === selectedEquipmentId}
      hover={externalEquipmentReference === hoverEquipmentId}
      key={externalEquipmentReference}
      onClick={() => {
        if (selectedEquipmentId && externalEquipmentReference === selectedEquipmentId) {
          setSelectedEquipmentId(null);
        } else {
          setSnap(true);
          setSelectedEquipmentId(externalEquipmentReference);
          setAlert(false);
        }
      }}
      onMouseEnter={() => setHoverEquipmentId(externalEquipmentReference)}
      onMouseLeave={() => setHoverEquipmentId(null)}
      operationalState={operationalState}
      mode={mode}
      online={online}
      name={displayName}
      statusLabel={<>{getVehicleStateLabel(operationalState, mode, online)}</>}
    />
  );
}

function MaximizedPanel(props) {
  const { spaced, areaList, h3, noVehicles } = useStyles();

  const {
    areas,
    equipmentStatuses,
    selectedAreaState,
    snapState,
    hoverEquipmentState,
    selectedEquipmentState,
    setMinimized,
    alertsMuted,
  } = props;
  const [, setSnap] = snapState;
  const [hoverEquipmentId, setHoverEquipmentId] = hoverEquipmentState;
  const [selectedEquipmentId, setSelectedEquipmentId] = selectedEquipmentState;
  const [selectedAreaId, setSelectedAreaId] = selectedAreaState;
  const [accordionOpen, setAccordionOpen] = useState(false);

  const sortedEquipment = getSortedVehicles(equipmentStatuses);
  const areaLabel = areas?.find(({ id }) => id === selectedAreaId)?.displayName;

  return (
    <>
      {areas ? (
        <AccordionButton
          style={{ marginTop: '-1px' }}
          open={accordionOpen}
          label={ellipsis(areaLabel, 20)}
          onClick={() => setAccordionOpen(!accordionOpen)}
          testid="area-selection-button"
        />
      ) : (
        <Skeleton className={spaced} variant="rect" width={210} height={30} />
      )}
      {accordionOpen && (
        <div className={areaList} id="areaSelectionList">
          {areas.map(({ id, displayName }) => (
            <StandardButton
              area
              key={id}
              onClick={() => {
                setAccordionOpen(false);
                setSelectedAreaId(id);
                setSelectedEquipmentId(null);
              }}
              data-testid={`area-selection-button-${displayName}`}
            >
              <p className="sdds-detail-02">{displayName}</p>
            </StandardButton>
          ))}
        </div>
      )}
      <h3 className={clsx(h3, 'sdds-headline-07')}>Site Vehicles</h3>
      {sortedEquipment?.map((vehicle, index) => (
        <VehicleStatusButtonContainer
          selectedAreaId={selectedAreaId}
          key={vehicle.externalEquipmentReference}
          vehicle={vehicle}
          index={index}
          selectedEquipmentId={selectedEquipmentId}
          hoverEquipmentId={hoverEquipmentId}
          setSnap={setSnap}
          setSelectedEquipmentId={setSelectedEquipmentId}
          setHoverEquipmentId={setHoverEquipmentId}
          alertsMuted={alertsMuted}
        />
      ))}
      {equipmentStatuses?.length === 0 && <p className={noVehicles}>No vehicles in area</p>}
      {equipmentStatuses === null && (
        <div className={spaced}>
          <LoadingSkeleton />
        </div>
      )}
      <CollapseButton onClick={() => setMinimized(true)} />
    </>
  );
}

function MinimizedPanel(props) {
  const {
    areas,
    selectedAreaState,
    equipmentStatuses,
    hoverEquipmentState,
    selectedEquipmentState,
    snapState,
    setMinimized,
  } = props;
  const { siteMinimized, tooltip } = useStyles();
  const [selectedAreaId] = selectedAreaState;
  const [, setSnap] = snapState;
  const [hoverEquipmentId, setHoverEquipmentId] = hoverEquipmentState;
  const [selectedEquipmentId, setSelectedEquipmentId] = selectedEquipmentState;
  const area = areas?.find(({ id }) => id === selectedAreaId) || {};
  const areaDisplayName = area.displayName;
  const sortedEquipment = getSortedVehicles(equipmentStatuses);

  return (
    <>
      <Tooltip placement="right" title={<p>{areaDisplayName}</p>} classes={{ tooltip }}>
        <StandardButton
          square
          wrap
          classes={['sdds-headline-04', siteMinimized]}
          onClick={() => setMinimized(false)}
          data-testid="maximize-left-panel"
        >
          {areaDisplayName?.[0]}
        </StandardButton>
      </Tooltip>
      {sortedEquipment?.map(({ displayName, externalEquipmentReference, operationalState, mode, online }) => (
        <Tooltip
          placement="right"
          key={externalEquipmentReference}
          classes={{ tooltip }}
          title={
            <>
              <p>{displayName || 'Name missing'}</p>
              <p>{getVehicleStateLabel(operationalState, mode, online)}</p>
            </>
          }
        >
          <TinyVehicleButton
            active={externalEquipmentReference === selectedEquipmentId}
            hover={externalEquipmentReference === hoverEquipmentId}
            key={externalEquipmentReference}
            onMouseEnter={() => setHoverEquipmentId(externalEquipmentReference)}
            onMouseLeave={() => setHoverEquipmentId(null)}
            onClick={() => {
              if (selectedEquipmentId && externalEquipmentReference === selectedEquipmentId) {
                setSelectedEquipmentId(null);
              } else {
                setSnap(true);
                setSelectedEquipmentId(externalEquipmentReference);
              }
            }}
            operationalState={operationalState}
            mode={mode}
            online={online}
          />
        </Tooltip>
      ))}
      <ExpandButton onClick={() => setMinimized(false)} />
    </>
  );
}

const LeftPanel = (props) => {
  const {
    areas,
    equipmentStatuses,
    selectedAreaState,
    selectedEquipmentState,
    hoverEquipmentState,
    snapState,
    alertsMuted,
    collapseState: [minimized, setMinimized],
  } = props;

  const { root, minimizedRoot } = useStyles();
  const maximized = !minimized;

  useEffect(() => {
    window.dispatchEvent(new Event(Events.MAP_AREA_CHANGED));
  }, [minimized]);

  return (
    <div className={[root].concat(minimized ? minimizedRoot : null).join(' ')}>
      {maximized ? (
        <MaximizedPanel
          setMinimized={setMinimized}
          selectedAreaState={selectedAreaState}
          areas={areas}
          equipmentStatuses={equipmentStatuses}
          snapState={snapState}
          hoverEquipmentState={hoverEquipmentState}
          selectedEquipmentState={selectedEquipmentState}
          alertsMuted={alertsMuted}
        />
      ) : (
        <MinimizedPanel
          areas={areas}
          equipmentStatuses={equipmentStatuses}
          selectedAreaState={selectedAreaState}
          snapState={snapState}
          hoverEquipmentState={hoverEquipmentState}
          selectedEquipmentState={selectedEquipmentState}
          setMinimized={setMinimized}
        />
      )}
    </div>
  );
};

export default withEquipment(memo(LeftPanel, isEqualOmitEquipmentTimestamps));
