import { Poi, Position } from '@ats/graphql';
import { getDistance } from 'ol/sphere';

const getClosestPoiName = (
  pois: ReadonlyArray<Poi> | null,
  position: Position | null,
  maxDistanceMeters = 10,
): string | null => {
  if (!position || !pois) return null;

  const closestPois: { poiDisplayName: string; poiDistance: number }[] = [];

  pois.forEach((poi) => {
    const poiDistance = getDistance(
      // Observe the OL put longitude first and then latitue: https://openlayers.org/en/latest/doc/faq.html#why-is-the-order-of-a-coordinate-lon-lat-and-not-lat-lon-
      [position.longitude, position.latitude],
      [poi.position.longitude, poi.position.latitude],
    );

    if (poiDistance <= maxDistanceMeters) {
      closestPois.push({ poiDisplayName: poi.displayName, poiDistance });
    }
  });

  const closestPoiDistance =
    closestPois.length >= 1 &&
    closestPois.reduce(
      (minDistance, { poiDistance }) => (poiDistance < minDistance ? poiDistance : minDistance),
      closestPois[0].poiDistance,
    );
  const closestPoi = closestPois.find((poi) => poi.poiDistance === closestPoiDistance) || null;

  return (closestPoi && closestPoi.poiDisplayName) || null;
};

export default getClosestPoiName;
