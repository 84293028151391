function convertToHumanReadable(str) {
  if (str.includes('MaxSubscriptionsReachedError')) return 'AWS connection leak (maximum 100 connecton reached)';

  return str;
}

const decipherErrorMessage = (e) => {
  const messages = e?.error?.errors.map((x) => x.message) || [];

  return messages.map(convertToHumanReadable).join(', ');
};

export default decipherErrorMessage;
