interface IProps {
  color: string;
  className?: string;
  dashed?: boolean;
}

export const Line = ({ color, className, dashed }: IProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="2px" className={className}>
    <line x1="1px" x2="1px" y1="0" y2="100%" strokeWidth="1px" stroke={color} strokeDasharray={dashed ? '4' : ''} />
  </svg>
);

export default Line;
