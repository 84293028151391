import {
  doLog,
  LogEntryType,
  LogLevel,
  sendClearMission,
  sendStandDown,
  canSendControl,
  canStandDown as casUserStandDown,
} from '@ats/graphql';
import { IUserAttributes } from '../../model/user';
import ActiveMission from './ActiveMission';
import type IVehicle from '../../model/vehicle/IVehicle';
import Progress from '../../send/Progress';
import type IVehicleStateColor from '../../model/vehicle/state/IVehicleStateColor';
import isInStandDown from '../../model/vehicle/stand-down/isInStandDown';
import IMissionOverview from '../../model/mission/overview/IMissionOverview';
import Events from '../../model/events';

interface IProps {
  makeSpaceForReleaseButton: boolean;
  setRenderButton: (render: boolean) => void;
  renderReleaseComponent: () => JSX.Element | null;
  externalEquipmentReference: string;
  clearingProgress: Progress | null;
  releasingProgress: Progress | null;
  standingDownProgress: Progress | null;
  dispatchingProgress: Progress | null;
  startClearing: (request: Promise<unknown> | null) => void;
  startStandingDown: (request: Promise<unknown> | null) => void;
  user: IUserAttributes;
  vehicle: IVehicle;
  vehicleColor: IVehicleStateColor;
  missionOverview: IMissionOverview | null;
}

const ActiveMissionContainer = (props: IProps) => {
  const {
    makeSpaceForReleaseButton,
    setRenderButton,
    clearingProgress,
    dispatchingProgress,
    externalEquipmentReference,
    missionOverview,
    releasingProgress,
    standingDownProgress,
    startClearing,
    startStandingDown,
    user,
    vehicle,
    vehicleColor,
    renderReleaseComponent,
  } = props;

  if (!missionOverview) return null;

  const inStandDown = isInStandDown(vehicle);
  const canClear = inStandDown && canSendControl(user);
  const canStandDown = !inStandDown && casUserStandDown(user);
  const hasReleaseFailed = releasingProgress === Progress.FAILED;
  const hasClearFailed = clearingProgress === Progress.FAILED;
  const hasStandDownFailed = standingDownProgress === Progress.FAILED;

  const clearMission = () => {
    const promise = sendClearMission(externalEquipmentReference)
      .then((response) => {
        doLog(LogLevel.Debug, {
          type: LogEntryType.UI,
          externalEquipmentReference,
          message: `Clear mission for ${vehicle.displayName}`,
        });
        return response;
      })
      .catch((e) => {
        const message = e === 'No current user' ? 'Unauthenticated, please log in again' : null;
        const detail = { title: `Failed clear mission command`, message };
        const event = new CustomEvent(Events.TOAST, { detail });
        window.dispatchEvent(event);
        doLog(LogLevel.Warning, {
          type: LogEntryType.UI,
          externalEquipmentReference,
          message: `Clear mission failed for ${vehicle.displayName}`,
        });
      });

    startClearing(promise);

    return promise;
  };

  const standDown = () => {
    const promise = sendStandDown(externalEquipmentReference)
      .then((response) => {
        doLog(LogLevel.Debug, {
          type: LogEntryType.UI,
          externalEquipmentReference,
          message: `Stand down ${vehicle.displayName}`,
        });
        return response;
      })
      .catch((e) => {
        const message = e === 'No current user' ? 'Unauthenticated, please log in again' : null;
        const detail = { title: `Failed stand down command`, message };
        const event = new CustomEvent(Events.TOAST, { detail });
        window.dispatchEvent(event);
        doLog(LogLevel.Warning, {
          type: LogEntryType.UI,
          externalEquipmentReference,
          message: `Stand down failed for ${vehicle.displayName}`,
        });
      });

    startStandingDown(promise);

    return promise;
  };

  return (
    <ActiveMission
      setRenderButton={setRenderButton}
      renderReleaseComponent={renderReleaseComponent}
      canClear={canClear}
      canStandDown={canStandDown}
      makeSpaceForReleaseButton={makeSpaceForReleaseButton}
      hasReleaseFailed={hasReleaseFailed}
      hasClearFailed={hasClearFailed}
      hasStandDownFailed={hasStandDownFailed}
      title={missionOverview.label}
      clearingProgress={clearingProgress}
      dispatchingProgress={dispatchingProgress}
      clear={clearMission}
      missionOverview={missionOverview}
      standDown={standDown}
      standingDownProgress={standingDownProgress}
      vehicleColor={vehicleColor}
    />
  );
};

export default ActiveMissionContainer;
