import { makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { memo } from 'react';

const useStyles = makeStyles(
  {
    marginBottom: {
      marginBottom: '20px',
    },
  },
  { index: 1 },
);

const LoadingSkeleton = () => {
  const { marginBottom } = useStyles();

  return (
    <>
      <Skeleton className={marginBottom} variant="rect" width={230} height={30} />
      <Skeleton className={marginBottom} variant="rect" width={230} height={30} />
      <Skeleton variant="rect" width={230} height={30} />
    </>
  );
};

export default memo(LoadingSkeleton);
