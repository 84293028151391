import { createMuiTheme, makeStyles } from '@material-ui/core';
import {
  semiDimWhite,
  dimWhite,
  blue,
  blue400,
  blue500,
  blue600,
  blue700,
  green,
  green600,
  green800,
  grey50,
  grey100,
  grey868,
  grey500,
  grey800,
  grey900,
  white,
  ultraDimWhite,
  transparent,
  disabledText,
} from './color';

const theme = createMuiTheme({
  spacing: 4,
  palette: {
    primary: {
      main: grey100,
    },
    action: {
      active: white,
      hoverOpacity: 0.04,
      disabled: grey500,
    },
    background: {
      default: grey900,
      paper: grey900,
    },
    text: {
      primary: white,
      secondary: grey100,
      disabled: grey500,
    },
  },
  typography: {
    body1: {
      color: white,
      fontSize: '4rem',
    },
    button: {
      textTransform: 'none',
    },
  },
  shape: {
    borderRadius: 0,
  },
  overrides: {
    MuiTypography: {
      h4: {
        marginTop: '0px',
        color: white,
      },
      h5: {
        marginTop: '0px',
        color: white,
        opacity: '0.87',
      },
      h6: {
        fontSize: '4rem',
      },
    },
    MuiList: {
      padding: {
        padding: '16px',
        paddingTop: '16px',
      },
    },
    MuiTab: {
      root: {
        fontSize: '14px',
      },
      textColorInherit: {
        color: white,
        opacity: 0.4,
      },
    },
    MuiTabs: {
      fixed: {
        width: 'calc(100vh - 74px)',
      },
      flexContainer: {
        borderBottom: `1px solid ${grey800}`,
      },
      indicator: {
        backgroundColor: `${grey100}`,
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: '5.5rem',
      },
    },
    MuiButton: {
      root: {
        minWidth: 0,
        borderRadius: '4px',
        '&:hover': {
          border: `1px solid`,
          borderColor: 'rgba(255, 255, 255, 0.6)',
          backgroundColor: grey900,
        },
        '&:focus': {
          border: `1px solid ${blue}`,
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: grey50,
        color: grey900,
        fontFamily: '"Scania Sans Semi Condensed", "Scania Sans Condensed", Arial, Helvetica, sans-serif',
        fontSize: '12px',
        lineHeight: '16px',
        padding: '8px',
        borderRadius: '4px',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
    MuiPaper: {
      elevation8: {
        boxShadow: 'none',
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: grey868,
        margin: '16px 24px 23px 24px',
      },
    },
    MuiGrid: {
      'spacing-xs-4': {
        margin: 0,
        width: 'auto',
      },
    },
  },
});

export const useCommonStyles = makeStyles(
  {
    primaryButton: {
      border: `1px solid ${transparent}`,
      backgroundColor: blue600,
      color: white,
      borderRadius: '4px',
      paddingBottom: '4px',
      paddingTop: '4px',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: blue500,
      },
      '&:focus': {
        backgroundColor: blue400,
      },
      '&:active': {
        backgroundColor: blue700,
      },
      '&:disabled': {
        color: disabledText,
        backgroundColor: grey868,
      },
    },
    secondaryButton: {
      border: `1px solid ${ultraDimWhite}`,
      borderRadius: '4px',
      color: white,
      paddingBottom: '4px',
      paddingTop: '4px',
      textTransform: 'none',
      '&:hover': {
        border: `1px solid ${dimWhite}`,
      },
      '&:focus': {
        border: `1px solid ${blue}`,
      },
      '&:active': {
        border: `1px solid ${semiDimWhite}`,
      },
    },
    secondaryButtonSelected: {
      border: `1px solid ${blue}`,
    },
    dispatchButton: {
      backgroundColor: `${green} !important`,
      color: white,
      '&:hover': {
        backgroundColor: `${green600} !important`,
        border: `none !important`,
      },
      '&:focus': {
        backgroundColor: `${green800} !important`,
        border: `none !important`,
      },
      '&:active': {
        backgroundColor: `${green800} !important`,
        border: `none !important`,
      },
      '&:disabled': {
        color: disabledText,
        backgroundColor: `${grey868} !important`,
        border: `none !important`,
      },
      '&:Mui-disabled': {
        color: disabledText,
        backgroundColor: `${grey868} !important`,
        border: `none !important`,
      },
    },
    ghostButton: {
      border: '1px solid rgba(0, 0, 0, 0) !important',
      borderRadius: '4px',
      color: white,
      paddingBottom: '4px',
      paddingTop: '4px',
      textTransform: 'none',
      '&:hover': {
        border: `1px solid ${dimWhite} !important`,
      },
      '&:focus': {
        border: `1px solid ${blue} !important`,
      },
      '&:active': {
        border: `1px solid ${semiDimWhite} !important`,
      },
    },
    cardifyButton: {
      padding: '20px',
    },
  },
  { index: 1 },
);

export default theme;
