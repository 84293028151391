import { LogEntry as ILogEntry } from '@ats/graphql';
import { omit, prop } from 'ramda';

export function prettify(str: string) {
  const arr = str.split('');
  let outputStr = '';
  arr.forEach((char, idx) => {
    if (idx === 0) {
      outputStr = char.toUpperCase();
      return;
    }
    if (char === 'd' && arr[idx - 1] === 'I') {
      outputStr += 'D';
      return;
    }
    if (char === char.toUpperCase()) {
      outputStr += ' ';
    }
    outputStr += char;
  });
  return outputStr;
}

export function generateTxtFile(data: ReadonlyArray<ILogEntry>) {
  let fileString = '';
  data.forEach((entry) => {
    const filteredData = omit(['data'], entry);
    fileString += `${JSON.stringify(filteredData)}\n`;
  });
  return fileString;
}

export const CSV_COLUMN_SEPARATOR = ';';

export function generateCSVFile(data: ReadonlyArray<ILogEntry>) {
  const attributes: Array<keyof ILogEntry> = [
    'timestamp',
    'level',
    'type',
    'message',
    'correlationId',
    'externalEquipmentReference',
    'dataType',
  ];
  let fileString = `${attributes.map(prettify).join(CSV_COLUMN_SEPARATOR)}\n`;
  data.forEach((entry, idx) => {
    let row = '';
    attributes.forEach((attribute, attributeIdx) => {
      const value = prop(attribute, entry);
      if (value !== null && value !== undefined) {
        row += attribute === 'message' ? `"${value}"` : value;
      }
      if (attributeIdx < attributes.length - 1) row += CSV_COLUMN_SEPARATOR;
    });
    const linebreak = idx < data.length - 1 ? '\n' : '';
    fileString += `${row}${linebreak}`;
  });
  return fileString;
}
