import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { useObservable } from '@libreact/use-observable';
import { useEffect } from 'react';

import { t } from 'ttag';
import { previewMissionInput as previewMissionInputObservable } from '../../model/observables';
import previewMissionObservable from '../../model/observable/previewMission.observable';
import Events from '../../model/events';

import { MapControlButton, LayerButton } from '../left/button/Button';
import PreviewStateInfoBox from '../mission/PreviewState.infoBox';
import { MissionView } from '../../model/ViewTypes';

const useStyles = makeStyles(
  {
    root: {
      zIndex: 1150, // Places the controls above the map, but below the click away listeners
      position: 'absolute',
      bottom: '24px',
      right: '24px',
      display: 'flex',
      flexDirection: 'column',
      '& button': {
        margin: '4px',
      },
    },
    rightPanelAdjusted: {
      right: `${368 + 14}px`,
    },
    loadingBox: {
      backgroundColor: '#F9FAFB',
      borderRadius: '4px',
      height: '32px',
      width: '106px',
      right: '45%',
      '& p': {
        font: '12px "Scania Sans Semi Condensed"',
        color: 'black',
        opacity: '0.87',
        textAlign: 'center',
        margin: 'auto',
      },
    },
  },
  { index: 1 },
);

const MapOverlayUI = (props) => {
  const {
    snapState: [snap, setSnap],
    loadingMapFileState: loadingMapFile,
    rightPanelOpen,
    selectedEquipmentId,
    satelliteVisibilityState: [satelliteLayerVisible, setSatelliteLayerVisible],
    showPoiNameState: [showPoiName, setShowPoiName],
    showVehicleNameState: [showVehicleName, setShowVehicleName],
    missionViewContext,
  } = props;

  const [previewMissionInput] = useObservable(previewMissionInputObservable);
  const [previewMission] = useObservable(previewMissionObservable, null);
  const { root, rightPanelAdjusted, loadingBox } = useStyles();
  const positionAdjustment = rightPanelOpen && rightPanelAdjusted;
  const infoBoxVisibility = previewMissionInput && !previewMission;

  useEffect(() => {
    if (infoBoxVisibility && missionViewContext === MissionView.SelectMission) {
      const detail = {
        message: t`Loading Preview`,
      };
      const event = new CustomEvent(Events.PREVIEW_LOAD, { detail });
      window.dispatchEvent(event);
    }
  }, [missionViewContext, infoBoxVisibility]);

  useEffect(() => {
    if (loadingMapFile) {
      const detail = {
        message: t`Loading Map`,
      };
      const event = new CustomEvent(Events.PREVIEW_LOAD, { detail });
      window.dispatchEvent(event);
    }
  }, [loadingMapFile]);

  const handleSnapClick = () => {
    if (selectedEquipmentId) setSnap(!snap);
  };

  return (
    <>
      <div className={clsx(root, positionAdjustment)}>
        <LayerButton
          satelliteVisibilityState={[satelliteLayerVisible, setSatelliteLayerVisible]}
          showPoiNameState={[showPoiName, setShowPoiName]}
          showVehicleNameState={[showVehicleName, setShowVehicleName]}
          rightPanelOpen={rightPanelOpen}
          testid="map-layer-button"
        />
        <MapControlButton active={snap} onClick={handleSnapClick} testid="map-snap-click-button">
          <svg width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9 11.5C10.3807 11.5 11.5 10.3807 11.5 9C11.5 7.61929 10.3807 6.5 9 6.5C7.61929 6.5 6.5 7.61929 6.5 9C6.5 10.3807 7.61929 11.5 9 11.5Z"
              fill="#F6F6F7"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.625 0.25H8.375V2.78086C5.42224 3.07403 3.07403 5.42224 2.78086 8.375H0.25V9.625H2.78086C3.07403 12.5778 5.42224 14.926 8.375 15.2191V17.75H9.625V15.2191C12.5778 14.926 14.926 12.5778 15.2191 9.625H17.75V8.375H15.2191C14.926 5.42224 12.5778 3.07403 9.625 2.78086V0.25ZM14 9C14 11.7614 11.7614 14 9 14C6.23858 14 4 11.7614 4 9C4 6.23858 6.23858 4 9 4C11.7614 4 14 6.23858 14 9Z"
              fill="#F6F6F7"
            />
          </svg>
        </MapControlButton>
        <MapControlButton onClick={() => window.dispatchEvent(new Event(Events.ZOOM_IN))} testid="map-zoom-in-button">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.625 0.75H6.375V6.375H0.75V7.625H6.375V13.25H7.625V7.625H13.25V6.375H7.625V0.75Z"
              fill="#F6F6F7"
            />
          </svg>
        </MapControlButton>
        <MapControlButton onClick={() => window.dispatchEvent(new Event(Events.ZOOM_OUT))} testid="map-zoom-out-button">
          <svg width="14" height="2" viewBox="0 0 14 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="13.25" y="0.375" width="1.25" height="12.5" transform="rotate(90 13.25 0.375)" fill="#F6F6F7" />
          </svg>
        </MapControlButton>
      </div>
      {((missionViewContext === MissionView.SelectMission && infoBoxVisibility) || loadingMapFile) && (
        <div className={clsx(root, loadingBox)}>
          <PreviewStateInfoBox />
        </div>
      )}
    </>
  );
};

export default MapOverlayUI;
