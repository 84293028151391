import { Card, CardContent, CardHeader, Divider } from '@material-ui/core';
import clsx from 'clsx';

import { useCardStyles } from './Card.styles';

interface IProps {
  mapInteractionModule: string;
}

const getLabel = (state: string): string => {
  switch (state) {
    case 'POSITION':
      return 'Select position';
    case 'ROTATION':
      return 'Select heading';
    case 'CONFIRMATION':
      return 'Confirm route';
    default:
      return '-';
  }
};

export const ClickAndDriveView = ({ mapInteractionModule }: IProps) => {
  const { card, cardHeader, cardHeaderTypography, cardContent } = useCardStyles();

  return (
    <Card className={card}>
      <CardHeader
        title="Click and Drive"
        className={cardHeader}
        titleTypographyProps={{ className: clsx(cardHeaderTypography, 'sdds-headline-04') }}
      />
      <Divider />
      <CardContent className={cardContent}>{getLabel(mapInteractionModule)}</CardContent>
    </Card>
  );
};
export default ClickAndDriveView;
