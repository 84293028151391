export enum SelectedType {
  Vehicle,
  Poi,
  Zone, // Combination of Queue and Paddock
  Queue, // Stand-alone queue, for example with Wenco integration
  Paddock, // Not sure if applicable
}

export interface ISelectedVehicle {
  id: string;
  type: SelectedType.Vehicle;
}

export interface ISelectedPoi {
  id: string;
  type: SelectedType.Poi;
}

export interface ISelectedZone {
  id?: string;
  type: SelectedType.Zone;
}

export interface ISelectedQueue {
  id?: string;
  type: SelectedType.Queue;
}

export interface ISelectedPaddock {
  id?: string;
  type: SelectedType.Paddock;
}

export type Selected = ISelectedVehicle | ISelectedPoi | ISelectedZone | ISelectedQueue | ISelectedPaddock | null;
