import { SvgIcon } from '@material-ui/core';
import { ICommonIconProps } from './CommonIconProps';

interface IProps extends ICommonIconProps {
  heading: number | null | undefined;
}
export const PoiIcon = ({ heading, className }: IProps) => (
  <SvgIcon
    width="32"
    height="56"
    viewBox="0 0 32 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    transform={`rotate(${heading ? parseFloat(`${heading}`).toFixed() : 0}) scale(1.1)`}
    className={className}
  >
    <circle cx="16" cy="28" r="15" fill="#0D0F13" />
    <path
      d="M16 36C20.4183 36 24 32.4183 24 28C24 23.5817 20.4183 20 16 20C11.5817 20 8 23.5817 8 28C8 32.4183 11.5817 36 16 36Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 28C30 35.732 23.732 42 16 42C8.26801 42 2 35.732 2 28C2 20.268 8.26801 14 16 14C23.732 14 30 20.268 30 28ZM28 28C28 34.6274 22.6274 40 16 40C9.37258 40 4 34.6274 4 28C4 21.3726 9.37258 16 16 16C22.6274 16 28 21.3726 28 28Z"
      fill="white"
    />
    <path
      d="M16.4743 3.84189L16 2.41886L15.5257 3.84189L13.5257 9.84189L13.3063 10.5H14H18H18.6937L18.4743 9.84189L16.4743 3.84189Z"
      fill="white"
      stroke="#0D0F13"
      strokeLinecap="round"
    />
  </SvgIcon>
);

export default PoiIcon;
