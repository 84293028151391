const getPrettifiedMissionStateReason = (reason: string | undefined | null) => {
  if (reason === undefined || reason === null) return 'Reason: Unknown';

  const words = reason.split(/[ _-]+/).filter((word) => !!word);
  const lowerCaseWords = words.map((word) => word.toLowerCase());
  // input strings are on format "REASON_XXX_YYY", output string should be: "Reason: Xxx yyy"
  return lowerCaseWords
    .map((word, index) => {
      let modifiedWord;
      if (word === 'reason') {
        modifiedWord = 'Reason:';
      } else if (index === 1) {
        modifiedWord = word.charAt(0).toUpperCase() + word.slice(1);
      } else {
        modifiedWord = word;
      }
      return modifiedWord;
    })
    .join(' ');
};

export default getPrettifiedMissionStateReason;
