import { SvgIcon } from '@material-ui/core';
import CSS from 'csstype';
import { ICommonIconProps } from './CommonIconProps';

interface IProps extends ICommonIconProps {
  click: React.MouseEventHandler<SVGSVGElement> | undefined;
  style?: Pick<CSS.Properties, 'position' | 'left' | 'top' | 'width' | 'height' | 'color' | 'cursor'>;
}
export const Close = ({ click, className, style, testid }: IProps) => {
  return (
    <SvgIcon
      viewBox="0 0 18 18"
      fill="none"
      transform="scale(0.55)"
      onClick={click}
      className={className}
      style={style}
      data-testid={testid}
    >
      <path
        transform="translate(3, 3)"
        d="M11.5814 1.08026L10.9186 0.417169L5.99924 5.33539L1.08112 0.416138L0.418117 1.07895L5.33624 5.9982L0.416992 10.9164L1.0799 11.5794L5.99915 6.6612L10.9173 11.5805L11.5803 10.9176L6.66215 5.99839L11.5814 1.08026Z"
      />
    </SvgIcon>
  );
};
export default Close;
