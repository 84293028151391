import 'ol/ol.css';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Map as OlMap } from 'ol';
import { defaults as controlDefaults, ScaleLine } from 'ol/control';
import View from 'ol/View';
import OlTileLayer from 'ol/layer/Tile';
import OlSourceXYZ from 'ol/source/XYZ';

import { defaults as defaultInteractions, MouseWheelZoom } from 'ol/interaction';

import Events from '../../model/events';
import { generateCatchUp, rawData } from '../../model/vehicle/position/vehiclePositionAlgorithms';
import { topLayerZIndex } from './constants';
import createOLVehicleFeature from './createOLVehicleFeature';

import { displayVehicleLabelSetting as displayVehicleLabelSettingObservable } from '../../model/observables';
import { createTrackingFeature } from './useTrackerLabelsLayer';
import zoomAndCenterToContent from './zoomAndCenterToContent';
import { defaultMapInteractions, defaultMapClick } from './defaultMapInteractions';
import {
  pointerMove as setPosition,
  click as positionClick,
  addSource as addSourcePosition,
} from './modules/PositionModule';
import {
  poiPointerMove as setPoiPosition,
  click as poiPositionClick,
  addPoiSource as addPoiSourcePosition,
} from './modules/PoiPositionModule';
import {
  rotationFeatures as setRotation,
  click as rotationClick,
  addSource as addSourceRotation,
} from './modules/RotationModule';
import {
  rotationFeatures as setPoiRotation,
  click as poiRotationClick,
  addPoiSource as addPoiSourceRotation,
} from './modules/PoiRotationModule';
import { addSource as addSourceConfirmation } from './modules/ConfirmationModule';

export function createMap(config) {
  const {
    containerRef,
    setHoverEquipmentId,
    setSelectedEquipmentId,
    setSelectedPoiId,
    setSelectedZone,
    siteMapLayer,
    poiLayer,
    queueAndPaddockLayer,
    vehicleRouteLayer,
    destinationMarkerLayer,
    satelliteLayerVisible,
    clickAndDriveLayer,
    trackerLayer,
  } = config;

  const vehicleLayer = new VectorLayer({
    source: new VectorSource(),
    updateWhileAnimating: true,
    updateWhileInteracting: true,
    renderBuffer: 50000000,
  });
  const multiPurposeIconLayer = new VectorLayer({
    source: new VectorSource(),
    updateWhileAnimating: true,
    updateWhileInteracting: true,
  });
  const view = new View({
    zoom: 100,
  });

  const addBaseLayer = (type) => {
    const baseLayerSource = new OlSourceXYZ({
      url: 'https://a.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png',
    });
    const satelliteLayerSource = new OlSourceXYZ({
      maxZoom: 17,
      url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    });
    return new OlTileLayer({
      opacity: 0.7,
      preload: 8,
      source: type === 'base' ? baseLayerSource : satelliteLayerSource,
      visible: true,
      zIndex: 0,
      name: 'base-layer',
    });
  };

  const scaleLine = new ScaleLine({
    units: 'metric',
  });
  const mouseWheelZoom = new MouseWheelZoom({ useAnchor: false });

  const map = new OlMap({
    interactions: defaultInteractions().extend([mouseWheelZoom]),
    controls: controlDefaults({
      zoom: false,
    }).extend([scaleLine]),
    target: containerRef,
    layers: [
      siteMapLayer,
      poiLayer,
      queueAndPaddockLayer,
      vehicleRouteLayer,
      destinationMarkerLayer,
      multiPurposeIconLayer,
      clickAndDriveLayer,
      vehicleLayer,
      trackerLayer,
    ],
    view,
  });

  const clickAndDriveSource = clickAndDriveLayer.getSource();
  addSourcePosition(clickAndDriveSource);
  addSourceRotation(clickAndDriveSource);
  addSourceConfirmation(clickAndDriveSource);

  const selectOnMapSource = multiPurposeIconLayer.getSource();
  addPoiSourcePosition(selectOnMapSource);
  addPoiSourceRotation(selectOnMapSource);

  const interactionConfig = {
    map,
    vehicleLayer,
    poiLayer,
    queueAndPaddockLayer,
    setHoverEquipmentId,
    setSelectedEquipmentId,
    setSelectedPoiId,
    setSelectedZone,
  };

  // Default interactions for map (hover on vehicles and POIs)
  map.on('pointermove', defaultMapInteractions(interactionConfig));

  // Click and drive states
  map.on('pointermove', setPosition);
  map.on('pointermove', setRotation);
  map.on('pointermove', setPoiPosition);
  map.on('pointermove', setPoiRotation);

  map.on('click', (event) => {
    const position = map.getPixelFromCoordinate(event.coordinate);
    window.dispatchEvent(new CustomEvent(Events.MAP_CLICK, { detail: { position } }));
  });
  map.on('click', defaultMapClick(interactionConfig));
  map.on('click', positionClick);
  map.on('click', rotationClick);
  map.on('click', poiPositionClick);
  map.on('click', poiRotationClick);

  map.getLayers().forEach((layer) => layer.setZIndex(topLayerZIndex)); // These layers should be considered "on top"

  const baseLayer = addBaseLayer(satelliteLayerVisible ? 'satellite' : 'base');
  map.addLayer(baseLayer);
  baseLayer.setZIndex(0); // This is the "bottom" layer, always

  zoomAndCenterToContent(map);

  window.addEventListener(Events.MAP_AREA_CHANGED, () => map.updateSize());

  const setSatelliteLayerVisibility = (visible) => {
    map.getLayers().forEach((layer) => {
      if (layer.get('name') === 'base-layer') {
        map.removeLayer(layer);
      }
    });
    const newBaseLayer = addBaseLayer(visible ? 'satellite' : 'base');
    map.addLayer(newBaseLayer);
    newBaseLayer.setZIndex(0);
  };

  window.addEventListener(Events.ZOOM_IN, () => view.setZoom(view.getZoom() + 1));
  window.addEventListener(Events.ZOOM_OUT, () => view.setZoom(view.getZoom() - 1));

  return new Promise((resolve) => {
    resolve({
      map,
      vehicleLayer,
      mouseWheelZoom,
      poiLayer,
      queueAndPaddockLayer,
      setSatelliteLayerVisibility,
    });
  });
}

export function createOLVehicleFeatures(
  vehicleLayer,
  trackerLayer,
  map,
  equipmentStatuses,
  vehicleSmoothing,
  clickCallback,
) {
  equipmentStatuses.forEach((eq) => {
    const target = vehicleLayer
      .getSource()
      .getFeatures()
      .find((feature) => feature.getProperties().externalEquipmentReference === eq.externalEquipmentReference);
    if (!target) {
      const selectedAlgorithm = {
        OFF: () => rawData,
        'CATCH-UP': generateCatchUp,
      }[vehicleSmoothing]();
      const vehicleFeature = createOLVehicleFeature(eq.externalEquipmentReference, selectedAlgorithm, clickCallback);
      vehicleFeature.getProperties().fadeIn();
      vehicleLayer.getSource().addFeature(vehicleFeature);

      const tracker = createTrackingFeature(map, 2, trackerLayer, vehicleFeature, displayVehicleLabelSettingObservable);
      trackerLayer.getSource().addFeature(tracker);
    }
  });
}

export function updateOLVehicleFeatures(vehicleLayer, equipmentStatuses, selectedEquipmentId, hoverEquipmentId) {
  vehicleLayer
    .getSource()
    .getFeatures()
    .forEach((ft) => {
      const eq = equipmentStatuses.find(
        ({ externalEquipmentReference }) =>
          ft.getProperties().externalEquipmentReference === externalEquipmentReference,
      );
      if (eq) {
        const isSelected = eq.externalEquipmentReference === selectedEquipmentId;
        const isHover = eq.externalEquipmentReference === hoverEquipmentId;
        ft.getProperties().customUpdateSelected(eq, isSelected);
        ft.getProperties().customUpdateHover(eq, isHover);
      } else {
        ft.getProperties().remove(() => vehicleLayer.getSource().removeFeature(ft));

        const detail = { id: ft.getProperties().externalEquipmentReference };
        const event = new CustomEvent(Events.VEHICLE_REMOVED, { detail });
        window.dispatchEvent(event);
      }
    });
}

export function removeOLVehicleFeatures(vehicleLayer) {
  vehicleLayer
    .getSource()
    .getFeatures()
    .forEach((ft) => {
      vehicleLayer.getSource().removeFeature(ft);

      const detail = { id: ft.getProperties().externalEquipmentReference };
      const event = new CustomEvent(Events.VEHICLE_REMOVED, { detail });
      window.dispatchEvent(event);
    });
}
