import { useEffect, useState } from 'react';
import { compose, tap } from 'ramda';
import Cookies from 'js-cookie';
import { AwsRum } from 'aws-rum-web';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core';
import { ReactKeycloakProvider, useKeycloak } from '@react-keycloak/web';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  getAreasPromise,
  geojsonMapFilePromise,
  saveStaff,
  getStaffFg,
  initLog,
  doLog,
  LogClient,
  LogLevel,
  LogEntryType,
  getAppMonitorConfigurationPromise,
} from '@ats/graphql';
import { useObservable } from '@libreact/use-observable';
import keycloakClient from './config/keycloakclient';
import PrivateRoute from './config/PrivateRoute';
import geoJsonObservable from './model/map/observable/geoJson.observable';
import ThemeSelector from './theme/ThemeSelector.component';
import { configureAmplify } from './model/user';
import RiskMap from './components/RiskMap';
import MapContainer from './components/map/MapContainer';
import MapOverlayUI from './components/map/MapOverlayUI';
import AppBarContainer from './components/AppBar.container';
import LeftPanel from './components/left/LeftPanel';
import EquipmentPanel from './components/EquipmentPanel';
import InformationCluster from './components/InformationCluster/InformationCluster';
import PreviewCluster from './components/PreviewCluster';
import SettingsModal from './components/SettingsModal';
import LogDumpModal from './components/LogDumpModal';
import CameraView from './components/CameraView';
import useMissionStateCardOverview from './components/useMissionStateCardOverview';
import useCameraView from './components/useCameraView';
import { ConfirmationModuleComponent as ClickAndDriveConfirmationMenu } from './components/map/modules/ConfirmationModule';
import { ClickAndDrivePositionClickAwayContainer } from './components/map/modules/PositionModule';
import { ClickAndDriveRotationClickAwayContainer } from './components/map/modules/RotationModule';
import { ActivePoiPositionContainer } from './components/map/modules/PoiPositionModule';
import { ActivePoiRotationContainer } from './components/map/modules/PoiRotationModule';
import ModalContainer from './components/Modal.container';
import NotificationPopupContainer from './components/notification/NotificationPopup.container';
import ToastContainer from './components/Toast.container';
import PointOfInterestPanel from './components/pointOfInterest/PointOfInterestPanel';
import ZonePanel from './components/zone/ZonePanel';
import QueuePanel from './components/zone/QueuePanel';
import PaddockPanel from './components/zone/PaddockPanel';
import { SelectedType } from './model/type/Selected';

import routeObservable from './model/map/observable/route.observable';
import {
  selectedAreaId as selectedAreaIdObservable,
  selectedArea as selectedAreaObservable,
  selectedVehicleId as selectedVehicleIdObservable,
  selectedQueueId as selectedQueueIdObservable,
  selectedPaddockId as selectedPaddockIdObservable,
  selectedZoneId as selectedZoneIdObservable,
  missionViewContext as missionViewContextObservable,
  selectedPoiId as selectedPoiIdObservable,
  displayPoiLabelSetting as displayPoiLabelSettingObservable,
  displayVehicleLabelSetting as displayVehicleLabelSettingObservable,
} from './model/observables';
import Events from './model/events';
import environment from './config/environment';
import decipherErrorMessage from './model/decipherErrorMessage';
import { View, SubView, MissionView } from './model/ViewTypes';
import useNotifications from './components/useNotifications';
import theme from './theme/theme';

require('./debug'); // Nothing to import, just need it to be included

// eslint-disable-next-line no-console
export const trace = (msg) => tap((value) => console.log(msg, value)); // Perhaps belongs in a util-section.

const useStyles = makeStyles(
  {
    root: {
      display: 'flex', // Layouts the panels and map
      position: 'relative', // To place CameraView on
      '& > div': {
        flexShrink: 0,
        flexGrow: 0,
        flexBasis: '368px',
      },
      '& #map': {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 'auto',
      },
    },
  },
  { index: 1 },
);
const useVehicleViewStyles = makeStyles(
  {
    VehicleViewLayout: {
      display: 'flex', // Layouts the panels and map
      position: 'relative', // To place CameraView on
      backgroundColor: '#0D0F13',
      '& > div': {
        flexShrink: 0,
        flexGrow: 0,
        flexBasis: '368px',
      },
      '& #dashboard': {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 'auto',
        height: 'calc(100vh - 64px)',
        width: 'calc(100vw - 368px)',
        padding: theme.spacing(0, 8),
      },
      '& #riskmap': {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 'auto',
      },
    },
  },
  { index: 1 },
);

const MapLayout = (props) => {
  const { children } = props;
  const { root } = useStyles();
  return <div className={root}>{children}</div>;
};

const AppCore = () => {
  const { VehicleViewLayout } = useVehicleViewStyles();
  const { keycloak } = useKeycloak();
  const isAuthenticated = keycloak.authenticated;
  const hasUserRole = keycloak.tokenParsed?.customer?.roles?.includes('Driver');
  const [user, setUser] = useState(null);
  const [userExternalStaffReference, setUserExternalStaffReference] = useState(null);
  const [areas, setAreas] = useState([]);
  const [selectedAreaId, setSelectedAreaId] = useState(null);
  const [loadingMapFile, setloadingMapFile] = useState(false);
  const [selected, setSelected] = useState(null);
  const [view, setView] = useState(View.MapView);
  const [subView, setSubView] = useState(SubView.Dashboard);
  const [missionViewContext, setMissionViewContext] = useState(MissionView.Loading);
  const [hoverEquipmentId, setHoverEquipmentId] = useState(null);
  const [missionLabel, setMissionLabel] = useState('No Mission');
  const [snap, setSnap] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [logDumpOpen, setLogDumpOpen] = useState(false);
  const [smoothing, setSmoothing] = useState('CATCH-UP');
  const [alertsMuted, setAlertsMuted] = useState('ON');
  const [satelliteLayerVisible, setSatelliteLayerVisible] = useState(false);
  const [showPoiName, setShowPoiName] = useState('OFF');
  const [showVehicleName, setShowVehicleName] = useState('OFF');
  const missionStateCardOverview = useMissionStateCardOverview();
  const [cameraView, setCameraView] = useCameraView();
  const [leftPanelCollapsed, setLeftPanelCollapsed] = useState(false);

  const [, genericError] = useObservable(routeObservable, null); // Just detect and catch errors

  const [error, setError] = useState();

  const selectedEquipmentId = selected?.type === SelectedType.Vehicle ? selected.id : null;
  const selectedPoiId = selected?.type === SelectedType.Poi ? selected.id : null;
  // const selectedZoneId = selected?.type === SelectedType.Zone ? selected.id : null;
  const setSelectedEquipmentId = (id) => {
    setSelected(id ? { type: SelectedType.Vehicle, id } : null);
  };
  const setSelectedPoiId = (id) => {
    setSelected(id ? { type: SelectedType.Poi, id } : null);
  };
  let rumClient = null;

  /**
   * Set either a zone, a queue or paddock. As these are deeply related,
   * they are handled within the same logic instead of having separate setters for them
   */
  const setSelectedZone = (zoneQueueOrPaddockId, zoneQueueOrPaddock) => {
    if (zoneQueueOrPaddock === 'Zone') {
      selectedZoneIdObservable.next(zoneQueueOrPaddockId);
      setSelected({ type: SelectedType.Zone, id: zoneQueueOrPaddockId });
      if (selectedQueueIdObservable.getValue() !== null) selectedQueueIdObservable.next(null);
      if (selectedPaddockIdObservable.getValue() !== null) selectedPaddockIdObservable.next(null);
      return;
    }

    if (zoneQueueOrPaddock === 'Queue') {
      selectedQueueIdObservable.next(zoneQueueOrPaddockId);
      setSelected({ type: SelectedType.Queue, id: zoneQueueOrPaddockId });
      if (selectedZoneIdObservable.getValue() !== null) selectedZoneIdObservable.next(null);
      if (selectedPaddockIdObservable.getValue() !== null) selectedPaddockIdObservable.next(null);
      return;
    }

    if (zoneQueueOrPaddock === 'Paddock') {
      selectedPaddockIdObservable.next(zoneQueueOrPaddockId);
      setSelected({ type: SelectedType.Paddock, id: zoneQueueOrPaddockId });
      if (selectedZoneIdObservable.getValue() !== null) selectedZoneIdObservable.next(null);
      if (selectedQueueIdObservable.getValue() !== null) selectedQueueIdObservable.next(null);
    }
  };

  // Reset selected zone/queue/paddock if nothing or some other entity is selected
  useEffect(() => {
    if (
      selected?.type === SelectedType.Zone ||
      selected?.type === SelectedType.Queue ||
      selected?.type === SelectedType.Paddock
    )
      return;
    if (selectedZoneIdObservable.getValue() !== null) selectedZoneIdObservable.next(null);
    if (selectedQueueIdObservable.getValue() !== null) selectedQueueIdObservable.next(null);
    if (selectedPaddockIdObservable.getValue() !== null) selectedPaddockIdObservable.next(null);
  }, [selected]);

  useEffect(() => {
    configureAmplify();
  }, []);

  const isMap = view === View.MapView;
  const isVehicleView = view === View.VehicleView;
  const isDashboard = subView === SubView.Dashboard;

  //
  // Setup logging
  //
  useEffect(() => {
    // Will be performed at page load

    initLog(LogClient.ICEUI);

    const callback = (e) => {
      if (e.ctrlKey && e.keyCode === 77 /* ctrl-m */) {
        // User triggered "marker"
        doLog(LogLevel.Debug, {
          type: LogEntryType.User,
          message: 'User marker set',
        });
      }
      if (e.keyCode === 80 /* p */) {
        // Bring up paddock panel
        setSelected({ type: SelectedType.Paddock });
      }
      if (e.keyCode === 81 /* q */) {
        // Bring up queue panel
        setSelected({ type: SelectedType.Queue });
      }
      if (e.keyCode === 90 /* z */) {
        // Bring up zone panel
        setSelected({ type: SelectedType.Zone });
      }
    };

    window.addEventListener('keydown', callback);
    return () => window.removeEventListener('keydown', callback);
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      setError('Unable to verify user authentication');
      return;
    }
    if (!hasUserRole) {
      setError(
        'You do not have the correct user role to access any sites. Contact the manager of your FMP customer and request the role.',
      );
      return;
    }
    const token = keycloak.tokenParsed;

    getStaffFg(token.customer.external_staff_reference).then((staff) => {
      if (staff === null)
        saveStaff({
          givenName: token.given_name,
          familyName: token.family_name,
          externalStaffReference: token.customer.external_staff_reference,
        });
    });

    setUser(token);
    setUserExternalStaffReference(token.customer.external_staff_reference || null);

    if (environment.buildInfo) {
      doLog(LogLevel.Debug, {
        type: LogEntryType.UI,
        message: `ICE UI version used: ${environment.buildInfo.lastCommitHash}`,
      });
    }

    doLog(LogLevel.Debug, {
      type: LogEntryType.UI,
      message: `User authenticated with roles ${
        keycloak.tokenParsed?.customer?.roles ? keycloak.tokenParsed?.customer?.roles.join(':') : '-'
      }`,
    });

    window.history.pushState(
      null,
      null,
      // PUBLIC_URL is different from '/' when we are browsing a sub-folder for a merge request (identified by MR #)
      process.env.PUBLIC_URL !== '/' ? `${process.env.PUBLIC_URL}/index.html` : '/',
    );
  }, [isAuthenticated, hasUserRole, keycloak.tokenParsed]);

  const [notifications, markAsNotVisible, markAsRead, notificationOpen, setNotificationOpen] = useNotifications(
    selectedEquipmentId,
    selectedAreaId,
  );

  // Pre-emptively remove the vehicles so they do not "linger" during loading
  useEffect(() => window.dispatchEvent(new Event(Events.REMOVE_ALL_VEHICLES)), [selectedAreaId]);

  // Update the observables
  useEffect(() => {
    // This is a slave, meaning the react state is the single source of truth and there is never a two way binding
    selectedAreaIdObservable.next(selectedAreaId);
    geoJsonObservable.next(null);
    setloadingMapFile(true);

    // Avoid Initial loading of mapfile for areaId === null
    if (selectedAreaId != null) {
      // Fetches the GeoJSON map file for an area
      geojsonMapFilePromise({
        areaId: selectedAreaId,
      })
        .then((response) => {
          const { type, features } = response[0];
          const feature = [];

          features.forEach((feat) => feature.push(JSON.parse(feat)));
          geoJsonObservable.next({
            type,
            features: feature,
          });

          setloadingMapFile(false);
        })
        .catch(() => {
          setloadingMapFile(false);
          window.dispatchEvent(new Event(Events.MAP_DOWNLOAD_FAILED));
        });
    }
  }, [selectedAreaId]);

  useEffect(() => {
    if (!isAuthenticated) return;
    if (window.location.hostname === 'localhost') return;
    getAppMonitorConfigurationPromise()
      .then((rumConfiguration) => {
        if (rumConfiguration.id === '') return null;

        const {
          guestRoleArn,
          identityPoolId,
          allowCookies,
          enableXRay,
          sessionSampleRate,
        } = rumConfiguration.appMonitorConfiguration;
        const { endpoint, version, region } = rumConfiguration.tags;

        const applicationId = rumConfiguration.id;
        const applicationVersion = version;

        // eslint-disable-next-line react-hooks/exhaustive-deps
        rumClient = new AwsRum(applicationId, applicationVersion, region, {
          sessionSampleRate,
          guestRoleArn,
          identityPoolId,
          endpoint,
          telemetries: [['errors', { stackTraceLength: 10000 }], 'http'],
          allowCookies,
          enableXRay,
        });

        initLog(
          LogClient.ICEUI,
          rumClient
            ? {
                callbackFn: (logEntry) => {
                  rumClient.recordEvent(`ice_${logEntry.level}_event`, {
                    details: logEntry,
                  });
                },
              }
            : undefined,
        );

        doLog(LogLevel.Debug, {
          type: LogEntryType.UI,
          message: `Cloudwatch session ${rumClient?.eventCache?.sessionManager?.session?.sessionId}`,
        });
      })
      .catch(() => {
        doLog(LogLevel.Error, {
          type: LogEntryType.UI,
          message: `Error in rum monitor initialization`,
        });
      });
  }, [isAuthenticated]);

  useEffect(() => {
    // This is a slave, meaning the react state is the single source of truth and there is never a two way binding

    // Keep track of all info about the selected area in an observable
    const area = areas.find(({ id }) => id === selectedAreaId) || null;
    selectedAreaObservable.next(area);

    if (area) {
      doLog(LogLevel.Debug, {
        type: LogEntryType.UI,
        message: `Selected area ${area.displayName} (${selectedAreaId})`,
      });
    }
  }, [selectedAreaId, areas]);

  useEffect(() => {
    // This is a slave, meaning the react state is the single source of truth and there is never a two way binding
    selectedVehicleIdObservable.next(selectedEquipmentId);
  }, [selectedEquipmentId]);

  useEffect(() => {
    // This is a slave, meaning the react state is the single source of truth and there is never a two way binding
    missionViewContextObservable.next(missionViewContext);
  }, [missionViewContext]);

  useEffect(() => {
    // This is a slave, meaning the react state is the single source of truth and there is never a two way binding
    selectedPoiIdObservable.next(selectedPoiId);
  }, [selectedPoiId]);

  useEffect(() => {
    // This is a slave, meaning the react state is the single source of truth and there is never a two way binding
    displayPoiLabelSettingObservable.next(showPoiName);
  }, [showPoiName]);

  useEffect(() => {
    // This is a slave, meaning the react state is the single source of truth and there is never a two way binding
    displayVehicleLabelSettingObservable.next(showVehicleName);
  }, [showVehicleName]);

  useEffect(() => {
    const callback = (event) => setError(event.detail.msg);
    window.addEventListener(Events.GENERIC_ERROR, callback);
    return () => window.removeEventListener(Events.GENERIC_ERROR, callback);
  }, []);

  useEffect(() => {
    if (!genericError) return;
    compose(setError, decipherErrorMessage)(genericError);
    doLog(LogLevel.Debug, {
      type: LogEntryType.UI,
      message: genericError,
    });
  }, [genericError]);

  useEffect(() => {
    const listener = () =>
      setError(
        'Unfortunately, it was not possible to load the map. Please contact the configuration manager to get help with uploading the map.',
      );
    window.addEventListener(Events.MAP_DOWNLOAD_FAILED, listener);
    return () => window.removeEventListener(Events.MAP_DOWNLOAD_FAILED, listener);
  }, []);

  useEffect(() => {
    // Clear old cookies
    Cookies.remove('vehicle-smoothing');
    Cookies.remove('alerts-muted');
    Cookies.remove('logging');

    const smoothingCookie = Cookies.get('vehicle-smoothing-algorithm');
    if (smoothingCookie) {
      setSmoothing(smoothingCookie);
    }

    const alertsMutedCookie = Cookies.get('alerts-muted-v2');
    if (alertsMutedCookie) {
      setAlertsMuted(alertsMutedCookie);
    }
  }, []);

  useEffect(() => {
    Cookies.set('vehicle-smoothing-algorithm', smoothing);
  }, [smoothing]);

  useEffect(() => {
    Cookies.set('alerts-muted-v2', alertsMuted);
  }, [alertsMuted]);

  useEffect(() => {
    window.dispatchEvent(new Event(Events.MAP_AREA_CHANGED));
  }, [selected]);

  useEffect(() => {
    if (!user) return;
    getAreasPromise()
      .then(setAreas)
      .catch((e) => {
        setError('Unable to fetch areas');
        // eslint-disable-next-line no-console
        console.error(e);
      });
  }, [user]);

  useEffect(() => {
    if (areas && areas.length > 0 && !selectedAreaId) {
      const savedAreaId = Cookies.get('save-area');
      const areaId = areas.find(({ id }) => id === savedAreaId) === undefined ? areas[0].id : savedAreaId;
      setSelectedAreaId(areaId);
    }
  }, [areas, selectedAreaId]);

  useEffect(() => {
    if (!selectedAreaId) return;
    Cookies.set('save-area', selectedAreaId, { expires: 60 });
  }, [selectedAreaId]);

  useEffect(() => {
    const poiNameCookie = Cookies.get('show-name-of-poi');
    const vehicleNameCookie = Cookies.get('show-name-of-vehicle');
    if (poiNameCookie) setShowPoiName(poiNameCookie);

    if (vehicleNameCookie) setShowVehicleName(vehicleNameCookie);
  }, []);

  useEffect(() => {
    if (!showPoiName) return;
    Cookies.set('show-name-of-poi', showPoiName);
  }, [showPoiName]);

  useEffect(() => {
    if (!showVehicleName) return;
    Cookies.set('show-name-of-vehicle', showVehicleName);
  }, [showVehicleName]);

  useEffect(() => {
    const satelliteLayerCookie = Cookies.get('satellite-layer');
    if (satelliteLayerCookie && satelliteLayerCookie === 'TRUE') setSatelliteLayerVisible(true);
    else setSatelliteLayerVisible(false);
  }, []);

  useEffect(() => {
    Cookies.set('satellite-layer', satelliteLayerVisible ? 'TRUE' : 'FALSE');
  }, [satelliteLayerVisible]);

  return (
    <CssBaseline>
      <sdds-theme>
        {user ? (
          <>
            <AppBarContainer
              openSettingsPanel={() => setSettingsOpen(true)}
              openLogDumpPanel={() => setLogDumpOpen(true)}
              notificationPanelState={[notificationOpen, setNotificationOpen]}
              markAsNotVisible={markAsNotVisible}
              notifications={notifications}
              selectedEquipmentState={[selectedEquipmentId, setSelectedEquipmentId]}
              setSnap={setSnap}
              selectedAreaId={selectedAreaId}
              viewState={[view, setView]}
            />
            <NotificationPopupContainer
              notifications={notifications}
              markAsRead={markAsRead}
              setSnap={setSnap}
              selectedEquipmentIdState={[selectedEquipmentId, setSelectedEquipmentId]}
              markAsNotVisible={markAsNotVisible}
              selectedAreaId={selectedAreaId}
              notificationOpen={notificationOpen}
            />
            <ToastContainer />
            <ModalContainer />
            <SettingsModal
              settingsOpenState={[settingsOpen, setSettingsOpen]}
              smoothingState={[smoothing, setSmoothing]}
              alertsMutedState={[alertsMuted, setAlertsMuted]}
            />
            <LogDumpModal logDumpOpenState={[logDumpOpen, setLogDumpOpen]} />
            {isVehicleView && (
              <>
                <div className={VehicleViewLayout}>
                  <EquipmentPanel
                    darkMode
                    areaId={selectedAreaId}
                    selectedEquipmentState={[selectedEquipmentId, setSelectedEquipmentId]}
                    missionViewContextState={[missionViewContext, setMissionViewContext]}
                    setMissionLabel={setMissionLabel}
                    user={user}
                    userExternalStaffReference={userExternalStaffReference}
                    missionStateCardOverview={missionStateCardOverview}
                    cameraViewState={[cameraView, setCameraView]}
                  />
                  {isDashboard ? (
                    <div id="dashboard">
                      <PreviewCluster
                        view={view}
                        subviewState={[subView, setSubView]}
                        areaId={selectedAreaId}
                        externalEquipmentReference={selectedEquipmentId}
                      />
                      <InformationCluster areaId={selectedAreaId} externalEquipmentReference={selectedEquipmentId} />
                    </div>
                  ) : (
                    <RiskMap
                      setSubView={setSubView}
                      areaId={selectedAreaId}
                      externalEquipmentReference={selectedEquipmentId}
                    />
                  )}
                </div>
              </>
            )}
            {isMap && (
              <>
                <MapLayout>
                  <LeftPanel
                    areas={areas}
                    areaId={selectedAreaId}
                    selectedAreaState={[selectedAreaId, setSelectedAreaId]}
                    selectedEquipmentState={[selectedEquipmentId, setSelectedEquipmentId]}
                    hoverEquipmentState={[hoverEquipmentId, setHoverEquipmentId]}
                    snapState={[snap, setSnap]}
                    alertsMuted={alertsMuted}
                    collapseState={[leftPanelCollapsed, setLeftPanelCollapsed]}
                  />
                  <MapContainer
                    vehicleSmoothing={smoothing}
                    areaId={selectedAreaId}
                    selectedEquipmentState={[selectedEquipmentId, setSelectedEquipmentId]}
                    selectedPoi={[selectedPoiId, setSelectedPoiId]}
                    selectedZone={setSelectedZone}
                    hoverEquipmentState={[hoverEquipmentId, setHoverEquipmentId]}
                    satelliteLayerVisible={satelliteLayerVisible}
                    snapState={[snap, setSnap]}
                    missionLabel={missionLabel}
                    throwError={setError}
                  />
                  <EquipmentPanel
                    darkMode={false}
                    areaId={selectedAreaId}
                    selectedEquipmentState={[selectedEquipmentId, setSelectedEquipmentId]}
                    missionViewContextState={[missionViewContext, setMissionViewContext]}
                    setMissionLabel={setMissionLabel}
                    user={user}
                    userExternalStaffReference={userExternalStaffReference}
                    missionStateCardOverview={missionStateCardOverview}
                    cameraViewState={[cameraView, setCameraView]}
                  />
                  <PointOfInterestPanel
                    areaId={selectedAreaId}
                    selectedPoiId={selectedPoiId}
                    setSelected={setSelected}
                  />
                  {selected?.type === SelectedType.Zone && (
                    <ZonePanel
                      setSelected={setSelected}
                      selectedEquipmentState={[selectedEquipmentId, setSelectedEquipmentId]}
                    />
                  )}
                  {selected?.type === SelectedType.Queue && (
                    <QueuePanel
                      setSelected={setSelected}
                      selectedEquipmentState={[selectedEquipmentId, setSelectedEquipmentId]}
                    />
                  )}
                  {selected?.type === SelectedType.Paddock && (
                    <PaddockPanel
                      setSelected={setSelected}
                      selectedEquipmentState={[selectedEquipmentId, setSelectedEquipmentId]}
                    />
                  )}
                  {cameraView && <CameraView offset={leftPanelCollapsed ? 68 : 368} />}
                </MapLayout>
                <MapOverlayUI
                  snapState={[snap, setSnap]}
                  loadingMapFileState={loadingMapFile}
                  selectedEquipmentId={selectedEquipmentId}
                  missionViewContext={missionViewContext}
                  rightPanelOpen={
                    !!selectedEquipmentId ||
                    !!selectedPoiId ||
                    selected?.type === SelectedType.Zone ||
                    selected?.type === SelectedType.Queue ||
                    selected?.type === SelectedType.Paddock
                  }
                  satelliteVisibilityState={[satelliteLayerVisible, setSatelliteLayerVisible]}
                  showPoiNameState={[showPoiName, setShowPoiName]}
                  showVehicleNameState={[showVehicleName, setShowVehicleName]}
                />
                <ClickAndDriveConfirmationMenu />
                <ClickAndDrivePositionClickAwayContainer />
                <ClickAndDriveRotationClickAwayContainer />
                <ActivePoiPositionContainer />
                <ActivePoiRotationContainer />
                <Dialog open={!!error} onClose={() => setError(null)}>
                  <DialogTitle>Error</DialogTitle>
                  <DialogContent>
                    <DialogContentText>{error}</DialogContentText>
                  </DialogContent>
                </Dialog>
              </>
            )}
          </>
        ) : (
          <div style={{ width: '100vh', height: '100vh', display: 'block' }}>
            <Dialog open={!!error} onClose={() => keycloak.logout()}>
              <DialogTitle>Error</DialogTitle>
              <DialogContent>
                <DialogContentText>{error}</DialogContentText>
              </DialogContent>
            </Dialog>
          </div>
        )}
      </sdds-theme>
    </CssBaseline>
  );
};

const initOptions = {
  onLoad: 'login-required',
  pkceMethod: 'S256', // use pkce
  flow: 'standard', // code
  enableLogging: false,
  checkLoginIframe: false,
};

const App = () => (
  <ReactKeycloakProvider
    authClient={keycloakClient}
    initOptions={initOptions}
    onTokens={({ token }) => {
      window.accessToken = token;
      window.isAuthenticated = true;
    }}
  >
    <ThemeSelector>
      <PrivateRoute>
        <AppCore />
      </PrivateRoute>
    </ThemeSelector>
  </ReactKeycloakProvider>
);

export default App;
