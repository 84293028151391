import { ActionList as ActionListType, Mission, Mission_actions as MissionActions, Poi } from '@ats/graphql';
import { Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { memo } from 'react';
import { Circle, Line } from '../icons';
import { grey100, grey300 } from '../../theme/color';
import { IMergedCommand } from '../../model/mission/overview/mergeMissionActionCommands';

import getCommandLabel from '../../model/mission/overview/getCommandLabel';

export const useStyles = makeStyles(
  {
    row: {
      display: 'flex',
    },
    iconColumn: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      marginRight: '16px',
    },
    circle: {
      fill: grey300,
      width: '16px',
      height: '16px',
    },
    line: {
      height: '16px',
      marginBottom: '8px',
      marginTop: '8px',
    },
    typographyDescription: {
      color: grey100,
      marginBottom: '32px',
    },
    typographyCommand: {
      color: grey300,
    },
  },
  { index: 1 },
);

interface IProps {
  actionList: ActionListType;
  missionTags: Mission['tags'];
  missionActionListId: Mission['actionListId'];
  commands: ReadonlyArray<IMergedCommand>;
  missionActionTags: MissionActions['tags'];
  pois: ReadonlyArray<Poi>;
}

const PreviewActionList = (props: IProps) => {
  const { actionList, missionTags, missionActionListId, commands, missionActionTags, pois } = props;
  const { description, displayName } = actionList;
  const { row, iconColumn, circle, line, typographyCommand, typographyDescription } = useStyles();

  return (
    <>
      {missionTags && missionTags.description && missionTags.description !== missionTags.displayText ? (
        <Typography
          className={clsx(typographyDescription, 'sdds-detail-02')}
          data-testid="mission-actions-preview-description"
        >
          {missionTags.description}
        </Typography>
      ) : (
        !!description &&
        description !== displayName && (
          <Typography className={clsx(typographyDescription, 'sdds-detail-02')} data-testid="mission-actions-preview">
            {description}
          </Typography>
        )
      )}
      {missionActionTags.map((missionActionTag: MissionActions['tags'], index: number) =>
        missionActionTag && missionActionTag.displayText ? (
          <div className={row} key={`${commands[index].actionIndex}-${commands[index].commandIndex}`}>
            <div className={iconColumn}>
              <Circle className={circle} />
              {index < missionActionTags.length - 1 && <Line color={grey300} className={line} />}
            </div>
            <Typography
              className={clsx(typographyCommand, 'sdds-detail-02')}
              data-testid={`mission-action-${getCommandLabel(missionActionListId, commands[index], pois)}`}
            >
              {missionActionTag.displayText}
            </Typography>
          </div>
        ) : (
          <div className={row} key={`${commands[index].actionIndex}-${commands[index].commandIndex}`}>
            <div className={iconColumn}>
              <Circle className={circle} />
              {index < commands.length - 1 && <Line color={grey300} className={line} />}
            </div>
            <Typography
              className={clsx(typographyCommand, 'sdds-detail-02')}
              data-testid={`mission-action-${getCommandLabel(missionActionListId, commands[index], pois)}`}
            >
              {getCommandLabel(missionActionListId, commands[index], pois)}
            </Typography>
          </div>
        ),
      )}
    </>
  );
};

export default memo(PreviewActionList);
