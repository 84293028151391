import { t } from 'ttag';
import { DegradationPlanAction } from '@ats/graphql';

const getDegradationActionLabel = (action: DegradationPlanAction) => {
  switch (action) {
    case DegradationPlanAction.PanicStop:
      return t`Panic stop`;
    case DegradationPlanAction.ExtremeBrakeAlongPath:
      return t`Extreme brake along path`;
    case DegradationPlanAction.HardBrakeAlongPath:
      return t`Hard brake along path`;
    case DegradationPlanAction.SoftBrakeAlongPath:
      return t`Soft brake along path`;
    case DegradationPlanAction.ReducedSpeed:
      return t`Reduced speed`;
    case DegradationPlanAction.None:
      return t`Warning`;
    default:
      return t`Unknown`;
  }
};
export default getDegradationActionLabel;
