import { useEffect, useMemo } from 'react';
import {
  Paddock,
  usePaddockWithSpots,
  QueueList,
  useQueueList,
  useSiteRelation,
  SiteRelation_queueRelations as SiteRelationQueueRelation,
  QueueRelationType,
} from '@ats/graphql';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { fromLonLat } from 'ol/proj';
import { Style, Circle, Stroke } from 'ol/style';
import OlIcon from 'ol/style/Icon';
import { useObservable } from '@libreact/use-observable';
import scaleModifier from './scaleModifier';

import { topLayerZIndex } from './constants';

import queueIcon from './Icons/QueueIcon';
import paddockIcon from './Icons/Paddock';
import {
  selectedQueueId as selectedQueueIdObservable,
  selectedPaddockId as selectedPaddockIdObservable,
  selectedZoneId as selectedZoneIdObservable,
} from '../../model/observables';

interface IPaddockExtended extends Paddock {
  zoneId: string | null;
  selected: boolean;
  displayName: string;
  latitude: number;
  longitude: number;
}

interface IQueueListExtended extends QueueList {
  selected: boolean;
  displayName: string;
  zoneId: string | null;
}

// CAV-62327 TODO - Do we want to differentiate the looks of a queue or paddock -
// that is part of a zone from one that is stand-alone?!?

// ** Post ** CAV-62327 TODO - We would like to be able to see the name of the zone/queue/paddock in the map, as with POIs (when enabled)
// It has been prepared in by adding the getDisplayName() property, but enabling it and actually rendering is left to do

const createQueues = ({ id, latitude, longitude, zoneId, displayName, selected }: IQueueListExtended) => {
  let hover = false;
  const queueFeature = new Feature({
    id: zoneId ?? id,
    name: zoneId ? 'Zone' : 'Queue',
  });
  const queueIconStyle = new Style({
    image: new OlIcon({
      src: `data:image/svg+xml;utf8,${encodeURIComponent(queueIcon)}`,
      opacity: 1,
    }),
  });

  queueFeature.setStyle((_feature, resolution) => {
    const hoverModifier = hover ? 1.1 : 1;
    const scale = scaleModifier(resolution) * hoverModifier;
    queueIconStyle.getImage().setScale(scale);
    const styles: Style[] = [queueIconStyle];
    if (selected) {
      styles.push(
        new Style({
          image: new Circle({
            radius: scaleModifier(resolution) * 20,
            stroke: new Stroke({ color: 'white', width: 1.5 }),
          }),
        }),
      );
    }
    return styles;
  });

  const point = new Point(fromLonLat([longitude, latitude]));
  queueFeature.setGeometry(point);

  queueFeature.setProperties({
    setHover: (_hover: boolean) => {
      hover = _hover;
      queueFeature.changed();
    },
    getDisplayName: () => displayName,
  });

  return queueFeature;
};

const createPaddocks = ({ paddockId, latitude, longitude, zoneId, displayName, selected }: IPaddockExtended) => {
  let hover = false;

  const paddockFeature = new Feature({
    id: zoneId ?? paddockId,
    name: zoneId ? 'Zone' : 'Paddock',
  });
  const paddockIconStyle = new Style({
    image: new OlIcon({
      src: `data:image/svg+xml;utf8,${encodeURIComponent(paddockIcon)}`,
      opacity: 1,
    }),
  });

  paddockFeature.setStyle((_feature, resolution) => {
    const hoverModifier = hover ? 1.1 : 1;
    const scale = scaleModifier(resolution) * hoverModifier;
    paddockIconStyle.getImage().setScale(scale);
    const styles: Style[] = [paddockIconStyle];
    if (selected) {
      styles.push(
        new Style({
          image: new Circle({
            radius: scaleModifier(resolution) * 20,
            stroke: new Stroke({ color: 'white', width: 1.5 }),
          }),
        }),
      );
    }
    return styles;
  });

  const point = new Point(fromLonLat([longitude, latitude]));
  paddockFeature.setGeometry(point);
  paddockFeature.setProperties({
    setHover: (_hover: boolean) => {
      hover = _hover;
      paddockFeature.changed();
    },
    getDisplayName: () => displayName,
  });

  return paddockFeature;
};

export default function useQueueAndPaddockLayer(areaId: string | null) {
  const source = useMemo(() => new VectorSource(), []);
  const layer = useMemo(() => new VectorLayer({ source }), [source]);
  const [queues] = useQueueList({ areaId });
  const [paddocks] = usePaddockWithSpots({ areaId });

  // We need the queue relations to find the type of the zone, as well as the id of the queue and paddock
  const [siteRelation] = useSiteRelation({ areaId });

  const [selectedZoneId] = useObservable(selectedZoneIdObservable, null);
  const [selectedQueueId] = useObservable(selectedQueueIdObservable, null);
  const [selectedPaddockId] = useObservable(selectedPaddockIdObservable, null);

  // Ideally we would like to alter the zIndex for just the selected queue/paddock,
  // but it didn't work so we adjust the zIndex for the whole layer instead
  const zIndex = selectedZoneId || selectedQueueId || selectedPaddockId ? topLayerZIndex + 1 : topLayerZIndex;
  useEffect(() => {
    layer.setZIndex(zIndex);
  }, [layer, zIndex]);

  useEffect(() => {
    // Filter the queue relations so that we get DUMPING_ZONE first as queues might (?) exist in both a
    // dumping zone and a loading zone at the same time
    const queueRelations: SiteRelationQueueRelation[] | null =
      siteRelation && siteRelation.length && siteRelation[0].queueRelations
        ? [...siteRelation[0].queueRelations].sort((a: SiteRelationQueueRelation, b: SiteRelationQueueRelation) =>
            // eslint-disable-next-line no-nested-ternary
            a.type === QueueRelationType.DUMPING_ZONE && b.type === QueueRelationType.LOADING_ZONE
              ? -1
              : a.type === QueueRelationType.LOADING_ZONE && b.type === QueueRelationType.DUMPING_ZONE
              ? 1
              : 0,
          )
        : null;

    // If a zone is selected we will use it to get the queue/paddock which then is also selected
    const zone = selectedZoneId && queueRelations ? queueRelations.find((qr) => qr.id === selectedZoneId) : null;

    const queueId = zone ? zone.queueId : selectedQueueId;
    const paddockId = zone ? zone.paddockId : selectedPaddockId;

    // Get the coordinates for the paddock from the spots
    const paddocksExtended: IPaddockExtended[] = paddocks
      ? paddocks.map((p) => {
          // Check if this paddock belongs to a zone
          const zone = queueRelations
            ? queueRelations.find((qr: SiteRelationQueueRelation) => qr.paddockId === p.paddockId)
            : null;
          return {
            ...p,
            zoneId: zone ? zone.id : null,
            selected: paddockId && paddockId === p.paddockId,
            // Set the display name for the paddock, however the rendering of it is not implemented yet
            displayName: zone ? zone.displayName : `${p.totalSpots} spots`,
            latitude: p.spots ? p.spots[0].spot.latitude : 0,
            longitude: p.spots ? p.spots[0].spot.longitude : 0,
          } as IPaddockExtended;
        })
      : [];

    const queueListExtended: IQueueListExtended[] = queues
      ? queues.map((q) => {
          // Check if this queue belongs to a zone
          const zone = queueRelations ? queueRelations.find((qr) => qr.queueId === q.id) : null;
          // Get the name of the queue if available in the meta data
          let queueDisplayName = 'Unknown queue';
          if (q.metaData) {
            try {
              const metaData = JSON.parse(q.metaData);
              if ('displayName' in metaData) queueDisplayName = metaData.displayName;
              // eslint-disable-next-line no-empty
            } catch {}
          }
          return {
            ...q,
            selected: queueId && queueId === q.id,
            zoneId: zone ? zone.id : null,
            // Set the display name for the queue, however the rendering of it is not implemented yet
            displayName: zone ? zone.displayName : queueDisplayName,
          } as IQueueListExtended;
        })
      : [];

    source.clear();
    const queueFeatures = queueListExtended ? queueListExtended.map(createQueues) : [];
    const paddockFeatures = paddocksExtended.length !== 0 ? paddocksExtended.map(createPaddocks) : [];
    source.addFeatures(queueFeatures);
    source.addFeatures(paddockFeatures);
    // We don't need to check if paddocksExtended has changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source, queues, paddocks, siteRelation, selectedQueueId, selectedPaddockId, selectedZoneId]);

  return layer;
}
