import type { Feature, Geometry } from 'geojson';
import GeoJSON from 'ol/format/GeoJSON';
import type { DeepReadonly } from 'ts-essentials';
import type RoutedFeature from '../RoutedFeature';
import type IFeatureProperties from '../../IFeatureProperties';

const geoJsonReader = new GeoJSON();

const getIndexOpenArea = (
  route: ReadonlyArray<RoutedFeature>,
  openArea: DeepReadonly<Feature<Geometry, IFeatureProperties>>,
): number => {
  const openAreaGeometry = geoJsonReader.readFeature(openArea).getGeometry();

  return route.findIndex(
    ({ geometry: { coordinates }, properties: { routeKnown } }) =>
      !routeKnown &&
      !!openAreaGeometry &&
      (openAreaGeometry.intersectsCoordinate(coordinates[0] as number[]) ||
        openAreaGeometry.intersectsCoordinate(coordinates[1] as number[])),
  );
};

export default getIndexOpenArea;
