import type { Feature, FeatureCollection, Geometry } from 'geojson';
import GeoJSON from 'ol/format/GeoJSON';
import type { DeepReadonly } from 'ts-essentials';
import type ICoordinates from './ICoordinates';
import type IFeatureProperties from '../IFeatureProperties';

const geoJsonReader = new GeoJSON();

const getOpenArea = (
  { latitude, longitude }: ICoordinates,
  { features }: DeepReadonly<FeatureCollection<Geometry, IFeatureProperties>>,
): DeepReadonly<Feature<Geometry, IFeatureProperties>> | null =>
  features.find((feature) => {
    return (
      feature.properties?.type === 'open_area_boundary' &&
      geoJsonReader.readFeature(feature).getGeometry()?.intersectsCoordinate([longitude, latitude])
    );
  }) || null;

export default getOpenArea;
