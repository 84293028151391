interface IImageScale {
  (imageWidth: number | null, imageHeight: number | null, canvasWidth: number | null, canvasHeight: number | null): {
    width: number;
    height: number;
    left: number;
    top: number;
  };
}

export const scaleImage: IImageScale = (imageWidth, imageHeight, canvasWidth, canvasHeight) => {
  const result = { width: 0, height: 0, left: 0, top: 0 };

  if (!canvasWidth || !canvasHeight || !imageWidth || !imageHeight) return result;

  if (imageWidth <= 0 || imageHeight <= 0 || canvasWidth <= 0 || canvasHeight <= 0) {
    return result;
  }

  const zoomFactor = Math.min(canvasWidth / imageWidth, canvasHeight / imageHeight);

  result.width = Math.floor(zoomFactor * imageWidth);
  result.height = Math.floor(zoomFactor * imageHeight);
  result.left = Math.floor((canvasWidth - result.width) / 2);
  result.top = Math.floor((canvasHeight - result.height) / 2);

  return result;
};

export default scaleImage;
