import { MissionInput, previewMission } from '@ats/graphql';
import { equals } from 'ramda';
import { from, of } from 'rxjs';
import { t } from 'ttag';

import { distinctUntilChanged, catchError, switchMap, shareReplay } from 'rxjs/operators';
import { previewMissionInput } from '../observables';
import Events from '../events';

const previewMissionObservable = previewMissionInput.pipe(
  distinctUntilChanged<MissionInput | null>(equals),
  switchMap((missionInput) => {
    return missionInput
      ? from(previewMission(missionInput)).pipe(
          catchError(() => {
            const detail = {
              message: t`Preview Failed`,
            };
            const event = new CustomEvent(Events.PREVIEW_LOAD, { detail });
            window.dispatchEvent(event);
            return of(null);
          }),
        )
      : of(null);
  }),
  shareReplay(1),
);

export default previewMissionObservable;
