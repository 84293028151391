import { makeStyles, Typography } from '@material-ui/core';
import { Equipment } from '@ats/graphql';
import clsx from 'clsx';
import { grey500 } from '../../theme/color';

const useStyles = makeStyles(
  {
    displayName: {
      color: grey500,
      position: 'absolute',
      top: '26px',
      left: '100px',
      width: '168px',
      textAlign: 'center',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: 'inline-block',
      whiteSpace: 'nowrap',
    },
  },
  { index: 1 },
);

interface IProps {
  vehicle: Equipment;
}

const VehicleDisplayName = ({ vehicle }: IProps) => {
  const { displayName } = useStyles();

  return (
    <Typography className={clsx(displayName, 'sdds-headline-06')} data-testid="vehicle-display-name">
      {vehicle.displayName}
    </Typography>
  );
};
export default VehicleDisplayName;
